import { getToken, getUser, getTime12, dateFormat } from "../../Utils/Common"
import { getAPICall, postAPICall } from "../axiosMethodCalls"
import Moment from "moment"

//GET
export const getAllProjectInvoice = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/get_all_project",
      {
        requester: getUser(),
        token: getToken(),
      }
    )
    return { data: response }
  } catch (error) {
    return { error: error.response }
  }
}

// GET SINGLE
export const getProjectInvoice = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/get_project_invoice",
      {
        requester: getUser(),
        token: getToken(),
        project_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

// GET SINGLE
export const getProjectInvoiceParticular = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_all_particulars",
      {
        requester: getUser(),
        token: getToken(),
        project_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const searchProjectInvoice = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/search",
      {
        requester: getUser(),
        token: getToken(),
        name: data.name,
        project_id: data.project_id,
        company: data.company,
        // status: data.status,
        invoice_no: data.invoice_no,
        anything: data.anything,
        payment_status: data.payment_status,
        date_from: data.date_from,
        date_to: data.date_to,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

// export const searchProjectInvoiceText = async (data) => {
//   try {
//     const response = await getAPICall(
//       process.env.REACT_APP_LINK + "project_invoices/search_invoice",
//       {
//         requester: getUser(),
//         token: getToken(),
//         search_text: data.search_text,
//         status: data.status,
//         // payment_status: data.payment_status,
//       }
//     );
//     return { data: response.data };
//   } catch (error) {
//     return { error: error.response };
//   }
// };

//POST
export const createProjectInvoice = async (data, particulars, file) => {
  let form_data = new FormData()
  form_data.append("requester", getUser())
  form_data.append("token", getToken())
  form_data.append("project_id", data.project_id)
  form_data.append("project_name", data.project_name)
  form_data.append("invoice_no", data.invoice_no)
  form_data.append("invoice_date", data.invoice_date)
  form_data.append("due_date", data.due_date)
  form_data.append("project_date", data.project_date)
  form_data.append("address", data.address)
  form_data.append("company", data.company)
  form_data.append("remarks", data.remarks)
  form_data.append("subtotal", data.subtotal)
  form_data.append("vat_twelve", data.vat_twelve)
  form_data.append("vat_net", data.vat_net)
  form_data.append("wht", data.withholding_tax)
  form_data.append("wht_percent", data.wht_percent)
  form_data.append("is_wht", data.is_wht)
  form_data.append("service_fee", data.service_fee)
  form_data.append("delivery_fee", data.delivery_fee)
  form_data.append("grand_total", data.grand_total)
  form_data.append("vat_type", data.vat_type)
  form_data.append("discount", data.discount)

  Array.from(file).map((val) => form_data.append("file[]", val))

  particulars.map((item) => {
    form_data.append("item_ids[]", item.item_id)
    form_data.append("item_balances[]", item.balance)
    form_data.append("item_names[]", item.item_name)
    form_data.append("units[]", item.unit)
    form_data.append("prices[]", item.price)
    form_data.append("billed_amounts[]", item.billed_amount)
    form_data.append("subtotal_item[]", item.total)
  })

  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "project_invoices/create",
      form_data
      // {
      //   project_id: data.project_id,
      //   invoice_date: data.invoice_date,
      //   due_date: data.due_date,
      //   project_date: data.project_date,
      //   address: data.address,
      //   company: data.company,
      //   remarks: data.remarks,
      //   subtotal: data.subtotal,
      //   service_fee: data.service_fee ? data.service_fee : 0,
      //   delivery_fee: data.delivery_fee ? data.delivery_fee : 0,
      //   grand_total: data.grand_total,

      //   item_names: particulars.map((item) => {
      //       return item.item_name;
      //   }),
      //   quantities: particulars.map((item) => {
      //       return item.qty;
      //   }),
      //   units: particulars.map((item) => {
      //       return item.unit;
      //   }),
      //   prices: particulars.map((item) => {
      //       return item.price;
      //   }),
      //   subtotal: particulars.map((item) => {
      //       return item.total;
      //   }),

      // }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const updateProjectInvoice = async (data, particulars) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "project_invoices/update",
      {
        requester: getUser(),
        token: getToken(),
        project_invoice_id: data.id,
        project_id: data.project_id,
        invoice_no: data.invoice_no,
        invoice_date: data.invoice_date,
        due_date: data.due_date,
        project_date: data.project_date,
        address: data.address,
        company: data.company,
        remarks: data.remarks,
        subtotal: data.subtotal,
        service_fee: data.service_fee ? data.service_fee : 0,
        delivery_fee: data.delivery_fee ? data.delivery_fee : 0,
        grand_total: data.grand_total,

        // //PAYMENT
        payment_type: data.payment_type,
        payment_date: data.payment_date,
        deposit_date: data.deposit_date,
        from_bank_name: data.bank_name,
        from_bank_id: data.from_bank_id,
        to_bank_id: data.to_bank_id,
        cheque_number: data.cheque_number,
        cheque_date: data.cheque_date,
        reference_number: data.reference_number,
        transaction_number: data.transaction_number,
        payment_description: data.payment_description,
        term_day: data.term_day,
        delivery_address: data.address,
        vat_twelve: data.vat_twelve,
        vat_net: data.vat_net,
        wht: data.withholding_tax,
        wht_percent: data.wht_percent,
        is_wht: data.is_wht,
        vat_type: data.vat_type,
        payment_remarks: data.payment_remarks,
        // invoice_no: data.invoice_no,
        // subtotal: data.subtotal,
        // service_fee: data.service_fee,
        // delivery_fee: data.delivery_fee,

        billed_amounts: particulars.map((item) => {
          return item.billed_amount
        }),
        item_balances: particulars.map((item) => {
          return item.balance
        }),
        item_ids: particulars.map((item) => {
          return item.id
        }),
        item_names: particulars.map((item) => {
          return item.item_name
        }),
        quantities: particulars.map((item) => {
          return item.qty
        }),
        units: particulars.map((item) => {
          return item.unit
        }),
        prices: particulars.map((item) => {
          return item.price
        }),
        // subtotal: particulars.map((item) => {
        //     return item.total;
        // }),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const deleteProjectInvoice = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/delete",
      {
        requester: getUser(),
        token: getToken(),
        project_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const sendToClientProjectInvoice = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/send_to_client",
      {
        requester: getUser(),
        token: getToken(),
        project_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const recordStatusProjectInvoice = async (id, status) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/record_status_change",
      {
        requester: getUser(),
        token: getToken(),
        status: status,
        project_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error }
  }
}

export const payProjectInvoiceModal = async (
  project_invoice_id,
  project_id,
  payment,
  balance,
  fileList
) => {
  try {
    var payload = {
      requester: getUser(),
      token: getToken(),
      project_id: project_id,
      project_invoice_id: project_invoice_id,
      payment_type: payment.payment_type,
      payment_date: payment.payment_date,
      deposit_date: payment.deposit_date,
      from_bank_id: payment.from_bank_id,
      to_bank_id: payment.to_bank_id,
      remarks: payment.remarks,
      from_bank_name: payment.bank_name,
      deposited_to: payment.deposited_to,
      cheque_number: payment.cheque_number,
      cheque_date: payment.cheque_date,
      reference_number: payment.reference_number,
      transaction_number: payment.transaction_number,
      payment_description: payment.payment_description,
      term_day: payment.term_days,
      delivery_address: payment.delivery_address,
      grand_total: payment.grand_total,
      paid_amount: payment.paid_amount !== "" ? payment.paid_amount : balance,
      subtotal: payment.subtotal,
      service_fee: payment.service_fee,
      delivery_fee: payment.delivery_fee,
      withholding_tax: payment.withholding_tax,
      invoice_no: payment.invoice_no,
      file: fileList.map((file) => file),
    }
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "project_invoice_payments/create",
      payload,
      true
    )
    return { data: response.data }
  } catch (error) {
    return { error: error }
  }
}
