import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import {
  numberFormat,
  TokenExpiry,
  selectDropdownStyle,   
  formatDateNoTime
} from "../../../Helpers/Utils/Common";
import { searchProject } from "./../../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "./../../../Helpers/apiCalls/Manage/CustomerAPI";
import { searchReceivablesAgingReport } from "./../../../Helpers/apiCalls/SalesReport/SalesReportApi";
import ReceivablesAgingBreakdownModal from "../../../Components/Modals/ReceivablesAgingBreakdownModal";
import { CSVLink } from "react-csv";
import ExportPDF from "../../../Components/Export/ExportPdf";

export default function ReceivablesAgingReport() {
  const navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    payment_status: "paid",
  });
  const[pdfData, setPdfData] = useState([])
  const [
    showReceivablesAgingBreakdownModal,
    setShowReceivablesAgingBreakdownModal,
  ] = useState(false);
  const handleCloseReceivablesAgingBreakdownModal = () =>
    setShowReceivablesAgingBreakdownModal(false);

  const [showLoader, setShowLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const [receivablesAgingBreakdownData, setReceivablesAgingBreakdownData] =
    useState([]);
  const [totalReceivables, setTotalReceivables] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const excelHeaders = [
    { label: "Customer", key: "customer_name" },
    { label: "Current", key: "cur" },
    { label: "1-30 Days", key: "one_to_thirty" }, 
    { label: "31-60 Days", key: "thirtyone_to_sixty" },
    { label: "61-90 Days", key: "sixty_to_ninety" },
    { label: "Above 90 Days", key: "above_ninety" },
    { label: "Total", key: "total" },
  ];

  const pdfHeaders = [
      "Customer",
      "Current",
      "1-30 Days",
      "31-60 Days",
      "61-90 Days",
      "Above 90 Days",
      "Total ",
  ]

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([]);

    const response = await getAllCustomer(filterConfig);
    if (response.error) {
      TokenExpiry(response.error);
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setCustomers([{ label: "All Customers", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([]);

    const response = await searchProject(filterConfig);
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error.data.status);
      }
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{ label: "All Projects", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function handleShowReceivablesAgingBreakdownModal(row, header) {
    if (!row[header] || parseFloat(row[header]) === 0) {
      return; // Do not show the modal if the value is zero
    }
  
    var split = row[header] ? row[header].split(",") : [];
    let breakdown = [];
    let aging_name = {
      cur: "CURRENT",
      one_to_thirty: "1 - 30 DAYS",
      thirtyone_to_sixty: "31 - 60 DAYS",
      sixtyone_to_ninety: "61 - 90 DAYS",
      above_ninety: "> 90 DAYS",
    };
  
    // Retrieve the invoice details from the row
    const invoiceDetails = row.invoice_details || []; // Assuming row has invoice_details
  
    for (let i = 0; i < split.length; i++) {
      let invoice_no = split[i].match(/INV\. ([^()]+)-\(\d+\.\d+\)/)?.[1].trim();
      let amount = split[i].match(/\(([\d.]+)\)/)?.[1].trim();
      
      // Find the corresponding invoice detail
      let invoiceDetail = invoiceDetails.find(invoice => invoice.invoice_no === invoice_no);
  
      if (invoiceDetail) {
        // Create a data object using the found invoice detail
        var data = {
          invoice_no: invoice_no,
          amount: numberFormat(amount),
          aging: aging_name[header],
          name: row.customer_name,
          invoice_date: formatDateNoTime(invoiceDetail.invoice_date),
          due_date: formatDateNoTime(invoiceDetail.due_date),
          project_name: invoiceDetail.project_name,
        };
        breakdown.push(data);
      }
    }
  
    setReceivablesAgingBreakdownData(breakdown);
    setShowReceivablesAgingBreakdownModal(true);
  }
  

  async function fetchData() {
    setShowLoader(true);
    setAllData([]);

    const response = await searchReceivablesAgingReport(filterConfig);
    if (response.error) {
      setAllData([]);
    } else {
      var allBills = response.data.receivables_aging.map((bill) => {
        return {
          ...bill,
          id: bill.customer_id,
          total: numberFormat(parseFloat(bill.total).toFixed(2))
        };
      });
      
      var pdfData = response.data.receivables_aging.map((bill) => {
        return {
          customer: bill.customer_name,
          current: numberFormat(bill.cur || 0),
          one_to_thirty: numberFormat(bill.one_to_thirty || 0),
          sixty_to_ninety: numberFormat(bill.sixty_to_ninety || 0),
          thirtyone_to_sixty: numberFormat(bill.thirtyone_to_sixty || 0),
          above_ninety: numberFormat(bill.above_ninety || 0),
          total: numberFormat(bill.total || 0),
        };
      });
      setPdfData(pdfData);
      setTotalReceivables(response.data.summary?.total_receivables || "0");
      setAllData(allBills);
    }
    setShowLoader(false);
  }

  function PrintBtn(row) {
    return (
      <button
        name="action"
        className="btn btn-sm view-btn-table"
        id={row.id}
        onClick={() => handlePrint(row)}
      >
        View
      </button>
    );
  }

  function getData(row, header) {
    var split = row[header] ? [row[header]] : [];
    return (
      <Row>
        {split.map((data, index) => (
          <label key={index} style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            {data}
          </label>
        ))}
      </Row>
    );
  }

  function sumData(row, header) {
    if (!row[header]) return (
      <ButtonGroup vertical>
        <Button
          className=""
          variant="outline-none"
          size="sm"
          onClick={() => handleShowReceivablesAgingBreakdownModal(row, header)}
        >
          {numberFormat("0.00")}
        </Button>
      </ButtonGroup>
    );

    const split = row[header].split(",");
    let totalExpense = 0;

    split.forEach(data => {
      const match = data.match(/\(([\d.]+)\)/);
      if (match) {
        totalExpense += parseFloat(match[1].trim());
      }
    });

    return (
      <ButtonGroup vertical>
        <Button
          className=""
          variant="outline-none"
          size="sm"
          onClick={() => handleShowReceivablesAgingBreakdownModal(row, header)}
        >
          {numberFormat(totalExpense.toFixed(2))}
        </Button>
      </ButtonGroup>
    );
  }

  function handlePrint(row) {
    navigate("/receivablesagingreport/print/" + row.id, {state: {reportDetails: row}});
  }

  function handleToCSV() {
    return (
      <CSVLink data={allData} headers={excelHeaders} filename={"ReceivableAgingReport.csv"} style={{textDecoration: "none", color: "#ffffff"}}>            
        Export to CSV
      </CSVLink>
    );
  }

  React.useEffect(() => {
    fetchData();
  }, [filterConfig]);

  React.useEffect(() => {
    fetchCustomers();
    fetchProjects();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES REPORT"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> Receivables Aging Report </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button className="add-btn text-center">{handleToCSV()}</button>
            <ExportPDF name={"ReceivablesAgingReport"} data={pdfData} header={pdfHeaders}>
            </ExportPDF>
          </Col>
        </Row>

        <div className="tab-content">
          {/* filters */}
          <div className="my-2 px-2 PO-filters PI-filters d-flex">
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customers"
              styles={selectDropdownStyle}
              // value={branch}
              options={customers}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, customer_id: e.value };
                });
              }}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Project"
              styles={selectDropdownStyle}
              // value={branch}
              options={projects}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, project_id: e.value };
                });
              }}
            />
            <span
              className="me-4 ml-8 align-middle mt-2 ps-label"
              style={{ fontWeight: "bold" }}
            >
              Total Receivables:{" "}
              {numberFormat(totalReceivables)}
            </span>
          </div>

          <div className="below">
            <Table
              tableHeaders={[
                // "-",
                "CUSTOMER NAME",
                "CURRENT",
                "1-30 DAYS",
                "31-60 DAYS",
                "61-90 DAYS",
                "ABOVE 90 DAYS",
                "TOTAL",
              ]}
              headerSelector={[
                // "-",
                "customer_name",
                "cur",
                "one_to_thirty", 
                "thirtyone_to_sixty",
                "sixtyone_to_ninety",
                "above_ninety",
                "total",
              ]}
              tableData={allData}
              currentAging={(row) => sumData(row, "cur")}
              oneToThirty={(row) => sumData(row, "one_to_thirty")} 
              thirtyOneToSixty={(row) => sumData(row, "thirtyone_to_sixty")}
              sixtyOneToNinety={(row) => sumData(row, "sixtyone_to_ninety")}
              aboveNinetyAging={(row) => sumData(row, "above_ninety")}
              totalAging={(row) => getData(row, "total")}
              ViewBtn={(row) => PrintBtn(row)}
              showLoader={showLoader}
              className={{
                customers: "text-text-start text-break ps-2 align-top",
              }}
            />
          </div>
          <div className="mb-2" />
        </div>
      </div>
      <ReceivablesAgingBreakdownModal
        show={showReceivablesAgingBreakdownModal}
        onHide={handleCloseReceivablesAgingBreakdownModal}
        data={receivablesAgingBreakdownData}
      ></ReceivablesAgingBreakdownModal>
    </div>
  );
}

