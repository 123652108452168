import moment from "moment";
import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import PaymentTable from "./PaymentTable";
import {
  formatDateNoTime,
  isAdmin,
  numberFormat,
  refreshPage, 
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import { getProjectInvoice } from "./../../Helpers/apiCalls/ProjectInvoice/ProjectInvoiceApi";
import "./ProjectInvoice.css";
import { Upload } from "antd";
import Compressor from "compressorjs";
import FileExceedModal from "../../Components/Modals/FileExceedModal";

export default function PrintProjectInvoice() {
  const { id } = useParams(); // id
  let navigate = useNavigate();

  const [inactive, setInactive] = useState(true);
  const [addDetails, setAddDetails] = useState([]);
  const [items, setItems] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showFileExceedModal, setShowFileExceedModal] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState("");

  async function fetchProjectInvoice() {
    setAddDetails({});
    setPaymentInfo([]);
    setItems([]);

    const response = await getProjectInvoice(id);

    if (response.error) {
      TokenExpiry(response);
    } else {
      var details = response.data.data[0];
      setAddDetails(details);
      setInvoiceNo(details.invoice_no)

      setItems(details.project_invoice_items);

      var payment = details.project_invoice_payments?.map((data) => {
        if (data.paid_amount !== "0.00") {
          var info = data;
          info.payment_date = formatDateNoTime(data.payment_date);
          info.paid_amount = numberFormat(data.paid_amount);
          info.deposit_date = formatDateNoTime(data.deposit_date);
          if (data.payment_type === "check") {
            info.payment_type = data.payment_type + "-" + data.cheque_number;
          }
         
          return info;
        }
        return null;
      }).filter(Boolean);

      setPaymentInfo(payment || []);

      if (response.data?.data[0]?.attachments && response.data.data[0].attachments.length > 0) {
        const _fileList = response.data.data[0].attachments.map((file) => ({
          uid: file.id || Math.random().toString(),
          name: file.name,
          status: 'done',
          url: file.base_64,
          type: file.file_type,
          file_type: file.file_type
        }));
        setFileList(_fileList);
      } else {
        setFileList([]);
      }
    }
    // handlePrint()
  }

  async function handlePrint() {
    toast.loading("Printing project invoice...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  const firstTable = () => {
  return (
    <div className="app-container">
      <table>
        <thead>
          <tr>
            <th
              className="text-left custom-td header-style"
              colSpan="2"
              style={{ fontSize: "20px" }}
            >
              Billing Details:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left custom-td" style={{ fontSize: "20px" }}> {/* Increased font size */}
              Company
            </td>
            <td className="print-data text-left custom-td" style={{ fontSize: "20px" }}>
              {addDetails.company}
            </td>
          </tr>
          <tr>
            <td className="text-left custom-td" style={{ fontSize: "20px" }}>
              Project Name
            </td>
            <td className="print-data text-left custom-td" style={{ fontSize: "20px" }}>
              {addDetails.project_name}
            </td>
          </tr>
          <tr>
            <td className="text-left custom-td" style={{ fontSize: "20px" }}>
              Address
            </td>
            <td className="print-data text-left custom-td" style={{ fontSize: "20px" }}>
              {addDetails.address}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


const secondTable = () => {
  return (
    <div className="app-container">
      <table>
        <thead>
          <tr>
            <th
              className="custom-td header-style"
              colSpan={4}
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              Product/Description
            </th>
            <th
              className="custom-td header-style"
              colSpan={1}
              style={{ fontWeight: "bold", fontSize: "20px"}} // Increased font size
            >
              Amount
            </th>
          </tr>
        </thead>
        
        <tbody>
        {items.map((item, index) => {
            return (
              <tr key={index}>
                <td colSpan={4} className="custom-desc-td" style={{ fontSize: "18px" }}>
                  {item.item_name}
                </td>
                <td 
                colSpan={1}
                className="custom-num-td" style={{ fontSize: "18px", color: "black" }}>
                  {numberFormat(item.billed_amount)}
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={4} className="custom-desc-td" style={{ fontSize: "18px", height: "30px" }}></td>
            <td colSpan={1} className="custom-num-td" style={{ fontSize: "18px" }}></td>
          </tr>
          <tr>
            <td colSpan={4} className="custom-desc-td" style={{ fontSize: "18px", height: "30px" }}></td>
            <td colSpan={1} className="custom-num-td" style={{ fontSize: "18px" }}></td>
          </tr>
          <tr>
            <td colSpan={4} className="custom-desc-td" style={{ fontSize: "18px", height: "30px" }}></td>
            <td colSpan={1} className="custom-num-td" style={{ fontSize: "18px" }}></td>
          </tr>
          
          <tr>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" , border: "none", fontWeight: "bold"}} // Increased font size
              colSpan={4}
            >
              Sub Total
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.subtotal ? numberFormat(addDetails.subtotal) : "0.00"}
            </td>
          </tr>
          <tr>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" , border: "none", fontWeight: "bold"}}
              colSpan={4}
            >
              12% VAT
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.vat_twelve
                ? numberFormat(parseFloat(addDetails.vat_twelve).toFixed(2))
                : 0}
            </td>
          </tr>
          <tr>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" , border: "none", fontWeight: "bold"}}
              colSpan={4}
            >
              NET OF VAT
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.vat_net
                ? numberFormat(parseFloat(addDetails.vat_net).toFixed(2))
                : 0}
            </td>
          </tr>
          <tr>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" , border: "none", fontWeight: "bold"}}
              colSpan={4}
            >
              WHT
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.wht
                ? numberFormat(parseFloat(addDetails.wht).toFixed(2))
                : "0.00"}
            </td>
          </tr>
          <tr>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" , border: "none", fontWeight: "bold"}}
              colSpan={4}
            >
              DISCOUNT
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.discount
                ? numberFormat(parseFloat(addDetails.discount).toFixed(2))
                : "0.00"}
            </td>
          </tr>
          <tr>
            <td
              className="text-end custom-td"
              style={{ fontWeight: "bold", fontSize: "20px", border: "none"}} // Increased font size
              colSpan={4}
            >
              GRAND TOTAL
            </td>
            <td
              className="text-right custom-td"
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              PHP{" "}
              {addDetails.grand_total
                ? numberFormat(addDetails.grand_total)
                : "0.00"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }

  React.useEffect(() => {
    fetchProjectInvoice();
  }, []);

  const handlePreview = async (file) => {
    try {
      let src = file.url;
      if (!src) {
        if (file.originFileObj) {
          src = await getBase64(file.originFileObj);
        } else {
          src = await getBase64(file);
        }
      }

      // Create a new window and display the file
      const newTab = window.open();
      newTab.document.write(`<img src="${src}" style="width:100%; height:auto;" />`);

    } catch (error) {
      console.error("Error previewing file:", error);
      toast.error("Error previewing file", {
        style: toastStyle(),
      });
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Update the handlePaymentAttachmentPreview function
  const handlePaymentAttachmentPreview = (payment) => {
    if (payment && payment.attachment && payment.attachment[0]) {
        const attachmentData = {
            name: payment.attachment[0].name || "Attachment",
            base_64: payment.attachment[0].base_64.includes('data:') 
                ? payment.attachment[0].base_64 
                : `data:image/jpeg;base64,${payment.attachment[0].base_64}`
        };
        setSelectedAttachment(attachmentData);
        setShowAttachmentModal(true);
    }
  };

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"FRANCHISE"}
        />
      </div>
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
        {/* Add print styles inline or in the <style> block below */}
          <div
            className={
              addDetails.fs_status === "invoiced"
                ? "text-end print-header d-flex flex-column"
                : "text-start print-header d-flex flex-column"
            }
          ></div>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left">
                <img
                  src={cleanLogo}
                  className="print-logo"
                  alt="Logo"
                  style={{ width: "200px", height: "70px" }}
                />
              </div>
              <div className="text-end">
                <div>
                  <span
                    style={{
                      fontSize: "80px",
                      color: "#5ac8e1",
                      fontWeight: "bold",
                    }}
                  >
                    INVOICE
                  </span>
                </div>
              </div>
            </div>

            <div className="text-left">
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                  MYT SoftDev Solutions, Inc.
                </span>
                <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                  NO. {invoiceNo}
                </span>
              </div>
            </div>

            <div className="text-left">
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: "16px" }}>
                  301 The Greenery, Pope John Paul II Ave.
                </span>
              </div>
            </div>

            <div style={{ fontSize: "16px" }}>Cebu City, 6000 Cebu</div>
            <div style={{ fontSize: "16px" }}>TIN : 497-280-919-000</div>

            {/* Add Billing Date and Due Date */}
            <div className="text-left mt-3">
              <div className="d-flex justify-content-between">
              <span></span>
              <span className="text-uppercase" style={{ fontSize: "18px"}}>
                {"BILLING DATE: " +
                  (addDetails.invoice_date
                    ? moment(addDetails.invoice_date).format("MMMM DD, yyyy")
                    : "N/A")}
              </span>

              </div>
              <div className="d-flex justify-content-between">
                <span></span>
                <span style={{ fontSize: "18px" }}>
                  {"DUE DATE: " +
                    (addDetails.due_date && addDetails.due_date !== "0000-00-00"
                      ? moment(addDetails.due_date).format("MMMM DD, yyyy")
                      : "N/A")}
                </span>

              </div>
            </div>

          </div>
          <div className="d-flex mt-5 mb-2">
            <div className="print-table mt-3 mx-2">{firstTable()}</div>
          </div>
          <div className="d-flex mt-5 mb-2">
            <div className="print-table mt-3 mx-2">{secondTable()}</div>
          </div>
          <div className="text-left" style={{ fontSize: "20px", textTransform: "uppercase" }}>
            REMARKS:{" "}
            {addDetails && addDetails.remarks !== undefined && addDetails.remarks !== null && addDetails.remarks !== "undefined"
              ? addDetails.remarks
              : ""}
          </div>


        <div
          className="text-left"
          style={{ fontSize: "20px", paddingTop: "20px", paddingBottom: "20px" }} // Set fontSize to 18px for consistency
        >
          <span>
            For check payment, deposit payment to:
            <div>Bank Name: Banco de Oro (BDO) Cebu HM Tower</div>
            <div>Account Number: 013030014422</div>
            <div>Account Name: MYT SoftDev Solutions, Inc.</div>
          </span>
        </div>


          <div>
      
          </div>
        </div>

        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
          <h5 className="PI-payment-info">PAYMENT HISTORY</h5>
          <div className="sales-tbl justify-content-center">
            <PaymentTable
              tableHeaders={[
                "PYMT DATE",
                "INV NO.",
                "TYPE",
                "DEPOSIT DATE",
                "DEPOSITED TO",
                "PAID AMT",
                "REMARKS",
                "ATTACHMENT"
              ]}
              headerSelector={[
                "payment_date",
                "id",
                "payment_type",
                "deposit_date",
                "to_bank_name",
                "paid_amount",
                "remarks",
                "attachment"
              ]}
              tableData={paymentInfo}
              onAttachmentClick={handlePaymentAttachmentPreview}
            />
          </div>
        </Container>

        {/* <Row className="mt-4 mb-2">
          <Col>
            <span className="edit-label">Attached Files</span>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: false,
                showDownloadIcon: false
              }}
            >
              {fileList.length >= 8 ? null : null}
            </Upload>
          </Col>
        </Row> */}

        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/projectinvoice")}
          >
            Close
          </button>
          {addDetails.payment_status === "open_bill" && isAdmin() ? (
            <button
              className="button-tertiary me-3"
              onClick={() => navigate("/projectinvoice/edit/" + id)}
            >
              Edit
            </button>
          ) : (
            ""
          )}
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>

      <FileExceedModal
        show={showFileExceedModal}
        onHide={() => setShowFileExceedModal(false)}
        size="2MB"
      />

      {/* Update the Attachment Modal */}
      <Modal
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedAttachment?.name || "Attachment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          {selectedAttachment && (
            <img
              src={selectedAttachment.base_64}
              alt="Payment Attachment"
              style={{ 
                maxWidth: '100%', 
                height: 'auto',
                objectFit: 'contain' 
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
