import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Compressor from "compressorjs";
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import { Image, Upload } from "antd";

//components
import Table from "../../Components/TableTemplate/Table";
import Navbar from "../../Components/Navbar/Navbar";
import DeleteModal from "../../Components/Modals/DeleteModal";
import AddModal from "../../Components/Modals/AddModal";
import EditModal from "../../Components/Modals/EditModal";
import ViewModal from "../../Components/Modals/ViewModal";
import FileExceedModal from "../../Components/Modals/FileExceedModal";

//css
import "./Manage.css";
import "../../Components/Navbar/Navbar.css";

import { getType, toastStyle } from "../../Helpers/Utils/Common";
import toast from "react-hot-toast";
import {
  getAllSuppliers,
  createSupplier,
  deleteSupplier,
  updateSupplier,
  searchSupplier,
} from "../../Helpers/apiCalls/suppliersApi";
import { validateSuppliers } from "../../Helpers/Validation/Manage/SuppliersValidation";
import InputError from "../../Components/InputError/InputError";
import upload from "../../Assets/Images/upload.png";
const { Dragger } = Upload;

// Add these constants at the top of the file
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
const ALLOWED_FILE_TYPES = ['application/pdf'];

export default function Suppliers() {
  const accountType = getType();
  const [inactive, setInactive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [file, setFile] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [showFileExceedModal, setShowFileExceedModal] = useState(false);

  const [filterConfig, setFilterConfig] = useState({
    tab: "MYT ERP",
  });
  const handleTabSelect = (tabKey) => {
    setFilterConfig((prev) => {
      return { ...prev, tab: tabKey };
    });
  };
  const isInitialMount = useRef(true);
  const filterConfigKey = "manage-suppliers-filterConfig";

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setFilterConfig((prev) => {
        if (window.localStorage.getItem(filterConfigKey) != null) {
          handleTabSelect(
            JSON.parse(window.localStorage.getItem(filterConfigKey)).tab
          );
          return JSON.parse(window.localStorage.getItem(filterConfigKey));
        } else {
          return { ...prev };
        }
      });
    } else {
      window.localStorage.setItem(
        filterConfigKey,
        JSON.stringify(filterConfig)
      );
    }
  }, [filterConfig]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewSupplierModal, setShowViewSupplierModal] = useState(false);
  const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);

  //API
  const [tableData, setTableData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [supplierData, setSupplierData] = useState({});
  const [selectedID, setSelectedID] = useState("");
  const [supplierDetails, setSupplierDetails] = useState({
    trade_name: "",
    trade_address: "",
    bir_name: "",
    bir_address: "",
    tin: "",
    terms: "",
    requirements: "",
    phone_no: "",
    email: "",
    contact_person: "",
    bank_primary: "",
    bank_alternate: "",
    payee: "",
    vat_type: "",
    file: [],
  });

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    trade_name: false,
    trade_address: false,
    contact_person: false,
    phone_no: false,
    payee: false,
    email: false,
    vat_type: false,
  });

  // Update the state management for files
  const [supplierFiles, setSupplierFiles] = useState({}); // Store files by supplier ID

  //onEdit
  function handleOnEdit() {
    setShowViewSupplierModal(false);
    setShowEditSupplierModal(true);
  }

  //DELETE or REMOVE SUPPLIER
  function handleDeleteSupplier() {
    removeSupplier(selectedID);
  }
  //DROPDOWN
  function handleSelectChange(e, row) {
    // Clean the data by converting nulls to empty strings
    const cleanedData = Object.keys(row).reduce((acc, key) => {
        acc[key] = row[key] === null ? "" : row[key];
        return acc;
    }, {});
    
    setSelectedID(row.id);
    setSupplierData(cleanedData);
    
    if (e.target.value === "delete-supplier") {
        setShowDeleteModal(true);
    } else if (e.target.value === "edit-supplier") {
        setShowEditSupplierModal(true);
        // Set files for this specific supplier
        if (row.attachments && row.attachments.length > 0) {
            const supplierFileList = row.attachments.map((file) => ({
                uid: file.id || Math.random().toString(),
                name: file.name,
                status: 'done',
                url: file.base_64,
                type: file.file_type,
                file_type: file.file_type
            }));
            setSupplierFiles(prev => ({
                ...prev,
                [row.id]: supplierFileList
            }));
            setFileList(supplierFileList);
        } else {
            setFileList([]);
        }
    } else if (e.target.value === "view-supplier") {
        setShowViewSupplierModal(true);
        // Set files for viewing this specific supplier
        if (row.attachments && row.attachments.length > 0) {
            const supplierFileList = row.attachments.map((file) => ({
                uid: file.id || Math.random().toString(),
                name: file.name,
                status: 'done',
                url: file.base_64,
                type: file.file_type,
                file_type: file.file_type
            }));
            setSupplierFiles(prev => ({
                ...prev,
                [row.id]: supplierFileList
            }));
            setFileList(supplierFileList);
        } else {
            setFileList([]);
        }
    }
}

  // Update handleFileChange for adding files
  const handleFileChange = ({ file, fileList }) => {
    const updatedFileList = fileList.map(f => {
        if (f.status === 'done' && f.originFileObj) {
            return {
                ...f,
                url: URL.createObjectURL(f.originFileObj)
            };
        }
        return f;
    });

    setFileList(updatedFileList);
    
    // Update supplier files
    if (selectedID) {
        setSupplierFiles(prev => ({
            ...prev,
            [selectedID]: updatedFileList
        }));
    }

    setSupplierDetails(prev => ({
        ...prev,
        file: updatedFileList.map(f => f.originFileObj || f)
    }));
  };

  // Update handleRemove for removing files
  const handleRemove = (removedFile) => {
    const newFileList = fileList.filter(file => file.uid !== removedFile.uid);
    setFileList(newFileList);
    
    // Update supplier files
    if (selectedID) {
        setSupplierFiles(prev => ({
            ...prev,
            [selectedID]: newFileList
        }));
    }
  };

  // to set uploaded file
  const handleBeforeUpload = (file) => {
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
        setShowFileExceedModal(true);
        return false;
    }

    // Check file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        toast.error("Only PDF files are allowed", {
            style: toastStyle(),
        });
        return false;
    }

    return true;
  };

  const handlePreview = async (file) => {
    try {
      let previewUrl = '';
      
      if (!file.url && !file.preview) {
        if (file.originFileObj) {
          previewUrl = await getBase64(file.originFileObj);
        } else if (file.base_64) {
          previewUrl = file.base_64;
        }
      }
      
      const url = file.url || previewUrl;
      
      if (!url) {
        toast.error("No preview available", {
          style: toastStyle(),
        });
        return;
      }

      // Open PDF in new tab with a more reliable method
      const newWindow = window.open();
      newWindow.document.write(`
        <html>
          <head>
            <title>${file.name || 'PDF Preview'}</title>
          </head>
          <body style="margin:0;padding:0;">
            <embed 
              src="${url}" 
              type="application/pdf"
              width="100%"
              height="100%"
              style="position:fixed;top:0;left:0;right:0;bottom:0;"
            />
          </body>
        </html>
      `);
    } catch (error) {
      console.error("Error previewing file:", error);
      toast.error("Error previewing file", {
        style: toastStyle(),
      });
    }
  };
  

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // const filteredData = tableData.filter(item =>
  //   item.trade_name.toLowerCase().includes(searchText.toLowerCase()) ||
  //   item.trade_address.toLowerCase().includes(searchText.toLowerCase())
  // );

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action form-select"
        id={row.id}
        onChange={(e) => handleSelectChange(e, row)}
        value={""}
      >
        <option defaulValue selected hidden>
          Select
        </option>
        <option value="view-supplier" className="color-options">
          View
        </option>
        <option value="edit-supplier" className="color-options">
          Edit
        </option>
        {accountType === "admin" && (
          <option value="delete-supplier" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  // Update resetValues to also reset file states
  function resetValues() {
    setSupplierDetails({
        trade_name: "",
        trade_address: "",
        bir_name: "",
        bir_address: "",
        tin: "",
        terms: "",
        bir_number: "",
        phone_no: "",
        email: "",
        contact_person: "",
        bank_primary: "",
        bank_alternate: "",
        primary_account_no: "",
        primary_account_name: "",
        alternate_account_no: "",
        alternate_account_name: "",
        payee: "",
        vat_type: "",
        file: [],
    });
    setSupplierData({});
    setFileList([]);
  }

  //API CALL
  async function fetchAllSuppliers() {
    setShowLoader(true);
    setTableData([]);

    const response = await searchSupplier(searchText);

    if (response.response) {
        var suppliersList = response?.response?.data?.map((supplier) => {
            // Clean null values
            const cleanedSupplier = Object.keys(supplier).reduce((acc, key) => {
                acc[key] = supplier[key] === null ? "" : supplier[key];
                return acc;
            }, {});

            return {
                ...cleanedSupplier,
                formatted_vat_type: cleanedSupplier.vat_type
                    ? cleanedSupplier.vat_type === "vat_ex"
                        ? "Vat Ex"
                        : "Vat In"
                    : ""
            };
        });

        setTableData(suppliersList);
    }

    setShowLoader(false);
}

  async function handleSaveSupplier() {
    if (validateSuppliers(supplierDetails, setIsError)) {
      setIsClicked(true);
      const response = await createSupplier(supplierDetails, fileList);
      if (response && response.response) {
        toast.success("Successfully created supplier!", {
          style: toastStyle(),
        });
        setShowAddSupplierModal(false);
        resetValues();
        fetchAllSuppliers();
      } else {
        toast.error(response?.error?.data?.messages?.error, {
          style: toastStyle(),
        });
      }
      setIsClicked(false);
    } else {
      toast.error("Error Creating New Supplier", {
        style: toastStyle(),
      });
    }
  }

  // Update handleEditSupplier to include selected files
  async function handleEditSupplier() {
    if (validateSuppliers(supplierData, setIsError)) {
        const currentFiles = fileList || [];
        const formData = new FormData();

        // Add all supplier details
        Object.keys(supplierData).forEach(key => {
            if (key !== 'file' && key !== 'file_ids' && key !== 'attachments') {
                formData.append(key, supplierData[key] || '');
            }
        });

        // Process all files (both new and existing)
        for (const file of currentFiles) {
            try {
                if (file.originFileObj) {
                    // New files
                    formData.append('file[]', file.originFileObj);
                } else if (file.url || file.base_64) {
                    // For existing files
                    const response = await fetch(file.url || file.base_64);
                    const blob = await response.blob();
                    const existingFile = new File([blob], file.name, { 
                        type: file.type || 'application/pdf' 
                    });
                    formData.append('file[]', existingFile);
                }

                // Add file IDs for existing files
                if (file.id) {
                    formData.append('file_ids[]', file.id);
                }
            } catch (error) {
                console.error("Error processing file:", error);
            }
        }

        formData.append('supplier_id', selectedID);

        const response = await updateSupplier(formData);

        if (response.response) {
            toast.success("Successfully updated supplier!", {
                style: toastStyle(),
            });
            setShowEditSupplierModal(false);
            resetValues();
            fetchAllSuppliers();
        } else {
            toast.error("Error Updating Supplier", {
                style: toastStyle(),
            });
        }
    }
  }

  async function removeSupplier(id) {
    const response = await deleteSupplier(id);
    if (response.data) {
      toast.success("Successfully deleted user!", {
        style: toastStyle(),
      });
      setShowDeleteModal(false);
      resetValues();
      fetchAllSuppliers();
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  //EDIT OR UPDATE FORWARDER
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSupplierData((prevState) => ({
        ...prevState,
        [name]: value === "null" ? "" : value
    }));
  };

  const handleEditFileChange = (info) => {
    setFileList(info.fileList);
    setFile(info.fileList);
  };

  React.useEffect(() => {
    fetchAllSuppliers();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"MANAGE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> SUPPLIERS </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search Company Name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetchAllSuppliers();
                }
              }}
              className="search-bar"
            />
            <button
              className="add-btn"
              eventKey="MYT ERP"
              onClick={() => {
                setShowAddSupplierModal(true);
                resetValues();
              }}
            >
              Add
            </button>
          </Col>
        </Row>
        <div className="tab-content">
          <Table
            tableHeaders={
              accountType === "admin"
                ? [
                    "COMPANY NAME",
                    "ADDRESS",
                    "CONTACT PERSON",
                    "PHONE NUMBER",
                    "COMPANY EMAIL",
                    "VAT TYPE",
                    "ACTIONS",
                  ]
                : [
                    "COMPANY NAME",
                    "ADDRESS",
                    "CONTACT PERSON",
                    "PHONE NUMBER",
                    "COMPANY EMAIL",
                    "VAT TYPE",
                  ]
            }
            headerSelector={[
              "trade_name",
              "trade_address",
              "contact_person",
              "phone_no",
              "email",
              "formatted_vat_type",
            ]}
            tableData={tableData}
            ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="supplier"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDeleteSupplier}
      />
      <AddModal
        title={`SUPPLIER`}
        show={showAddSupplierModal}
        onHide={() => {
          setShowAddSupplierModal(false);
          resetValues();
          setFileList([]);
        }}
        onSave={handleSaveSupplier}
        isClicked={isClicked}
        data={supplierDetails}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={supplierDetails.bir_name || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={supplierDetails.trade_name || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
              <InputError
                isValid={isError.trade_name}
                message={"Trade name is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={supplierDetails.bir_address || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_address"
                value={supplierDetails.trade_address || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
              <InputError
                isValid={isError.trade_address}
                message={"Trade address is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER{" "}
              <Form.Control
                type="text"
                name="tin"
                value={supplierDetails.tin || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                className="nc-modal-custom-input"
                value={supplierDetails.bir_number || ""}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERM (DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={supplierDetails.terms || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="text"
                name="contact_person"
                value={supplierDetails.contact_person || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {/* <InputError
                isValid={isError.contact_person}
                message={"Contact person is required"}
              /> */}
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="text"
                name="phone_no"
                value={supplierDetails.phone_no || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {/* <InputError
                isValid={isError.phone_no}
                message={"Phone number is required"}
              /> */}
            </Col>
            <Col xs={4}>
              PAYEE
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="email"
                name="payee"
                value={supplierDetails.payee || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
              {/* <InputError
                isValid={isError.payee}
                message={"Payee is required"}
              /> */}
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={supplierDetails.bank_primary || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="number"
                name="primary_account_no"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={supplierDetails.bank_alternate || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="number"
                name="alternate_account_no"
                className="nc-modal-custom-input"
                value={supplierDetails.alternate_account_no || ""}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                className="nc-modal-custom-input"
                value={supplierDetails.alternate_account_name || ""}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row">
            COMPANY EMAIL
              <Form.Control
                type="email"
                name="email"
                value={supplierDetails.email || ""}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row">
              VAT TYPE <label className="badge-required">{` *`}</label>
              <InputError
                isValid={isError.vat_type}
                message={"Vat Type is required"}
              />
              <Col md={8} className="d-flex align-items-center">
                <Form.Check 
                  inline
                  label={<span className="pt-10">VAT Ex</span>} 
                  name="vat_type"
                  type="radio"
                  value="vat_ex"
                  checked={supplierDetails.vat_type === "vat_ex"}
                  onChange={(e) => {
                    setSupplierDetails({ ...supplierDetails, vat_type: e.target.value });
                  }}
                />
                <Form.Check
                  inline
                  label={<span className="pt-10">VAT In</span>} 
                  name="vat_type"
                  type="radio"
                  value="vat_in"
                  checked={supplierDetails.vat_type === "vat_in"}
                  onChange={(e) => {
                    setSupplierDetails({ ...supplierDetails, vat_type: e.target.value });
                  }}
                />
              </Col>
            </Col>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">File Attachment</span>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  multiple={true}
                  accept=".pdf"
                  maxCount={10}
                  onChange={handleFileChange}
                  onPreview={handlePreview}
                  style={{ 
                    border: '1px dashed #d9d9d9',
                    borderRadius: '8px',
                    padding: '20px'
                  }}
                >
                  {fileList.length >= 10 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload PDF</div>
                      <div style={{ fontSize: '12px', color: '#666' }}>
                        (Max: 5MB per file)
                      </div>
                    </div>
                  )}
                </Upload>
              </Col>
            </Row>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="SUPPLIER"
        show={showEditSupplierModal}
        onHide={() => {
          setShowEditSupplierModal(false);
          resetValues();
        }}
        onSave={handleEditSupplier}
        data={supplierData}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={supplierData.bir_name || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={supplierData.trade_name || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
                required
              />
              <InputError
                isValid={isError.trade_name}
                message={"Trade name is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={supplierData.bir_address || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS<label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_address"
                value={supplierData.trade_address || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
                required
              />
              <InputError
                isValid={isError.trade_address}
                message={"Trade address is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin"
                value={supplierData.tin || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                value={supplierData.bir_number || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERM (DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={supplierData.terms || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON<label className="badge-required"></label>
              <Form.Control
                type="text"
                name="contact_person"
                value={supplierData.contact_person || ""}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
              <InputError
                isValid={isError.contact_person}
                message={"Contact person is required"}
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER<label className="badge-required"></label>
              <Form.Control
                type="text"
                name="phone_no"
                value={supplierData.phone_no || ""}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
              <InputError
                isValid={isError.phone_no}
                message={"Phone number is required"}
              />
            </Col>
            <Col xs={4}>
              PAYEE <label className=""></label>
              <Form.Control
                type="email"
                name="payee"
                value={supplierData.payee || ""}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isError.payee}
                message={"Payee is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={supplierData.bank_primary || ""}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="text"
                name="primary_account_no"
                value={supplierData.primary_account_no || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                value={supplierData.primary_account_name || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={supplierData.bank_alternate || ""}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="text"
                name="alternate_account_no"
                value={supplierData.alternate_account_no || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                value={supplierData.alternate_account_name || ""}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row">
              COMPANY EMAIL
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="email"
                name="email"
                className="nc-modal-custom-input-edit"
                value={supplierData.email || ""}
                onChange={(e) => handleEditChange(e)}
              />
              {/* <InputError
                isValid={isError.email}
                message={"Email is required"}
              /> */}
            </Col>
            <Col xs={4} className="nc-modal-custom-row">
              VAT TYPE <label className="badge-required">{` *`}</label>
              <InputError
                isValid={isError.vat_type}
                message={"Vat Type is required"}
              />
              <Col md={8} className="d-flex align-items-center">
                <Form.Check 
                  inline
                  label={<span className="pt-10">VAT Ex</span>} 
                  name="vat_type"
                  type="radio"
                  value="vat_ex"
                  checked={supplierData.vat_type === "vat_ex"}
                  onChange={handleEditChange}
                />
                <Form.Check
                  inline
                  label={<span className="pt-10">VAT In</span>} 
                  name="vat_type"
                  type="radio"
                  value="vat_in"
                  checked={supplierData.vat_type === "vat_in"}
                  onChange={handleEditChange}
                />
              </Col>
            </Col>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">File Attachment</span>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  multiple={true}
                  accept=".pdf"
                  maxCount={10}
                  onChange={handleFileChange}
                  onPreview={handlePreview}
                  style={{ 
                    border: '1px dashed #d9d9d9',
                    borderRadius: '8px',
                    padding: '20px'
                  }}
                >
                  {fileList.length >= 10 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload PDF</div>
                      <div style={{ fontSize: '12px', color: '#666' }}>
                        (Max: 5MB per file)
                      </div>
                    </div>
                  )}
                </Upload>
              </Col>
            </Row>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        show={showViewSupplierModal}
        onHide={() => setShowViewSupplierModal(false)}
        onEdit={handleOnEdit}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title-forwarder-details">
            SUPPLIER DETAILS
          </span>
          <Row className="nc-modal-custom-row mt-3">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={supplierData.bir_name || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={supplierData.trade_name || ""}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={supplierData.bir_address || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              <Form.Control
                type="text"
                name="trade_address"
                value={supplierData.trade_address || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin"
                value={supplierData.tin || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                value={supplierData.bir_number || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERM (DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={supplierData.terms || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={supplierData.contact_person || ""}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={supplierData.phone_no || ""}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col xs={4}>
              PAYEE <label className="badge-required">{}</label>
              <Form.Control
                type="email"
                name="payee"
                value={supplierData.payee || ""}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={supplierData.bank_primary || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="text"
                name="primary_account_no"
                value={supplierData.primary_account_no || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                value={supplierData.primary_account_name || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={supplierData.bank_alternate || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="text"
                name="alternate_account_no"
                value={supplierData.alternate_account_no || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                value={supplierData.alternate_account_name || ""}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row">
              COMPANY EMAIL
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="email"
                name="email"
                className="nc-modal-custom-input-edit"
                value={supplierData.email || ""}
                onChange={(e) => handleEditChange(e)}
              />
              {/* <InputError
                isValid={isError.email}
                message={"Email is required"}
              /> */}
            </Col>
            <Col xs={4} className="nc-modal-custom-row">
              VAT TYPE <label className="badge-required">{` *`}</label>
              <Col md={8} className="d-flex align-items-center">
                <Form.Check 
                  inline
                  label={<span className="pt-10">VAT Ex</span>} 
                  name="vat_type"
                  type="radio"
                  value="vat_ex"
                  checked={supplierData.vat_type === "vat_ex"}
                />
                <Form.Check
                  inline
                  label={<span className="pt-10">VAT In</span>} 
                  name="vat_type"
                  type="radio"
                  value="vat_in"
                  checked={supplierData.vat_type === "vat_in"}
                  onChange={handleEditChange}
                />
              </Col>
            </Col>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">Attached Files</span>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  showUploadList={{
                    showPreviewIcon: true,
                    showRemoveIcon: false,
                    showDownloadIcon: false
                  }}
                >
                  {null}
                </Upload>
              </Col>
            </Row>
          </Row>
        </div>
      </ViewModal>
      <FileExceedModal
          show={showFileExceedModal}
          onHide={() => setShowFileExceedModal(false)}
          size="5MB"
      />
    </div>
  );
}

// Add some CSS to style the PDF cards
const uploadCardStyle = {
  '.ant-upload-list-picture-card-container': {
    width: '150px',
    height: '150px',
  },
  '.ant-upload-list-picture-card .ant-upload-list-item': {
    padding: '8px',
  },
  '.ant-upload.ant-upload-select-picture-card': {
    width: '150px',
    height: '150px',
  }
};
