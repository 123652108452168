import moment from "moment";
import { Fragment } from "react";
import React, { useState } from "react";
import { Col, Container, Row, Table, Form, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  getTodayDateISO,
  refreshPage,
  toastStyle,
  getName,
  dateFormat
} from "../../Helpers/Utils/Common";
import { searchProject } from "../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "../../Helpers/apiCalls/Manage/CustomerAPI";

export default function PrintDistributor (edit) {
  const { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const distributorDetails = location.state.distributorDetails;
  const today = getTodayDateISO();
  const [inactive, setInactive] = useState(true);
  const [customersData, setCustomersData] = useState([]);
  const [allProjects, setAllProjects] = useState([])

  const [clientDetails, setClientDetails] = useState([
    {
      customer_id: '',
      projects: [{ project_id: '', project_date: '' }],
    },
  ]);   

  async function handlePrint() {
    toast.loading("Printing distributor details...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }

  async function fetchCustomer() {
    setCustomersData([]);
    const response = await getAllCustomer();
    let isCustomer = [];

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.data
    ) {

      let result = response.data.data.data.map((a) => {
        return {
          label: a.name,
          value: a.id,
          contact_no: a.contact_no,
          contact_person: a.contact_person,
          address: a.address,
          company: a.company,
          email: a.email,
        };
      });
      setCustomersData(result);
    }
  }

  async function fetchProjects () {
    const response = await searchProject({name:'',project_id:'',customer_id:''})
    if (response.data) {
      setAllProjects(response.data.data);
    }
  }

  async function getDistributorDetails () {
    const tempData = {
      name: 'TEMPORARY DISTIBUTOR NAME',
      address: 'THE ADDRESS OF THE DISTRIBUTOR, IN THIS CITY, AND THIS COUNTRY',
      contact_person: 'JUAN DELA CRUZ',
      contact_no: '09129283238'
    }

    // setDistributorDetails(tempData);

    const tempClientDetails = [{
      customer_id: 31,
      projects: [{project_id: 31,
        project_date: new Date()}]
    }]
    setClientDetails(tempClientDetails)
  }

  React.useEffect(() => {
    fetchCustomer();
    fetchProjects();
    getDistributorDetails();
  }, []);

  // DO NOT DELETE THIS PART OF THE CODE BECOS GIPAHIDE RA NI SIYA FOR NOW
  const CustomerProjectsTable = () => {
    return (
        <Table className="">
          <thead>
            <tr>
              <th className="w-30 text-start">Customer</th>
              <th className="w-30 text-start">Project</th>
              <th className="w-30 text-start">Project Date</th>
            </tr>
          </thead>
          <tbody>
            {(distributorDetails.distributor_clients&&distributorDetails.distributor_clients.length>0) && distributorDetails.distributor_clients.map((client, customerIndex) => (
              <>
              <tr key={customerIndex}>
                  <td className="w-30 text-start">{client.customer_name}</td>
                  <td className="w-30 text-start">{client.project_name}</td>
                  <td className="w-30 text-start">{dateFormat(client.distributor_client_date)}</td>
                      {/* {client.projects.map((project, projectIndex) => (
                        <>
                          <td>{project.project}</td>
                          <td>{dateFormat(project.project_date)}</td>
                        </>
                      ))} */}
              </tr>
              </>
            ))}
          </tbody>
        </Table>
    );
  };

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
          <Row className="print-grand-total my-3 text-end print-header d-flex flex-column">
            <span>DISTRIBUTOR NO. {id}</span>
            <span className="text-uppercase">
              {moment().format("MMMM DD, YYYY")}
            </span>
          </Row>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" />
          </div>
          
          <div className="print-body mt-5">
              <Row className="px-5">
                  <Col>
                      <div className="d-flex my-2 align-items-center">
                          <Col xs={4} className="print-label">
                              Distributor:
                          </Col>
                          <Col xs={7} className="print-data">
                              {distributorDetails.name
                                  ? distributorDetails.name
                                  : ''}
                          </Col>
                      </div>
                      
                      <div className="d-flex my-2 align-items-center">
                          <Col xs={4} className="print-label">
                              Contact Person:
                          </Col>
                          <Col xs={7} className="print-data">
                              {distributorDetails.contact_person}
                          </Col>
                      </div>
                  </Col>
                  <Col>
                      <div className="d-flex my-2 align-items-center">
                          <Col xs={4} className="print-label">
                              Address:
                          </Col>
                          <Col xs={7} className="print-data">
                              {distributorDetails.address}
                          </Col>
                      </div>
                      <div className="d-flex my-2 align-items-center">
                          <Col xs={4} className="print-label">
                              Contact No.:
                          </Col>
                          <Col xs={7} className="print-data">
                              {distributorDetails.contact_no}
                          </Col>
                      </div>
                  </Col>
              </Row>
              {/* DO NOT DELETE COZ TEMPORARILY HIDDEN RA NI WHATEVER WORKS FOR THE USER */}
              {/* <div className="d-flex mt-5 px-5 justify-content-evenly">
                  <div className="print-table mt-3 mx-2">
                    <CustomerProjectsTable/>
                  </div>
              </div> */}
            
              <div className="print-signatures mt-5">
                  <span className="text-center text-uppercase print-label">
                      {getName()}
                  </span>
                  <span className="text-center text-uppercase print-label fw-bold">
                      {distributorDetails.prepared_by}
                  </span>
              </div>
              <div className="print-signatories pb-4 mb-4">
                  <span>Received by</span>
                  <span>Prepared by</span>
              </div>
          </div>
        </div>
        

        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/distributors")}
          >
            Close
          </button>
          <button
            className="button-tertiary me-3"
            onClick={() => navigate("/distributors/form", {state: {formPurpose:"EDIT", passedId: id, distributorDetails: distributorDetails}})}
          >
            Edit
          </button>
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
}