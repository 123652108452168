import moment from "moment";
import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cleanLogo from "../../../Assets/Images/Login/logo.png";
import Navbar from "../../../Components/Navbar/Navbar";
import {
  formatAmount,
  refreshPage,
  toastStyle,
} from "../../../Helpers/Utils/Common";

export default function PrintReceivablesAgingReport() {
  const { id } = useParams(); // id
  let navigate = useNavigate();
  const location = useLocation();
  const reportDetails = location.state.reportDetails;
  ;

  const [inactive, setInactive] = useState(true);
  const [addDetails, setAddDetails] = useState(reportDetails);
  const [items, setItems] = useState([]);

  async function handlePrint() {
    toast.loading("Printing project invoice...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  function renderTable() {
    return (
      <Table className="align-middle">
        <thead>
          <tr>
            <th>Invoice Date</th>
            <th>Invoice No.</th>
            <th>Grand Total</th>
            <th>Paid Amount</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            return (
              <tr>
                <td>{item.date}</td>
                <td>{item.invoice_no}</td>
                <td className="text-lowercase">
                  {formatAmount(item.grand_total)}
                </td>
                <td>{formatAmount(item.paid_amount)}</td>
                <td>{formatAmount(item.balance)}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={5}>
              <div>
                <hr />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td>GRAND TOTAL</td>
            <td>
              PHP{" "}
              {addDetails.total
                ? formatAmount(addDetails.total)
                : "0.00"}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"FRANCHISE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
          <div className="text-end print-header d-flex flex-column">
            STATEMENT OF ACCOUNT
            <span className="text-uppercase">
              {moment(addDetails.added_on).format("MMMM DD, yyyy")}
            </span>
          </div>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" />
          </div>

          {/* content */}
          <div className="print-body mt-5 justify-content-start px-4">
            <Row className="d-flex align-items-start">
              <Col>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={2} className="print-label">
                    Project Name:
                  </Col>
                  <Col xs={7} className="print-data">
                    {addDetails.project_name}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={2} className="print-label">
                    Customer:
                  </Col>
                  <Col xs={7} className="print-data">
                    {addDetails.customer_name}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={2} className="print-label">
                    Address:
                  </Col>
                  <Col xs={7} className="print-data">
                    {addDetails.address}
                  </Col>
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-5 mb-2 justify-content-evenly">
              {/* table */}
              <div className="print-table mt-3 mb-5 mx-2">{renderTable()}</div>
            </div>

            <div className="print-signatures">
              <div className="d-flex align-items-center justify-content-end flex-column">
                <span className="text-center text-uppercase print-label fw-bold">
                  {addDetails?.added_by_name}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column">
                <span className="text-center text-uppercase print-label fw-bold"></span>
              </div>
            </div>
            <div className="print-signatories pb-2">
              <span>Prepared by</span>
              <span>Approved by</span>
            </div>
            <div className="print-signatures">
              <span className="text-center text-uppercase print-label fw-bold">
                {addDetails.client}
              </span>
            </div>
            <div className="print-signatories pb-4 mb-4">
              <span>Noted by (Client)</span>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate(-1)}
          >
            Close
          </button>
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
}
