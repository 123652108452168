import { Modal, Button, Form, Container } from "react-bootstrap";
import React, { useState } from "react";
// import { render } from "react-dom";
import warning from "../../Assets/Images/Modal/warning.png";

//css
import "./Modal.css";

function ProjectSaleModal(props) {
    
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size={props.size}
        centered
      >
      <Modal.Header closeButton>
        <Modal.Title className="custom-modal-body-title"><strong>{props?.sales?.row?.name}</strong></Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12">
            <div className="row">
                <div className="col-sm-3 d-flex justify-content-center">
                    <label className=""><strong>INVOICE NO.</strong></label>
                </div>
                <div className="col-sm-3 d-flex justify-content-end">
                    <label className=""><strong>AMOUNT</strong></label>
                </div>
                <div className="col-sm-3 d-flex justify-content-end">
                    <label className=""><strong>PAID AMOUNT</strong></label>
                </div>
                <div className="col-sm-3 d-flex justify-content-end">
                    <label className=""><strong>BALANCE</strong></label>
                </div>
            </div>
            {props.data.map((info)=>{
                return (
                    <div className="row">
                        <div className="col-sm-3 d-flex justify-content-center">
                            <label className="">{info.id}</label>
                        </div>
                        <div className="col-sm-3 d-flex justify-content-end">
                            <label>{info.grand_total}</label>
                        </div>
                        <div className="col-sm-3 d-flex justify-content-end">
                            <label className="">{info.paid_amount}</label>
                        </div>
                        <div className="col-sm-3 d-flex justify-content-end">
                            <label className="">{info.balance}</label>
                        </div>
                    </div>
                )
            })}
            <Container fluid className="modal-cont justify-content-center">
              {props.children}
            </Container>
          {props.withButtons && (
              <div className="col-sm-12 mt-3 d-flex justify-content-end">
              <button className="button-primary  me-3" onClick={props.onEdit}>
                Edit
              </button>
              <button className="button-tertiary mr-3" onClick={props.onHide}>
                Close
              </button>
            </div>
          )}
          {props.withCloseButtons && (
              <div className="col-sm-12 mt-3 d-flex justify-content-end">
              <button className="button-tertiary mr-3" onClick={props.onHide}>
                Close
              </button>
            </div>
          )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ProjectSaleModal.defaultProps = {
  title:"",
  type:"",
  size:"xl",
  withButtons: false,
  withHeader: false,
  show:()=>{},
  onHide:()=>{},
  onEdit:()=>{}
}

export default ProjectSaleModal;