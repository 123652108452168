import React, { useState } from "react";
import "jspdf-autotable";
import jsPDF from "jspdf";

export default function ExportPDF({ name, data, header }) {
  var infos = [];
  data.map((info) => {
    infos.push(Object.values(info === undefined ? 0 : info));
  });

  function print() {
    const doc = new jsPDF("l", "mm", "tabloid");
    // var img = new Image();
    // img.src = logo;
    // doc.addImage(img, 'png', 15, 13, 19, 9);

    doc.text(name, 20, 20);
    doc.autoTable({
      styles: {
        fontSize: 7,
        width: "50px",
      },

      theme: "plain",
      margin: { top: 25 },
      head: [header],
      body: infos,
    });

    doc.save(name + ".pdf");
  }

  return (
    <button className="add-btn text-center" onClick={print}>
      Export to PDF
    </button>
  );
}