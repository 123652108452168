import {handleValidationChange} from '../CommonValidation';

export const validatePayment = (data, setIsError) => {
    let isValid = true;

    // Check if payment type is empty
    if (data.payment_type === "") {
        handleValidationChange("payment_type", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("payment_type", false, setIsError);
    }

    // Check if payment date is empty
    if (data.payment_date === "") {
        handleValidationChange("payment_date", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("payment_date", false, setIsError);
    }

    // Check if invoice number is empty
    if (data.invoice_no === "" || data.invoice_no === undefined) {
        handleValidationChange("invoice_no", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("invoice_no", false, setIsError);
    }

    // Check if paid amount is empty or zero
    if (data.paid_amount === "" || data.paid_amount === undefined || parseFloat(data.paid_amount) <= 0) {
        handleValidationChange("paid_amount", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("paid_amount", false, setIsError);
    }

    // Check if deposit date is empty
    if (data.deposit_date === "" || data.deposit_date === undefined) {
        handleValidationChange("deposit_date", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("deposit_date", false, setIsError);
    }

    // Check if attachment is empty - required for all payment types
    if (!data.attachment || data.attachment === "") {
        handleValidationChange("attachment", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("attachment", false, setIsError);
    }

    // Validate bank_id (Credit From) - required for all payment types
    if (!data.bank_id) {
        handleValidationChange("bank_id", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("bank_id", false, setIsError);
    }

    // For check and others payment types, validate additional fields
    if (data.payment_type !== "cash") {
        // Additional validations for `check` payment type
        if (data.payment_type === "check") {
            if (!data.bank_name) {
                handleValidationChange("bank_name", true, setIsError);
                isValid = false;
            } else {
                handleValidationChange("bank_name", false, setIsError);
            }

            if (!data.check_number) {
                handleValidationChange("check_number", true, setIsError);
                isValid = false;
            } else {
                handleValidationChange("check_number", false, setIsError);
            }

            if (!data.check_date) {
                handleValidationChange("check_date", true, setIsError);
                isValid = false;
            } else {
                handleValidationChange("check_date", false, setIsError);
            }
        }

        // Additional validation if payment type is "others"
        if (data.payment_type === "others" && !data.reference_number) {
            handleValidationChange("reference_number", true, setIsError);
            isValid = false;
        } else {
            handleValidationChange("reference_number", false, setIsError);
        }
    }

    return isValid;
};

  