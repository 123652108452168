import moment from "moment";
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import { Col, Row, Container, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  formatDateNoTime,
  getTodayDateISO,
  numberFormat,
  refreshPage,
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import InvoiceTable from "../Sales/InvoiceTable";
import { searchProjectSales } from "../../Helpers/apiCalls/Manage/Projects";

export default function PrintProjectSales() {
  const currentDate = moment();
  const { project_id } = useParams();
  let navigate = useNavigate();
  const today = getTodayDateISO();

  const [inactive, setInactive] = useState(true);
  const [projectInvoiceData, setProjectInvoiceData] = useState([]);
  const [projectSalesData, setProjectSalesData] = useState([]);
  const [projectInvoiceSummary, setProjectInvoiceSummary] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  async function fetchData() {
    setShowLoader(true);
    setProjectInvoiceData([]);
    setProjectSalesData([]);
    setProjectInvoiceSummary([]);
    const response = await searchProjectSales({ project_id });

    if (response.error) {
      TokenExpiry(response);
    } else {
      setProjectSalesData(response.data.project_sales[0]);
      var invoiceSummary = response.data.invoice_summary;
      var invoiceItems = response.data.project_invoice;
      
      const formattedInvoiceItems = invoiceItems.map((item) => {
        return {
          invoice_date: formatDateNoTime(item.invoice_date),
          id: item.id,
          grand_total: item.grand_total,
          paid_amount: item.paid_amount,
          invoice_balance: item.invoice_balance,
        }
      });

      const formattedInvoiceSummary = invoiceSummary.map((item) => {
        return {
          amount: numberFormat(item.amount),
          paid_amount: numberFormat(item.paid_amount),
          receivable: numberFormat(item.receivable),
          project_expense: numberFormat(item.project_expense),
          total_sales: numberFormat(item.total_sales),
        }
      });

      setProjectInvoiceData(formattedInvoiceItems);
      setProjectInvoiceSummary(formattedInvoiceSummary);
    }
    setShowLoader(false);
  }

  const firstTable = () => {
    return (
      <div className="app-container">
        <h5 className="PI-payment-info">{"PROJECT NAME: " + projectSalesData.name}</h5>
        <h5 className="PI-payment-info">{"CUSTOMER: " + projectSalesData.customer_name}</h5>
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th
                  className="custom-td"
                  style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
                >
                  Total Invoice Amount
                </th>
                <th
                  className="custom-td"
                  style={{ fontWeight: "bold", fontSize: "20px"}} // Increased font size
                >
                  Paid Amount
                </th>
                <th
                  className="custom-td"
                  style={{ fontWeight: "bold", fontSize: "20px"}} // Increased font size
                >
                  Receivable
                </th>
                <th
                  className="custom-td"
                  style={{ fontWeight: "bold", fontSize: "20px"}} // Increased font size
                >
                  Project Expense
                </th>
                <th
                  className="custom-td"
                  style={{ fontWeight: "bold", fontSize: "20px"}} // Increased font size
                >
                  Total Profit
                </th>
              </tr>
            </thead>
            <tbody>
              {projectInvoiceSummary.length > 0 ? (
                projectInvoiceSummary.map((item, index) => (
                  <tr key={index}>
                    <td className="custom-td" style={{ fontWeight: "bold", fontSize: "20px" }}>{item.amount}</td>
                    <td className="custom-td" style={{ fontWeight: "bold", fontSize: "20px" }}>{item.paid_amount}</td>
                    <td className="custom-td" style={{ fontWeight: "bold", fontSize: "20px" }}>{item.receivable}</td>
                    <td className="custom-td" style={{ fontWeight: "bold", fontSize: "20px" }}>{item.project_expense}</td>
                    <td className="custom-td" style={{ fontWeight: "bold", fontSize: "20px" }}>{item.total_sales}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="custom-td" colSpan="5" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"SALES"}
        />
      </div>
      <div className={`container ${inactive ? "inactive" : "active"}`}>
      <div className="print-container px-3 py-2" id="printablediv">
          <div className="print-grand-total my-3 text-end print-header d-flex flex-column">
            <span>PROJECT NO. {projectSalesData.project_id}</span>
            <span className="text-uppercase">
            {projectSalesData.start_date === "0000-00-00" || !projectSalesData.start_date ? "No Date Specified"
            : moment(projectSalesData.start_date).format("MM-DD-YYYY")}
            </span>
          </div>

          {/* <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" alt="logo"/>
          </div> */}

          {/* <div className="d-flex align-items-center">
              <div className="text-center">
                <img
                  src={cleanLogo}
                  className="print-logo"
                  alt="Logo"
                  style={{ width: "200px", height: "70px" }}
                />
              </div>
            </div> */}

            {/* <div className="text-center">
              <div className="align-items-center">
                <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                  MYT SoftDev Solutions, Inc.
                </span>
              </div>
            </div> */}

            {/* <div className="text-center">
              <div className="align-items-center">
                <span style={{ fontSize: "16px" }}>
                  301 The Greenery, Pope John Paul II Ave.
                </span>
              </div>
            </div> */}

            {/* <div className="text-center">
              <div className="align-items-center">
                <span style={{ fontSize: "16px" }}>
                Cebu City, 6000 Cebu
                </span>
              </div>
            </div> */}

          <div className="d-flex mt-5 mb-2">
            <div className="print-table mt-3 mx-2">
              {firstTable()}
            </div>
          </div>
        </div>
        
        {/* Invoice List Table */}
        <Container fluid className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form">
          <h5 className="PI-payment-info">LIST OF INVOICES</h5>
          <div className="sales-tbl justify-content-center">
          <InvoiceTable
              tableHeaders={[
                "INVOICE DATE",
                "INVOICE NUMBER",
                "INVOICE AMOUNT",
                "PAID AMOUNT",
                "BALANCE",
              ]}
              headerSelector={[
                "invoice_date",
                "id",
                "grand_total",
                "paid_amount",
                "invoice_balance",
              ]}
              tableData={projectInvoiceData}
            />
          </div>
        </Container>

        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/projectsales")}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
