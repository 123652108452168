import {handleValidationChange} from "./CommonValidation"

export function validateChangeRequest(data, particulars, setIsError) {
    setIsError({
        project_id: false,
        project_date: false,
        request_no: false,
        address: false,
        items: false
    });

    if (particulars.length === 0) {
        setIsError((prev) => ({
            ...prev,
            items: true,
        }));
        return false;
    }

    if (data.request_no === "" || !data.request_no) {
        setIsError((prev) => ({
            ...prev,
            request_no: true,
        }));
        return false;
    }

    var isValid = true;

    if (data.request_date === "" || data.request_date === undefined) {
        handleValidationChange("request_date", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("request_date", false, setIsError);
    }

    if (data.request_no === "" || data.request_no === undefined) {
        handleValidationChange("request_no", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("request_no", false, setIsError);
    }

    if (particulars.length !== 0) {
        handleValidationChange("items", false, setIsError);
        particulars.map((data) => {
            if (
                data.name === "" ||
                data.name === undefined ||
                data.name === 0
            ) {
                handleValidationChange("name", true, setIsError);
                isValid = false;
            } else {
                handleValidationChange("name", false, setIsError);
            }
            if (
                data.amount === "" ||
                data.amount === undefined ||
                data.amount === 0
            ) {
                handleValidationChange("amount", true, setIsError);
                isValid = false;
            } else {
                handleValidationChange("amount", false, setIsError);
            }
        });
    } else {
        handleValidationChange("items", true, setIsError);
        isValid = false;
    }
    return isValid;
};