import { handleValidationChange } from "../CommonValidation";

export const validateAddSO = (data, setIsError) => {
  var isValid = true;
  if (data.supplier_id === "" && data.vendor_id === "") {
    handleValidationChange("supplierName", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("supplierName", false, setIsError);
  }

  if (data.requisitioner === "" || data.requisitioner === undefined) {
    handleValidationChange("requisitioner", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("requisitioner", false, setIsError);
  }

  if (data.expense_type_id === "" || data.expense_type_id === undefined) {
    handleValidationChange("type", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("type", false, setIsError);
  }

  if (
    data.supplies_expense_date === "" ||
    data.supplies_expense_date === undefined ||
    data.supplies_expense_date === "Invalid Date"
  ) {
    handleValidationChange("purchaseDate", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("purchaseDate", false, setIsError);
  }

  if (
    data.delivery_date === "" ||
    data.delivery_date === undefined ||
    data.purchase_date === "Invalid Date"
  ) {
    handleValidationChange("deliveryDate", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("deliveryDate", false, setIsError);
  }

  if (data.payment_method === "" || data.payment_method === undefined || data.payment_method === null) {
    handleValidationChange("paymentMethod", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("paymentMethod", false, setIsError);
  }

  return isValid;
};

