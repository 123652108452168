import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import {
  numberFormat,
  TokenExpiry,
  getTodayDateISO,
  selectDropdownStyle,
  firstDayOfMonth,
  lastDayOfMonth,
} from "../../../Helpers/Utils/Common";
import Moment from "moment";
import { CSVLink } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";
import { DatePicker } from "antd";

import { getAllExpenseType } from "./../../../Helpers/apiCalls/expensetypesApi";
import { searchExpenseReport } from "./../../../Helpers/apiCalls/ExpensesReports/ExpenseReportApi";

const { RangePicker } = DatePicker;

export default function ExpenseReport() {
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    date_from: firstDayOfMonth(),
    date_to: lastDayOfMonth(),
    search_text: '',
  });
  const [showLoader, setShowLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const excelHeaders = [
    { label: "DATE", key: "expense_date" },
    { label: "INVOICE No.", key: "particulars" },
    { label: "DESCRIPTION", key: "doc_no" },
    { label: "EXPENSE TYPE", key: "expense_type" },
    { label: "TOTAL AMOUNT", key: "expense_total" },
    { label: "PAID", key: "paid_amount" },
    { label: "BALANCE", key: "reference_no" },
  ];

  async function fetchExpenseTypes() {
    setShowLoader(true);
    setExpenseTypes([]);

    const response = await getAllExpenseType(filterConfig);
    if (response.error) {
      TokenExpiry(response.error);
    } else {
      var types = response.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setExpenseTypes([{ label: "All Expense Types", value: "" }, ...types]);
    }
    setShowLoader(false);
  }
  
  const handleFilterChange = (e) => {
    setFilterConfig({
      ...filterConfig,
      search_text: e.target.value, // Update search_text with input
    });
  };

  const fetchData = async () => {
    setShowLoader(true);
    setAllData([]);
  
    const response = await searchExpenseReport(filterConfig); // Pass filterConfig to search function
    if (response.error) {
      // Handle error 
    } else {
      let allBills = response.data.expense.map((bill) => {
        var info = bill;
        info.expense_date = Moment(bill.expense_date).format("MM-DD-YYYY");
        info.deposit_date = bill.deposit_date
          ? Moment(bill.deposit_date).format("MM-DD-YYYY")
          : "N/A";
        info.expense_total = numberFormat(bill.expense_total);
        info.paid_amount = numberFormat(bill.paid_amount);
        info.balance = numberFormat(bill.balance);
        return info;
      });
  
      // Sort by expense_date in ascending order
      allBills.sort((a, b) => {
        const dateA = new Date(a.expense_date);
        const dateB = new Date(b.expense_date);
        return dateA - dateB;
      });
  
      const expense = response.data.summary
        ? response.data.summary.total_expense
        : "0";
      const paid = response.data.summary
        ? response.data.summary.total_paid
        : "0";
      const balance = response.data.summary
        ? response.data.summary.total_balance
        : "0";
  
      setTotalExpense(expense);
      setTotalPaid(paid);
      setTotalBalance(balance);
      setAllData(allBills);
    }
  
    setShowLoader(false);
  };
  

  React.useEffect(() => {
    fetchData();
  }, [filterConfig]);

  React.useEffect(() => {
    fetchExpenseTypes();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"EXPENSES REPORT"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> Expense Report </h1>
          </Col>
        </Row>
          <Row className="d-flex align-items-center justify-content-between mb-3">
            <Col className="d-flex justify-content-start">
              <div className="justify-content-center align-items-center">
                <CSVLink
                  className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                  data={allData}
                  headers={excelHeaders}
                  target="_blank"
                  filename={`${getTodayDateISO()} Expense Report`}
                >
                  <span className="me-2">
                    <img width={20} height={20} src={downloadIcon} alt="" />
                  </span>
                  Download Excel
                </CSVLink>
              </div>
            </Col>
            
            <Col xs={6} className="d-flex justify-content-end">
              <input
                type="search"
                name="name"
                placeholder="Search expense..."
                value={filterConfig.search_text} // Bind search_text to input value
                onChange={handleFilterChange} // Update search_text on change
                className="search-bar"
              />
              <button
                className="add-btn"
                onClick={() => fetchData()} // Trigger fetchData on search
              >
                Search
              </button>
            </Col>
          </Row>

        <div className="tab-content mt-2">
          {/* filters */}
          <div className="my-2 px-2 PO-filters d-flex">
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Expense Type"
              styles={selectDropdownStyle}
              // value={branch}
              options={expenseTypes}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, expense_type: e.value };
                });
              }}
            />
            <Form.Select
              type="select"
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select Status..."
              name="payment_status"
              value={filterConfig.payment_status}
              onChange={(e) =>
                setFilterConfig((prev) => {
                  return { ...prev, payment_status: e.target.value };
                })
              }
            >
              <option value="">All</option>
              <option value="fully paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </Form.Select>

            <RangePicker placeholder={[filterConfig.date_from, filterConfig.date_to]} onChange={(e) => {
              if (e) {
                setFilterConfig((prev) => ({...prev,
                  date_from: e[0].format('YYYY-MM-DD'),
                  date_to: e[1].format('YYYY-MM-DD'),
                }))
              } else {
                setFilterConfig((prev) => ({...prev,
                  date_from: firstDayOfMonth(),
                  date_to: lastDayOfMonth(),
                }))
              }
            }}/>
          </div>

          <div className="my-2 px-2 PO-filters d-flex justify-content-center">
            <span className="me-4 ml-4 align-middle ps-label">
              Total Expense: {numberFormat(totalExpense ? totalExpense : 0.0)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Paid: {numberFormat(totalPaid ? totalPaid : 0.0)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Balance: {numberFormat(totalBalance ? totalBalance : 0.0)}
            </span>
          </div>

          <div className="below">
            {/* table */}
            <Table
              tableHeaders={[
                // "-",
                "DATE",
                "INVOICE NO",
                "DESCRIPTION",
                "EXPENSE TYPE",
                "TOTAL AMOUNT",
                "PAID",
                "BALANCE",
              ]}
              headerSelector={[
                // "-",
                "expense_date",
                "doc_no",
                "description",
                "expense_type",
                "expense_total",
                "paid_amount",
                "balance",
              ]}
              tableData={allData}
              showLoader={showLoader}
            />
          </div>
          <div className="mb-2" />
        </div>
        <div className="d-flex justify-content-end pt-5 pb-3">
          <button
            type="button"
            className="button-secondary me-3"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
