import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { DatePicker, Select, Table as AntTable } from "antd"
import Navbar from "../../Components/Navbar/Navbar"
import { formatAmount, numberFormat, toastStyle } from "../../Helpers/Utils/Common"
import { getFinancialReport } from "../../Helpers/apiCalls/SalesReport/SalesReportApi";
import dayjs from "dayjs"
import Table from "../../Components/TableTemplate/Table"
import { CSVLink } from "react-csv"; // Import CSVLink
import Moment from "moment"
import downloadIcon from "../../Assets/Images/download_icon.png"; // Import the download icon

const { RangePicker } = DatePicker
export default function BalanceSheet() {
  const [inactive, setInactive] = useState(true)
  const [filterConfig, setFilterConfig] = useState({
    date_from: dayjs().startOf("month").format("YYYY-MM-DD"),
    date_to: dayjs().endOf("month").format("YYYY-MM-DD"),
    year: dayjs().year(),
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableData, setTableData] = useState([])

  const excelHeaders = [
    { label: "Type", key: "name" },
    { label: "Jan", key: "jan" },
    { label: "Feb", key: "feb" },
    { label: "Mar", key: "mar" },
    { label: "Apr", key: "apr" },
    { label: "May", key: "may" },
    { label: "Jun", key: "jun" },
    { label: "Jul", key: "jul" },
    { label: "Aug", key: "aug" },
    { label: "Sep", key: "sep" },
    { label: "Oct", key: "oct" },
    { label: "Nov", key: "nov" },
    { label: "Dec", key: "dec" },
    { label: "Total", key: "total" },
  ];
  function handleToCSV() {
            return (
              <CSVLink
                data={tableData}
                headers={excelHeaders}
                filename={`IncomeStatementReport.csv`}
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                Export to CSV
              </CSVLink>
            );
          }

  async function fetchTableData() {
    setShowLoader(true)
    setTableData([])

    const response = await getFinancialReport(filterConfig);

    if (response.data) {
      const formattedData = response.data.account_types.map((asset) => {
        const baseData = {
          name: asset.name.toUpperCase(),
          key: asset.name.toUpperCase(),
          jan: asset.jan ? numberFormat(asset.jan.toFixed(2)) : "0.00",
          feb: asset.feb ? numberFormat(asset.feb.toFixed(2)) : "0.00",
          mar: asset.mar ? numberFormat(asset.mar.toFixed(2)) : "0.00",
          apr: asset.apr ? numberFormat(asset.apr.toFixed(2)) : "0.00",
          may: asset.may ? numberFormat(asset.may.toFixed(2)) : "0.00",
          jun: asset.jun ? numberFormat(asset.jun.toFixed(2)) : "0.00",
          jul: asset.jul ? numberFormat(asset.jul.toFixed(2)) : "0.00",
          aug: asset.aug ? numberFormat(asset.aug.toFixed(2)) : "0.00",
          sep: asset.sep ? numberFormat(asset.sep.toFixed(2)) : "0.00",
          oct: asset.oct ? numberFormat(asset.oct.toFixed(2)) : "0.00",
          nov: asset.nov ? numberFormat(asset.nov.toFixed(2)) : "0.00",
          dec: asset.dec ? numberFormat(asset.dec.toFixed(2)) : "0.00",
          total: numberFormat(asset.total_amount?.toFixed(2)),
          children: asset?.children?.map((item) => ({...item, total: item.total_amount}))
        };

        // Add any additional properties needed for styling
        if (["sales", "expenses", "income/loss"].includes(asset.name.toLowerCase())) {
          baseData.isParent = true;
        }
        if (asset.name.toLowerCase() === "income/loss" && parseFloat(asset.total_amount) < 0) {
          baseData.isNegative = true;
        }

        return baseData;
      });

      setTableData(formattedData)
    }

    setShowLoader(false)
  }

  useEffect(() => {
    fetchTableData()
  }, [filterConfig])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> Income Statement Report </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
          <button className="add-btn text-center">{handleToCSV()}</button>
          
          </Col>
        </Row>

        <div className="tab-content">
        <div className="ms-3 PO-filters PI-filters d-flex mb-4">
          <DatePicker
            picker="year"
            defaultValue={dayjs().year(filterConfig.year)}
            allowClear={false} // Disable the close icon
            onChange={(date, dateString) => {
              if (date) {
                setFilterConfig((prev) => ({
                  ...prev,
                  year: date.year(),
                }));
              } else {
                setFilterConfig((prev) => ({
                  ...prev,
                  year: null,
                }));
              }
            }}
          />
        </div>

          

          {/* Table Content */}
          {/* <Table
            tableHeaders={[
              "Type",
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
              "Total"
            ]}
            headerSelector={[
              "name",
              "jan",
              "feb",
              "mar",
              "apr",
              "may",
              "jun",
              "jul",
              "aug",
              "sep",
              "oct",
              "nov",
              "dec",
              "total"
            ]}
            tableData={tableData}
            showLoader={showLoader}
            tableType="income_statement"
          /> */}

          <div className="overflow-scroll ant-table-container">
            <AntTable 
              pagination={false}
              loading={showLoader}
              columns={excelHeaders.map((header) => 
              {
                return {
                  title: header.label, 
                  dataIndex: header.key, 
                  key: header.key, 
                  align: `${header.label === "Type" ? "start" : "end"}`,
                  render: (text, record) => {
                    const isIncomeLoss = record.name === 'INCOME/LOSS';
                    const isNegative = parseFloat(text) < 0;
                    const color = isIncomeLoss ? (isNegative ? 'red' : 'green') : 'inherit';
                    const isNumber = !isNaN(text) && isFinite(text)
                    const formattedText = isNumber ? new Intl.NumberFormat('en-US').format(text) : text;
                  
                    return <span style={{ color }}>{formattedText}</span>;
                  }
                }
              })
              } 
              dataSource={tableData}/>
          </div>
        </div>
      </div>
    </div>
  )
}
