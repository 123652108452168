import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { getToken, toastStyle } from "../../Helpers/Utils/Common";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//images
import logo from "../../Assets/Images/Login/logo.png";
import usernameIcon from "../../Assets/Images/Login/username.png";
import passwordIcon from "../../Assets/Images/Login/password.png";

//css
import "./Login.css";

//api
import { loginUser } from "../../Helpers/apiCalls/authApi";
import { refreshPage } from "../../Helpers/Utils/Common";

export default function Login() {
  const navigate = useNavigate();
  const userToken = getToken();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableClick, setDisableClick] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  async function submit() {
    setDisableClick(true);
    const response = await loginUser(username, password);
    if (response.data) {
      localStorage.setItem("pin", JSON.stringify(response.data.user.pin));
      localStorage.setItem("user", JSON.stringify(response.data.user.id));
      localStorage.setItem(
        "name",
        JSON.stringify(
          response.data.user.first_name + " " + response.data.user.last_name
        )
      );
      localStorage.setItem("type", JSON.stringify(response.data.user.type));
      localStorage.setItem(
        "api-key",
        JSON.stringify(response.data.user.api_key).replace(/['"]+/g, "")
      );
      localStorage.setItem("token", JSON.stringify(response.data.user.token));
      toast.success("Successful Login!", { style: toastStyle() });
      setTimeout(() => {refreshPage()}
      , 2000);
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
      setTimeout(() => refreshPage(), 2000);
    }
  }

  useEffect(() => {
    if (userToken) {
      navigate("/dashboard");
    }
  }, [userToken, navigate])

  return (
    <div className="center">
      <div className="login-cont">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="row">
          <div className="col d-flex justify-content-center">
            <img
              src={logo}
              alt="logo"
              className="login-logo"
              style={{ width: "350px", height: "150px" }}
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center username-cont">
            <div className="input-group mb-3">
              <div className="input-group-prepend icon-cont">
                <span
                  className="input-group-text icon-text custom-border-radius"
                  id="basic-addon1"
                >
                  <img
                    src={usernameIcon}
                    alt="username"
                    className="username-icon"
                  />
                </span>
              </div>
              <input
                type="text"
                className="form-control username-input"
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={(e)=>{
                  if(e.key==="Enter") {
                    submit();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center password-cont">
            <div className="input-group mb-3 password-wrapper-login">
              <div className="input-group-prepend icon-cont">
                <span
                  className="input-group-text icon-text custom-border-radius"
                  id="basic-addon1"
                >
                  <img
                    src={passwordIcon}
                    alt="password"
                    className="password-icon"
                  />
                </span>
              </div>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control password-input"
                placeholder="Password"
                aria-label="Password"
                aria-describedby="basic-addon1"
                autoComplete="on"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e)=>{
                  if(e.key==="Enter") {
                    submit();
                  }
                }}
              />
              <div className="eye-icon-login">
                <FontAwesomeIcon
                  icon={showPassword ? "eye" : "eye-slash"}
                  alt={"eye"}
                  className={showPassword ? "eye-login" : "eye-slash-login"}
                  aria-hidden="true"
                  onClick={togglePassword}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center login-btn-cont">
            {disableClick === true && (
              <ReactLoading
                type="spinningBubbles"
                color="#5ac8e1"
                height={50}
                width={50}
              />
            )}
            {disableClick === false && (
              <button
                type="submit"
                className="login-btn"
                onClick={() => submit()}
              >
                LOGIN
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
