import { handleValidationChange } from "../CommonValidation";

export const validateCustomer = (data, setIsError, type) => {
  var isValid = true;
  
  if (data.company === "") {
    handleValidationChange("company", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("company", false, setIsError);
  }

  if (data.name === "") {
    handleValidationChange("name", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("name", false, setIsError);
  }

  if (data.address === "") {
    handleValidationChange("address", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("address", false, setIsError);
  }

  if (data.credit_limit && !/^[0-9.]+$/.test(data.credit_limit)) {
    handleValidationChange("credit_limit", true, setIsError, "Credit limit must contain only numbers and periods");
    isValid = false;
} else {
    handleValidationChange("credit_limit", false, setIsError);
}


  return isValid;
};
