import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/DataTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Select from "react-select";

// api calls and utils
import {
  refreshPage,
  toastStyle,
  TokenExpiry,
  formatDateNoTime,
  getType,
  numberFormat,
  numberFormatInt,
  selectDropdownStyle,
} from "../../Helpers/Utils/Common";
import { searchProject, deleteProject } from "../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "../../Helpers/apiCalls/Manage/CustomerAPI";
import { getDistributor } from "../../Helpers/apiCalls/Distributor/DistributorApi";

// css
import "./ForBilling.css";

export default function ForBilling() {
  let navigate = useNavigate();
  const userType = getType();
  const [inactive, setInactive] = useState(true);
  const [allData, setAllData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [allDistributors, setAllDistributors] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [projectId, setProjectId] = useState("");

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({});
  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilterConfig((prev) => {
      return { ...prev, [name]: value };
    });
  }

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([]);

    const response = await getAllCustomer();

    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      const customersList = response.data.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      setCustomers([{ label: "All Customers", value: "" }, ...customersList]);
    }
    setShowLoader(false);
  }

  async function fetchDistributors() {
    setAllDistributors([]);
    const response = await getDistributor();
    if (response.data) {
      const distributorsList = response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
      setAllDistributors([{ value: "", label: "All Distributors" }, ...distributorsList]);
    }
  } 

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([]);

    const response = await searchProject();
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      const projectsList = response.data.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      setProjects([{ label: "All Projects", value: "" }, ...projectsList]);
    }

    setShowLoader(false);
  }

  async function fetchData() {
    setShowLoader(true);
    setAllData([]);

    const response = await searchProject(filterConfig);
    if (response.data) {
      const allDataList = response.data.data.map((data) => ({
        ...data,
        start_date: data.start_date !== "0000-00-00" ? formatDateNoTime(data.start_date) : "None",
        project_date: data.project_date !== "0000-00-00" ? formatDateNoTime(data.project_date) : "None",
        grand_total: numberFormat(data.grand_total),
        balance: numberFormat(data.balance),
        paid_amount: data.paid_amount ? numberFormat(data.paid_amount) : "0.00",
      }));
      setAllData(allDataList);
    } else if (response.error.data.status !== 404) {
      TokenExpiry(response.error);
    }
    setShowLoader(false);
  }

  async function handleDeleteProject() {
    const response = await deleteProject(projectId);

    if (response.data) {
      toast.success("Project Deleted Successfully!", {
        style: toastStyle(),
      });
      setTimeout(() => refreshPage(), 1000);
    } else {
      toast.error("Error Deleting Project", {
        style: toastStyle(),
      });
    }
  }

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        onChange={(e) => {
          if (e.target.value === "delete-project") {
            setProjectId(row.id);
            handleShowDeleteModal();
          }
        }}
      >
        <option value="" hidden>
          Select
        </option>
        <option value="delete-project" className="color-red">
          Delete
        </option>
      </Form.Select>
    );
  }

  useEffect(() => {
    fetchProjects();
    fetchCustomers();
    fetchDistributors();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">FOR BILLING</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search project..."
              value={filterConfig.name}
              onChange={(e) => handleFilterChange(e)}
              className="search-bar"
            />
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 ms-2 PO-filters PI-filters d-flex">
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Project"
              styles={selectDropdownStyle}
              options={projects}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, project_id: e.value };
                });
              }}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customer"
              styles={selectDropdownStyle}
              options={customers}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, customer_id: e.value };
                });
              }}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Distributor"
              styles={selectDropdownStyle}
              options={allDistributors}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, distributor_id: e.value };
                });
              }}
            />
          </div>

          <Table
            tableHeaders={[
                "PROJECT ID",
              "PROJECT NAME",
              "DESCRIPTION",
              "TYPE",
              "PERIOD",
              "PRICE",
              "BALANCE",
              "TIMES BILLED",
            ]}
            headerSelector={[
              "name",
              "customer_name",
              "distributor_name",
              "grand_total",
              "project_date",
              "payment_structure",
            ]}
            tableData={allData}
            ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
            withActionData={false}
          />
        </div>
        <div className="mb-2" />
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="project"
        onDelete={() => handleDeleteProject()}
      />
    </div>
  );
}
