import DataTable from "react-data-table-component";
import styled from 'styled-components';
import { SyncLoader } from "react-spinners";
import NoDataPrompt from "../../Components/NoDataPrompt/NoDataPrompt";
import "../../Components/TableTemplate/OneTable.css";
import { formatAmount, numberFormat } from "../../Helpers/Utils/Common";

export default function InvoiceTable({
    tableHeaders,
    headerSelector,
    tableData,
    ActionBtn,
    ViewBtn,
    PendingBtn,
    showLoader,
    withActionData,
}) {
    const columns = tableHeaders.map((header, index) => {
        if (["INVOICE AMOUNT", "PAID AMOUNT", "BALANCE"].includes(header.toUpperCase())) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                wrap: true,
                reorder: true,
                right: true, // Aligns text to the right
                cell: (row) => (
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end", // Aligns text to the right
                        paddingRight: "10px",
                    }}>
                        {numberFormat(row[headerSelector[index]])}
                    </div>
                ),
            };
        } else if (["INVOICE DATE", "INVOICE NUMBER"].includes(header.toUpperCase())) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                wrap: true,
                reorder: true,
                center: true,
                cell: (row) => (
                    <div style={{
                        width: "100%",
                        display: "flex",
                        textAlign: "center",
                        paddingRight: "25px",
                        justifyContent: "center", // Aligns text to the center
                    }}>
                        {row[headerSelector[index]]}
                    </div>
                ),
            };
        } else {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                wrap: true,
                reorder: true,
            };
        }
    });
    

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: "5.2vh",
                fontSize: "12px",
                whiteSpace: "pre",
            },
        },
        headCells: {
            style: {
                fontSize: "12px",
                textAlign: "center", // Centers column headers
                justifyContent: "center",
            },
        },
        cells: {
            style: {
                justifyContent: "flex-end", // Ensures right-aligned values
                textAlign: "right", // Aligns numbers to the right
                paddingRight: "10px", // Ensures spacing from the column edge
            },
        },
    };
    

    return showLoader ? (
        <div className="d-flex justify-content-center my-5">
            <SyncLoader color="#5ac8e1" size={15} />
        </div>
    ) : (
        <DataTable
            grow
            responsive
            striped
            fixedHeader
            columns={columns}
            data={tableData}
            customStyles={customStyles}
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoDataPrompt />}
        />
    );
}
