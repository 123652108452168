import { getToken, getUser } from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import qs from 'qs';

export const getDistributor = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributors/get_all_distributor",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const getOneDistributor = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributors/get_distributor",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const createDistributor = async (data) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "distributors/create",
            {
                requester: getUser(),
                token: getToken(),
                ...data,
            },
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

export const updateDistributor = async (data) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "distributors/update",
            {
                requester: getUser(),
                token: getToken(),
                ...data,
            },
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

export const deleteDistributor = async (id) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "distributors/delete",
            {
                requester: getUser(),
                token: getToken(),
                distributor_id: id,
            },
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

// DISTRIBUTOR BILLING

export const getDistributorBilling = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributor_billings/get_all_distributor_billing",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const DistributorBilling = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributor_billings/search",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const getOneDistributorBilling = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributor_billings/get_distributor_billing",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const generateMonthlyBilling = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributor_billings/generate_distributor_billing",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const generateDistributorBilling = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributor_billings/create",
            {
                requester: getUser(),
                token: getToken(),
                ...data
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const addDistributorBillingPayment = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributor_billing_payments/create",
            {
                requester: getUser(),
                token: getToken(),
                ...data
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const updateDistributorBilling = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "distributor_billings/update",
            {
                requester: getUser(),
                token: getToken(),
                ...data
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const deleteDistributorBilling = async (id) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "distributor_billings/delete",
            {
                requester: getUser(),
                token: getToken(),
                distributor_billing_id: id,
            },
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};