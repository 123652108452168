import { Modal, Button, Form, Container, Row, Col} from "react-bootstrap";
import React, { useState } from "react";
import Table from "../../Components/TableTemplate/DataTable";
// import { render } from "react-dom";
import { 
    numberFormat,
} from "../../Helpers/Utils/Common";
import { useNavigate } from "react-router-dom";
import warning from "../../Assets/Images/Modal/warning.png";

//css
import "./Modal.css";

function ReceivablesAgingBreakdownModal(props) {
    let navigate = useNavigate();
    const [filterConfig, setFilterConfig] = useState({
        name: "",
      });
    
    function handleViewBtn(id, status) {
        navigate("/projectexpense/view/" + id + "/" + status);
    }

    function splitData(row, header) {

        var split = row[header]?row[header].split(","):[];
         return (
            <Row>{
                split.map((data) => {
                    return (
                        <>
                            <label>{data}</label><br/>
                        </>
                    );
                })
            }
            </Row>
        )
    }

    return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className="modal-cont-receivables"
        size={props.size}
        centered
      >
      <Modal.Header closeButton/>
        <Modal.Body>
          <div className="col-sm-12 col-6">
            <div className="col-sm-12">
                <span className="custom-modal-body-title">{props.data[0] ? props.data[0].name : ""} ({props.data[0] ? props.data[0].aging : ""})</span>
            </div>
            <Container fluid className="modal-cont receivableAgingContainer">
                <div className="sales-tbl receivableAging" style={{ overflowX: "auto" }}>
                    <Table
                        tableHeaders={[
                            "INV NO",
                            "INV DATE",
                            "PROJ NAME",
                            "DUE DATE",
                            "INV AMT",
                            // "AMOUNT",
                            // "OTHER FEES",
                            // "GRAND TOTAL",
                            // "REMARKS",
                            // "ADDED BY",
                        ]}
                        headerSelector={[
                            "invoice_no",
                            "invoice_date",
                            "project_name",
                            "due_date",
                            "amount",
                            // "amount",
                            // "other_fees",
                            // "grand_total",
                            // "remarks",
                            // "added_by_name",
                        ]}
                        tableData={props.data}
                        fixedHeader
                        fixedHeaderScrollHeight="40vh"
                        // showLoader={showLoader}
                        withActionData={false}
                        className={{
                            customers: "text-text-start text-break ps-2 align-top",
                          }}
                    />
                </div>
            </Container>
         
            <div className="col-sm-12 mt-3 d-flex justify-content-end">
                <button className="button-tertiary mr-3" onClick={props.onHide}>
                    Close
                </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ReceivablesAgingBreakdownModal.defaultProps = {
  title:"",
  type:"",
  size:"xl",
  withButtons: false,
  withHeader: false,
  show:()=>{},
  onHide:()=>{},
  onEdit:()=>{}
}

export default ReceivablesAgingBreakdownModal;