import DataTable from "react-data-table-component";
import { SyncLoader } from "react-spinners";
import NoDataPrompt from "../../Components/NoDataPrompt/NoDataPrompt";
import "../../Components/TableTemplate/OneTable.css";

export default function PaymentTable({
    tableHeaders,
    headerSelector,
    tableData,
    ActionBtn,
    ViewBtn,
    PendingBtn,
    showLoader,
    withActionData,
    onAttachmentClick,
}) {
    const columns = tableHeaders.map((header, index) => {
        if (header === "PAYMENT DATE") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                button: true,
                width: "20%",
            };
        } else if (header.includes("TYPE")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                // sortable: true,
                width: "8%",
                wrap: true,
                reorder: true,
            };
        } else if (header.includes("PAID AMT")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "20%",
                wrap: true,
                reorder: true,
                right: true,
            };
        } else if (header === "ATTACHMENT") {
            return {
                name: header,
                cell: (row) => renderCell(row, headerSelector[index]),
                width: "auto",
                wrap: true,
                reorder: true,
            };
        } else {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                // sortable: true,
                width: "auto",
                wrap: true,
                reorder: true,
            };
        }
    });

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: "5.2vh",
                flexWrap: "wrap",
                fontSize: "12px",
                whiteSpace: "pre",
            },
        },
        headCells: {
            style: {
                flexWrap: "wrap",
                fontSize: "12px",
                width: "100%",
                wordWrap: "breakWord",
                color: "#5ac8e1",
            },
        },
    };

    const renderCell = (row, selector) => {
        if (selector === "attachment") {
            // Only show "View Attachment" if row has attachment array with data
            if (row.attachment && row.attachment.length > 0 && row.attachment[0].base_64) {
                return (
                    <span 
                        style={{ 
                            cursor: "pointer",
                            color: "#5ac8e1"
                        }}
                        onClick={() => onAttachmentClick && onAttachmentClick(row)}
                    >
                        {row.attachment_name || "View Attachment"}
                    </span>
                );
            }
            return ""; // Return empty string if no attachment
        }
        return row[selector];
    };

    return showLoader ? (
        <div className="d-flex justify-content-center my-5">
            <SyncLoader color="#5ac8e1" size={15} />
        </div>
    ) : (
        <DataTable
            grow
            pagination
            responsive
            striped
            fixedHeader
            columns={columns}
            data={tableData}
            customStyles={customStyles}
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoDataPrompt />}
        />
    );
}
