import { handleValidationChange } from "../CommonValidation";

export const validateBranches = (data, setIsError, type) => {
  //Required
  // name: false,
  // address: false,
  // phone_no: false,
  // initial_drawer: false,
  // price_level: false,
  // contact_person: false,
  // contact_person_no: false

  var isValid = true;

  if (data.initial_drawer === "") {
    handleValidationChange("initial_drawer", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("initial_drawer", false, setIsError);
  }

  // if(data.price_level === "") {
  //     handleValidationChange("price_level", true, setIsError);
  //     isValid=false;
  // } else {
  //     handleValidationChange("price_level", false, setIsError);
  // }

  if (data.contact_person === "") {
    handleValidationChange("contact_person", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("contact_person", false, setIsError);
  }

  if (data.contact_person_no === "") {
    handleValidationChange("contact_person_no", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("contact_person_no", false, setIsError);
  }

  if (data.address === "") {
    handleValidationChange("address", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("address", false, setIsError);
  }

  if (data.phone_no === "") {
    handleValidationChange("phone_no", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("phone_no", false, setIsError);
  }

  return isValid;
};
