import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DatePicker } from "antd";
import Navbar from "../../Components/Navbar/Navbar";
import { formatAmount, firstDayOfMonth, lastDayOfMonth, formatDateNoTime } from "../../Helpers/Utils/Common";
import { getJournalEntryReport } from "../../Helpers/apiCalls/SalesReport/SalesReportApi";
import Table from "../../Components/TableTemplate/Table";

const { RangePicker } = DatePicker;

export default function JournalEntry() {
  const [inactive, setInactive] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    date_from: null,
    date_to: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  async function fetchTableData() {
      setShowLoader(true);
      setTableData([]);

      const response = await getJournalEntryReport(filterConfig);

      if (response?.data?.data) { // Ensure response structure matches
          const formattedData = response.data.data.map((entry, index) => ({
              key: index,
              payment_date: entry.payment_date ? formatDateNoTime(entry.payment_date) : " ",
              description: entry.description || " ",
              account_type: entry.account_type || " ",
              bank_name: entry.bank_name || " ",
              income: formatAmount(parseFloat(entry.income || 0).toFixed(2)),
              expense: formatAmount(parseFloat(entry.expense || 0).toFixed(2)),
              total: formatAmount(parseFloat(entry.total || 0).toFixed(2)),
          }));

          setTableData(formattedData);
      }

      setShowLoader(false);
  }

  // const tableColumns = [
  //   { 
  //     title: "Date", 
  //     dataIndex: "payment_date", 
  //     key: "payment_date", 
  //     width: 140, 
  //     onHeaderCell: () => ({
  //       style: { backgroundColor: "#5ac8e1", color: "white", textAlign: "center" },
  //     }),
  //     render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,  
  //   },
  //   { 
  //     title: "Description", 
  //     dataIndex: "description", 
  //     key: "description", 
  //     width: 400, 
  //     onHeaderCell: () => ({
  //       style: { backgroundColor: "#5ac8e1", color: "white", textAlign: "center" },
  //     }),
  //     render: (text) => <div style={{ textAlign: "left" }}>{text}</div>, 
  //   },
  //   { 
  //     title: "Account Type", 
  //     dataIndex: "account_type", 
  //     key: "account_type", 
  //     onHeaderCell: () => ({
  //       style: { backgroundColor: "#5ac8e1", color: "white", textAlign: "center" },
  //     }),
  //     render: (text) => <div style={{ textAlign: "left" }}>{text}</div>, 
  //   },
  //   { 
  //     title: "Bank Name", 
  //     dataIndex: "bank_name", 
  //     key: "bank_name", 
  //     onHeaderCell: () => ({
  //       style: { backgroundColor: "#5ac8e1", color: "white", textAlign: "center" },
  //     }),
  //     render: (text) => <div style={{ textAlign: "left" }}>{text}</div>, 
  //   },
  //   { 
  //     title: "Income", 
  //     dataIndex: "income", 
  //     key: "income", 
  //     onHeaderCell: () => ({
  //       style: { backgroundColor: "#5ac8e1", color: "white", textAlign: "center" },
  //     }),
  //     render: (text) => (
  //       <div style={{ textAlign: "right" }}>
  //         {new Intl.NumberFormat("en-US", {
  //           style: "decimal",
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2,
  //         }).format(text)}
  //       </div>
  //     ), 
  //   },
  //   { 
  //     title: "Expense", 
  //     dataIndex: "expense", 
  //     key: "expense", 
  //     onHeaderCell: () => ({
  //       style: { backgroundColor: "#5ac8e1", color: "white", textAlign: "center" },
  //     }),
  //     render: (text) => (
  //       <div style={{ textAlign: "right" }}>
  //         {new Intl.NumberFormat("en-US", {
  //           style: "decimal",
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2,
  //         }).format(text)}
  //       </div>
  //     ), 
  //   },
  //   { 
  //     title: "Balance", 
  //     dataIndex: "total", 
  //     key: "total", 
  //     onHeaderCell: () => ({
  //       style: { backgroundColor: "#5ac8e1", color: "white", textAlign: "center" },
  //     }),
  //     render: (text) => (
  //       <div style={{ textAlign: "right" }}>
  //         {new Intl.NumberFormat("en-US", {
  //           style: "decimal",
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2,
  //         }).format(text)}
  //       </div>
  //     ), 
  //   },
  // ];

  useEffect(() => {
    fetchTableData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar onCollapse={(inactive) => setInactive(inactive)} active="FINANCIAL STATEMENTS" />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
      <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">Journal Entry Report</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button
              className="add-btn"
              // onClick={() => navigate("/projectinvoice/add")}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="ms-3 PO-filters PI-filters d-flex mb-4">
            <RangePicker 
              placeholder={[
                filterConfig.date_from ?? firstDayOfMonth(), 
                filterConfig.date_to ?? lastDayOfMonth()
              ]} 
              onChange={(e) => {
                if (e) {
                  setFilterConfig((prev) => ({...prev,
                    date_from: e[0].format('YYYY-MM-DD'),
                    date_to: e[1].format('YYYY-MM-DD'),
                  }))
                } else {
                  setFilterConfig((prev) => ({...prev,
                    date_from: firstDayOfMonth(),
                    date_to: lastDayOfMonth(),
                  }))
                }
              }}
            />
          </div>
          <Table
            tableHeaders={[
              "DATE",
              "DESCRIPTION",
              "ACCOUNT TYPE",
              "BANK NAME",
              "INCOME",
              "EXPENSE",
              "BALANCE",
            ]}
            headerSelector={[
              "payment_date",
              "description",
              "account_type",
              "bank_name",
              "income",
              "expense",
              "total",
            ]}
            tableData={tableData}
            showLoader={showLoader}
            tableType="journal_entry"
          />
        </div>
      </div>
    </div>
  );
}
