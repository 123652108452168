import React, { useState, useEffect, useRef } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  isAdmin,
  toastStyle,
  TokenExpiry,
  numberFormat,
  numberFormatInt,
  selectDropdownStyle,
  formatDateNoTime,
  firstDayOfMonth,
  lastDayOfMonth,
} from "../../../Helpers/Utils/Common";
import Moment from "moment";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";

import toast from "react-hot-toast";
import DeleteModal from "../../../Components/Modals/DeleteModal";
import {
  approveSuppliesExpense,
  changeStatus,
} from "../../../Helpers/apiCalls/Purchases/suppliesExpensesApi";
import { getType } from "../../../Helpers/Utils/Common";
import "../PurchaseOrders/PurchaseOrders.css";
import SEModal from "./Components/SEModal";
import { searchSE } from "../../../Helpers/apiCalls/Purchases/suppliesExpensesApi";
import { getAllSuppliers, getAccountDetails } from "../../../Helpers/apiCalls/suppliersApi";
import { getAllExpenseType } from "../../../Helpers/apiCalls/expensetypesApi";
import { getAllVendors } from "../../../Helpers/apiCalls/Manage/Vendors";
import POModal from "../PurchaseOrders/Components/POModal";
import PoPaymentModal from "./Components/PoPaymentModal";
import Select from "react-select";
// import DatePicker from "react-datepicker";
import { DatePicker } from "antd";
import { Checkbox } from 'antd';
import { CSVLink } from "react-csv";
import moment from "moment"; // Add this import for date handling
import downloadIcon from "../../../Assets/Images/download_icon.png"; // Import the download icon

const {RangePicker} = DatePicker;

const onChange = e => {
  console.log(`checked = ${e.target.checked}`);
};

function CheckboxCell({ checked, onChange }) {
  return <Checkbox checked={checked} onChange={onChange} />;
}

export default function SuppliesExpenses() {
  let navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [supExpenseID, setSupExpenseID] = useState("");
  const [userType, setUserType] = useState(getType());
  const [totalExpenses, setTotalExpenses] = useState(0);

  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  async function handleDeleteSuppliesExpense() {
    const response1 = await approveSuppliesExpense(supExpenseID.id, "deleted");

    if (response1.data.response) {
      toast.success("Supplies Expense Deleted Successfully!", {
        style: toastStyle(),
      });
      setShowDeleteModal(false);
      fetchSuppliers();
      searchSuppliesExpense();
      // setTimeout(() => refreshPage(), 1000);
    } else {
      toast.error("Error Deleting Supplies Expense", {
        style: toastStyle(),
      });
    }
  }
  /* return modal handler */
  const [showReturnModal, setShowReturnModal] = useState(false);
  const handleShowReturnModal = () => setShowReturnModal(true);
  const handleCloseReturnModal = () => setShowReturnModal(false);

  /* close modal handler */
  const [showClosePOModal, setShowClosePOModal] = useState(false);
  const handleShowClosePOModal = () => setShowClosePOModal(true);
  const handleCloseClosePOModal = () => setShowClosePOModal(false);

  const [inactive, setInactive] = useState(true);
  const [suppliesExpenseManager, setSuppliesExpenseManager] = useState([]);
  const [bal, setBal] = useState("");
  /* add payment modal handler */
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const handleShowAddPaymentModal = () => setShowAddPaymentModal(true);
  const handleCloseAddPaymentModal = () => setShowAddPaymentModal(false);

  const [suppliers, setSuppliers] = useState([]);

  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    setSupplierList(
      suppliers.map((supplier) => {
        return {
          label: supplier.trade_name,
          value: supplier.id + "|" + supplier.type,
        };
      })
    );
    setSupplierList((branches) => {
      var newBranches = [...branches];
      newBranches.push({ label: "All Suppliers", value: "" });
      return newBranches.reverse();
    });
  }, [suppliers]);
 
  async function fetchAllExpenseType() {
    setShowLoader(true);
    const response = await getAllExpenseType();
    if (response.data) {
        var types = response.data.map((type) => {
            return {
                label: type.name,
                value: type.id,
            };
        }); // Corrected closing parenthesis
        setTypeList(types);
    } else {
        setTypeList([]);
    }
    setShowLoader(false);
}

  function handleSupplierChange(e) {
    setSelectedSupplier(e);
    const toFilter = { target: { name: "supplier_id", value: e.value } };
    handleFilterChange(toFilter);
  }

  function handleTypeChange(e) {
    setSelectedType(e);
    const toFilter = { target: { name: "type", value: e?.value || "" } };
    handleFilterChange(toFilter);
  }

  async function fetchSuppliers() {
    setSuppliers([]);
    const suppliersResponse = await getAllSuppliers();
    const vendorsResponse = await getAllVendors();

    if (suppliersResponse.error) {
      TokenExpiry(suppliersResponse);
    } else {
      suppliersResponse.data.data.map((supplier) => {
        var info = supplier;
        info.type = "mango|supplier";
        setSuppliers((prev) => [...prev, info]);
      });
    }

    if (vendorsResponse.error) {
      TokenExpiry(vendorsResponse);
    } else {
      vendorsResponse.response.data.map((vendor) => {
        var info = vendor;
        info.type = "mango|vendor";
        setSuppliers((prev) => [...prev, info]);
      });
    }
  }

  async function handleReceiveSE(id) {
    navigate("/se/purchaseinvoices/add/" + id);
  }

  async function handleReturnSE() {
    const response = await approveSuppliesExpense(supExpenseID.id, "for approval");
    if (response.data.response) {
      toast.success("Purchase Order Returned to For Approval", {
        style: toastStyle(),
      });
      // setTimeout(() => refreshPage(), 1000);
      setShowReturnModal(false);
      fetchSuppliers();
      searchSuppliesExpense();
    } else {
      toast.error("Error Returning Purchase Order to For Approval", {
        style: toastStyle(),
      });
    }
  }

  const [selectedSEId, setSelectedSEId] = useState("");

  async function handleSelectChange(e, suppID, type, row) {
    const { id, value } = e.target;
    setSupExpenseID({
      id: id,
      supplier: suppID,
      name: row.supplier_trade_name
        ? row.supplier_trade_name
        : row.vendor_trade_name,
    });

    var _baseURL = "suppliesexpenses/";

    if (value === "edit-supExpense") {
      window.open(_baseURL + "edit/" + type + "/" + id, "_blank");
    } else if (value === "delete-supExpense") {
      handleShowDeleteModal();
    } else if (value === "return-supExpense") {
      handleShowReturnModal();
    } else if (value === "print-supExpense") {
      navigate("print/" + id, {
        state: { type: type, suppID: suppID },
      });
    } else if (value === "review-supExpense") {
      window.open(_baseURL + "review/" + id, "_blank");
    } else if (value === "email-supExpense") {
      navigate("print/" + id, {
        state: { type: type, suppID: suppID },
      });
    } else if (value === "view-supExpense") {
      window.open(_baseURL + "review/" + id, "_blank");
    } else if (value === "receive-supExpense") {
      handleReceiveSE(id);
    } else if (value === "close-supExpense") {
      setSelectedSEId(id);
      handleShowClosePOModal();
    } else if (value === "payment-po") {
      setBal(numberFormatInt(row.grand_total));
      handleShowAddPaymentModal();
    } else if (value === "paybank-supExpense") {
      try {
        const response = await getAccountDetails(id);
        if (response.data) {
          navigate(`/se/paysuppliers/add/bank?id=${id}`, {
            state: { 
              accountNo: response.data.account_no, 
              accountName: response.data.account_name,
              supplierName: row.supplier_trade_name,
              payee: row.supplier_trade_name,
              supplier_id: row.supplier_id
            },
          });
        } else {
          console.error("Failed to fetch account details");
        }
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    } else if (value === "paycash-supExpense") {
      try {
        const response = await getAccountDetails(id);
        if (response.data) {
          navigate(`/se/paysuppliers/add/cash?id=${id}`, {
            state: { 
              accountNo: response.data.account_no, 
              accountName: response.data.account_name,
              supplierName: row.supplier_trade_name,
              payee: row.supplier_trade_name,
              supplier_id: row.supplier_id
            },
          });
        } else {
          console.error("Failed to fetch account details");
        }
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    } else if (value === "paycheck-supExpense") {
      try {
        const response = await getAccountDetails(id);
        if (response.data) {
          navigate(`/se/paysuppliers/add/check?id=${id}`, {
            state: { 
              accountNo: response.data.account_no, 
              accountName: response.data.account_name,
              supplierName: row.supplier_trade_name,
              payee: row.supplier_trade_name,
              supplier_id: row.supplier_id
            },
          });
        } else {
          console.error("Failed to fetch account details");
        }
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    } else if (value === "paygcash-supExpense") {
      try {
        const response = await getAccountDetails(id);
        if (response.data) {
          navigate(`/se/paysuppliers/add/gcash?id=${id}`, {
            state: { 
              accountNo: response.data.account_no, 
              accountName: response.data.account_name,
              supplierName: row.supplier_trade_name,
              payee: row.supplier_trade_name,
              supplier_id: row.supplier_id
            },
          });
        } else {
          console.error("Failed to fetch account details");
        }
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    }
  }

  async function handleClosePO() {
    var data = {
      se_id: selectedSEId,
      new_status: "complete",
    };
    const response = await changeStatus(data);
    if (response.data) {
      toast.success(response.data.response, {
        style: toastStyle(),
      });
      handleCloseClosePOModal();
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: "complete",
          order_status: "complete",
        };
      });
    } else {
      TokenExpiry(response);
      toast.error("Error closing supplies purchase order", {
        style: toastStyle(),
      });
    }
  }

  const today = moment().format("YYYY-MM-DD"); // Define today's date

  const [filterConfig, setFilterConfig] = useState({
    tab: "for_approval",
    project_id: "",
    status: "for_approval",
    order_status: "",
    date_from: today, // Default to today's date
    date_to: today,   // Default to today's date
  });

  async function searchSuppliesExpense(filterConfig) {
    setSuppliesExpenseManager([]);
    setShowLoader(true);
    const response = await searchSE(filterConfig);
    if (response.data) {
      setTotalExpenses(response.data.total_expenses); // Store total_expenses
      var SE = response.data.data.map((se) => {
        var info = se;
        info.supplies_expense_date = formatDateNoTime(se.supplies_expense_date)
        info.supplier_trade_name = se.supplier_id
          ? se.supplier_trade_name
          : se.vendor_id
          ? se.vendor_trade_name
          : "N/A";
        info.grand_total = numberFormat(info.grand_total);
        info.invoice = se.invoice_no?.map((invoice) => {
          return invoice.invoice_no ? invoice.invoice_no : "";
        });
        info.invoice_id = se.invoice_no?.map((invoice) => {
          return invoice.id ? invoice.id : "";
        });
        info.pay_ref_no = !se.payments
          ? ""
          : se.payments[0]?.payment_mode === "check"
          ? se.payments[0]?.payment_mode + " - " + se.payments[0]?.check_no
          : se.payments[0]?.payment_mode === "bank"
          ? se.payments[0]?.payment_mode + " - " + se.payments[0]?.reference_no
          : se.payments[0]?.payment_mode;
        info.pay_id_no = !se.payments
          ? ""
          : se.payments[0]?.se_id
          ? se.payments[0].se_id
          : "";
        info.status = se.status === "approved" 
          ? "Approved" 
          : se.status === "disapproved" 
          ? "Disapproved" 
          : "For Approval";
        info.order_status = se.order_status === "complete"
          ? "Complete"
          : se.order_status === "incomplete"
          ? "Incomplete"
          : "Pending";
        return info;
      });
      setSuppliesExpenseManager(
        SE.sort(
          (a, b) =>
            new Date(...a.supplies_expense_date?.split("/").reverse()) -
            new Date(...b.supplies_expense_date?.split("/").reverse())
        ).reverse()
      );
    } else {
      setSuppliesExpenseManager([]);
      setTotalExpenses(0); // Reset total_expenses if no data
    }
    setShowLoader(false);
  }

  const handleTabSelect = (tabKey) => {
    setSelectedSupplier(null); 
    if (tabKey === "complete") {
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: "",
          order_status: tabKey,
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: firstDayOfMonth(),
          date_to: lastDayOfMonth(),
        };
      });
    } else if (tabKey === "incomplete") {
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: "",
          order_status: tabKey,
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: null,
          date_to: null,
        };
      });
    } 
    else if (tabKey === "all") {
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: tabKey,
          order_status: "",
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: firstDayOfMonth(),
          date_to: lastDayOfMonth(),
        };
      });
    } 
    else {
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: tabKey,
          order_status: "",
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: null,
          date_to: null,
        };
      });
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "supplier_id") {
      var id = value.split("|")[0];
      var type = value.split("|")[1];
      var by = value.split("|")[2];
      if (by === "supplier") {
        setFilterConfig((prev) => {
          return {
            ...prev,
            ["supplier_id"]: id,
            ["vendor_id"]: "",
            ["type"]: type,
          };
        });
      } else {
        setFilterConfig((prev) => {
          return {
            ...prev,
            ["supplier_id"]: "",
            ["vendor_id"]: id,
            ["type"]: type,
          };
        });
      }
    } else {
      setFilterConfig((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  async function handleBulkAction(action) {
    const status = action === "approve" ? "approved" : "disapproved";
    const selectedIds = selectedRows.filter(row => row.selected).map(row => row.id);
  
    for (const id of selectedIds) {
      await approveSuppliesExpense(id, status);
    }
  
    toast.success(`Supplies Expenses ${status.charAt(0).toUpperCase() + status.slice(1)} Successfully!`, {
      style: toastStyle(),
    });
  
    fetchSuppliers();
    searchSuppliesExpense(filterConfig);
  }

  React.useEffect(() => {
    searchSuppliesExpense(filterConfig);
  }, [filterConfig]);

  React.useEffect(() => {
    setSelectedRows(
      suppliesExpenseManager.map((row) => ({ ...row, selected: false }))
    ); // Initialize selectedRows properly
  }, [suppliesExpenseManager]);

  function ContractBtn(row) {
    return (
      <span
        className="me-4 align-middle ps-label"
        onClick={() => handleViewContract(row.pay_id_no)}
      >
        {row.pay_ref_no}
      </span>
    );
  }
  function handleViewContract(id, type) {
    {
      window.open("/se/purchaseinvoices/print/" + id);
    }
  }

  function ViewBtn(row) {
    return (
      <>
        {filterConfig.tab === "complete" && (
          <button
            name="action"
            className="btn btn-sm view-btn-table me-1"
            id={row.id}
            onClick={(e) => handleSelectChange(e, row.supplier_id, "sent", row)}
            value="view-supExpense"
          >
            View
          </button>
        )}
        {filterConfig.tab === "all" && (
          <button
            name="action"
            className="btn btn-sm view-btn-table me-1"
            id={row.id}
            onClick={(e) => window.open(`/suppliesexpenses/review/${row.id}`, "_blank")}
            value="view-supExpense"
          >
            View
          </button>
        )}
        {filterConfig.tab === "incomplete" && (
          <>
            <button
              name="action"
              className="btn btn-sm view-btn-table me-1"
              id={row.id}
              onClick={(e) => handleSelectChange(e, row.supplier_id, "sent", row)}
              value="view-supExpense"
            >
              View
            </button>
            <button
              name="action"
              className="btn btn-sm view-btn-table me-1"
              id={row.id}
              onClick={(e) => handleSelectChange(e, row.supplier_id, "sent", row)}
              value="close-supExpense"
            >
              Close
            </button>
            <button
              name="action"
              className="btn btn-sm view-btn-table"
              id={row.id}
              onClick={(e) => handleSelectChange(e, row.supplier_id, "sent", row)}
              value="receive-supExpense"
            >
              Receive
            </button>
          </>
        )}

        {filterConfig.tab === "for_approval" && (
          <button
            name="action"
            className="btn btn-sm view-btn-table me-1"
            id={row.id}
            onClick={() => window.location.href = `/suppliesexpenses/review/${row.id}`}
            value="view-supExpense"
          >
            View
          </button>
        )}
      </>
    );
  }

  function InvoiceBtn(row) {
    return (
      <div className="text-center">
        <span
          className="ps-label"
          onClick={() => handleViewInvoice(row.invoice_id)}
        >
          {row.invoice}
        </span>
      </div>
    );
  }

  function handleViewInvoice(id) {
    window.open("/se/purchaseinvoices/print/" + id);
  }

  function EditBtn(row, type) {
    return (
      <button
        name="action"
        className="btn btn-sm view-btn-table"
        id={row.id}
        onClick={(e) => handleSelectChange(e, row.supplier_id, type, row)}
        value="edit-supExpense"
      >
        Edit
      </button>
    );
  }

  function ReviewBtn(row, type) {
    return (
      <button
        name="action"
        className="btn btn-sm view-btn-table"
        id={row.id}
        onClick={(e) => handleSelectChange(e, row.supplier_id, type, row)}
        value="review-supExpense"
      >
        Review
      </button>
    );
  }

  function ReceivePOBtn(row, type) {
    return (
      <button
        name="action"
        className="btn btn-sm view-btn-table"
        id={row.id}
        onClick={(e) => handleSelectChange(e, row.supplier_id, type, row)}
        value="receive-supExpense"
      >
        Receive SE
      </button>
    );
  }

  function ReturnBtn(row, type) {
    return (
      <button
        name="action"
        className="btn btn-sm view-btn-table"
        id={row.id}
        onClick={(e) => handleSelectChange(e, row.supplier_id, type, row)}
        value="return-supExpense"
      >
        Return to Pending
      </button>
    );
  }

  function ActionBtn(row, type) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        id={row.id}
        onChange={(e) => handleSelectChange(e, row.supplier_id, type, row)}
      >
        <option value="" hidden>
          Select
        </option>
        {type === "for_approval" ? (
          <>
            <option value="view-supExpense" className="color-options">View</option>
            <option value="edit-supExpense" className="color-options">Edit</option>
            <option value="delete-supExpense" className="color-red">Delete</option>
          </>
        ) : type === "disapproved" ? (
          <>
            <option value="return-supExpense" className="color-options">Return</option>
            <option value="view-supExpense" className="color-options">View</option>
            <option value="delete-supExpense" className="color-red">Delete</option>
          </>
        ) : type === "approved" || type === "incomplete" ? (
          <>
            <option value="view-supExpense" className="color-options">
              View
            </option>
            <option value="paybank-supExpense" className="color-options">
              Bank to Bank
            </option>
            <option value="paycash-supExpense" className="color-options">
              Cash
            </option>
            <option value="paycheck-supExpense" className="color-options">
              Check
            </option>
            <option value="paygcash-supExpense" className="color-options">
              Gcash
            </option>
            <option value="delete-supExpense" className="color-red">
              Delete
            </option>
          </>
        ) : (
          <>
            <option value="view-supExpense" className="color-options">View</option>
            <option value="edit-supExpense" className="color-options">Edit</option>
            <option value="delete-supExpense" className="color-red">Delete</option>
          </>
        )}
      </Form.Select>
    );
  }
  

  function AddPaymentBtn(row, type) {
    if (row.can_be_paid === "0") {
      return (
        <button
          name="action"
          className="btn btn-sm view-btn-table"
          id={row.id}
          onClick={(e) => handleSelectChange(e, row.supplier_id, type, row)}
          value="payment-po"
        >
          Add Payment
        </button>
      );
    }
  }

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAllChange = e => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedRows(suppliesExpenseManager.map(row => ({ ...row, selected: checked })));
  };

  const handleRowCheckboxChange = (e, row) => {
    const checked = e.target.checked;
    setSelectedRows(prev =>
      prev.map(r => (r.id === row.id ? { ...r, selected: checked } : r))
    );
  };

  React.useEffect(() => {
    fetchSuppliers();
    fetchAllExpenseType();
  }, []);

  const csvHeaders = [
    { label: "PO NO.", key: "id" },
    { label: "DATE", key: "supplies_expense_date" },
    { label: "SUPPLIER", key: "supplier_trade_name" },
    { label: "TYPE", key: "expense_name" },
    { label: "REMARKS", key: "remarks" },
    { label: "TOTAL", key: "grand_total" },
    { label: "STATUS", key: "status" },
    { label: "ORDER STATUS", key: "order_status" },
    { label: "PREPARED BY", key: "prepared_by_name" },
    { label: "APPROVED BY", key: "approved_by_name" },
  ];

  function handleToCSV() {
        return (
          <CSVLink
            data={suppliesExpenseManager}
            headers={csvHeaders}
            filename={`PurchaseOrder_${Moment().format("YYYY-MM-DD")}.csv`}
            style={{ textDecoration: "none", color: "#ffffff" }}
          >
            Export to CSV
          </CSVLink>
        );
      }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"EXPENSE"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-6">
          <Col xs={6}>
            <h1 className="page-title"> PURCHASE ORDERS </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end mt-4">
          <button className="add-btn text-center">{handleToCSV()}</button>
            <button
              className="add-btn"
              onClick={() => navigate("/suppliesexpenses/add")}
            >
              Add
            </button>
          </Col>
        </Row>

        {/* tabs */}
        <Tabs
          activeKey={filterConfig.tab}
          defaultActiveKey={filterConfig.tab}
          id="SE-tabs"
          onSelect={handleTabSelect}
          className="TabStyle1"
        >
          <Tab eventKey="for_approval" title="for approval">
            {/* filters */}
            <div className="my-2 px-4 PO-filters d-flex justify-content-between">
              <div className="d-flex">
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Supplier"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedSupplier}
                  options={supplierList}
                  onChange={handleSupplierChange}
                />
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Type"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedType}
                  options={typeList}
                  onChange={handleTypeChange}
                />
                <RangePicker
                  placeholder={[
                    filterConfig.date_from ?? today,
                    filterConfig.date_to ?? today,
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: e[0].format("YYYY-MM-DD"),
                        date_to: e[1].format("YYYY-MM-DD"),
                      }));
                    } else {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: today,
                        date_to: today,
                      }));
                    }
                  }}
                />
              </div>
              <span className="align-self-center">Total Expenses: {numberFormat(totalExpenses)}</span>
            </div>
            {/* table */}
            <Table
              tableHeaders={[
                "SELECT",
                "PO NO.",
                "DATE",
                "SUPPLIER",
                "TYPE",
                "REMARKS",
                "TOTAL",
                "ACTIONS",
              ]}
              headerSelector={[
                "select",
                "id",
                "supplies_expense_date",
                "supplier_trade_name",
                "expense_name",
                "remarks",
                "grand_total",
                "prepared_by_name",
              ]}
              tableData={suppliesExpenseManager}
              ViewBtn={(row) => ViewBtn(row, "for approval")}
              PendingBtn={(row) => EditBtn(row, "for approval")}
              ActionBtn={(row) => ActionBtn(row, "for approval")}
              CheckboxCell={CheckboxCell}
              selectAll={selectAll}
              handleSelectAllChange={handleSelectAllChange}
              handleRowCheckboxChange={handleRowCheckboxChange}
              selectedRows={selectedRows}
              showLoader={showLoader}
              className={{
                supplier_trade_name: "text-start text-wrap ps-2 align-top",
                remarks: "text-start text-break ps-2 align-top",
                grand_total: "text-end pe-2",
              }}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="approved" title="approved">
            {/* filters */}
            <div className="my-2 px-4 PO-filters d-flex justify-content-between">
              <div className="d-flex">
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Supplier"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedSupplier}
                  options={supplierList}
                  onChange={handleSupplierChange}
                />
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Type"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedType}
                  options={typeList}
                  onChange={handleTypeChange}
                />
                <RangePicker
                  placeholder={[
                    filterConfig.date_from ?? today,
                    filterConfig.date_to ?? today,
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: e[0].format("YYYY-MM-DD"),
                        date_to: e[1].format("YYYY-MM-DD"),
                      }));
                    } else {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: null,
                        date_to: null,
                      }));
                    }
                  }}
                />
              </div>
              <span className="align-self-center">Total Expenses: {numberFormat(totalExpenses)}</span>
            </div>
            {/* table */}
            <Table
              tableHeaders={[
                "PO NO.",
                "DATE",
                "SUPPLIER",
                "TYPE",
                "REMARKS",
                "TOTAL",
                "PREP BY",
                "APV BY",
                "ACTIONS",
              ]}
              headerSelector={[
                "id",
                "supplies_expense_date",
                "supplier_trade_name",
                "expense_name",
                "remarks",
                "grand_total",
                "prepared_by_name",
                "approved_by_name",
              ]}
              tableData={suppliesExpenseManager}
              PendingBtn={(row) => ReceivePOBtn(row, "sent")}
              ActionBtn={(row) => ActionBtn(row, "approved")}
              PaymentBtn={(row) => AddPaymentBtn(row, "sent")}
              showLoader={showLoader}
              className={{
                supplier_trade_name: "text-start text-wrap ps-2 align-top",
                expense_name: "text-start text-wrap ps-2 align-top",
                remarks: "text-start text-break ps-2 align-top",
                grand_total: "text-end pe-2",
              }}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="incomplete" title="unpaid">
            {/* filters */}
            <div className="my-2 px-4 PO-filters d-flex justify-content-between">
              <div className="d-flex">
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Supplier"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedSupplier}
                  options={supplierList}
                  onChange={handleSupplierChange}
                />
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Type"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedType}
                  options={typeList}
                  onChange={handleTypeChange}
                />
                <RangePicker
                  placeholder={[
                    filterConfig.date_from ?? today,
                    filterConfig.date_to ?? today,
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: e[0].format("YYYY-MM-DD"),
                        date_to: e[1].format("YYYY-MM-DD"),
                      }));
                    } else {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: today,
                        date_to: today,
                      }));
                    }
                  }}
                />
              </div>
              <span className="align-self-center">Total Expenses: {numberFormat(totalExpenses)}</span>
            </div>
            {/* table */}
            <Table
              tableHeaders={[
                "PO NO.",
                "DATE",
                "SUPPLIER",
                "TYPE",
                "REMARKS",
                "TOTAL",
                "PREP BY",
                "APV BY",
                "ACTIONS",
              ]}
              headerSelector={[
                "id",
                "supplies_expense_date",
                "supplier_trade_name",
                "expense_name",
                "remarks",
                "grand_total",
                "prepared_by_name",
                "approved_by_name",
              ]}
              tableData={suppliesExpenseManager}
              PendingBtn={(row) => ViewBtn(row)}
              ActionBtn={(row) => ActionBtn(row, "incomplete")}
              showLoader={showLoader}
              className={{
                supplier_trade_name: "text-start text-wrap ps-2 align-top",
                expense_name: "text-start text-wrap ps-2 align-top",
                grand_total: "text-end pe-2",
              }}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="complete" title="paid">
            {/* filters */}
            <div className="my-2 px-4 PO-filters d-flex justify-content-between">
              <div className="d-flex">
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Supplier"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedSupplier}
                  options={supplierList}
                  onChange={handleSupplierChange}
                />
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Type"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedType}
                  options={typeList}
                  onChange={handleTypeChange}
                />
                <RangePicker
                  placeholder={[
                    filterConfig.date_from ?? today,
                    filterConfig.date_to ?? today,
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: e[0].format("YYYY-MM-DD"),
                        date_to: e[1].format("YYYY-MM-DD"),
                      }));
                    } else {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: today,
                        date_to: today,
                      }));
                    }
                  }}
                />
              </div>
              <span className="align-self-center">Total Expenses: {numberFormat(totalExpenses)}</span>
            </div>
            {/* table */}
            <Table
              tableHeaders={[
                "PO NO.",
                "DATE",
                "SUPPLIER",
                "TYPE",
                "REMARKS",
                "TOTAL",
                "PYMT REF NO",
                "PREP BY",
                "APV BY",
              ]}
              headerSelector={[
                "id",
                "supplies_expense_date",
                "supplier_trade_name",
                "expense_name",
                "remarks",
                "grand_total",
                "payment_ref_no",
                "prepared_by_name",
                "approved_by_name",
              ]}
              tableData={suppliesExpenseManager}
              onRowClicked={(row) => window.open(`/suppliesexpenses/review/${row.id}`, "_blank")}
              InvoiceBtn={(row) => InvoiceBtn(row)}
              newTabBtn={(row) => ContractBtn(row, row.status)}
              showLoader={showLoader}
              className={{
                supplier_trade_name: "text-start text-wrap ps-2 align-top",
                expense_name: "text-start text-wrap ps-2 align-top",
                invoice: "text-center text-wrap align-top",
                payment_ref_no: "text-start text-wrap ps-2 align-top",
                grand_total: "text-end pe-2",
              }}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="all" title="all">
            {/* filters */}
            <div className="my-2 px-4 PO-filters d-flex justify-content-between">
              <div className="d-flex">
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Supplier"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedSupplier}
                  options={supplierList}
                  onChange={handleSupplierChange}
                />
                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="Select Type"
                  styles={{
                    ...selectDropdownStyle,
                    container: (base) => ({
                      ...base,
                      width: "250px",
                    }),
                  }}
                  value={selectedType}
                  options={typeList}
                  onChange={handleTypeChange}
                />
                <RangePicker
                  placeholder={[
                    filterConfig.date_from ?? today,
                    filterConfig.date_to ?? today,
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: e[0].format("YYYY-MM-DD"),
                        date_to: e[1].format("YYYY-MM-DD"),
                      }));
                    } else {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: today,
                        date_to: today,
                      }));
                    }
                  }}
                />
              </div>
              <span className="align-self-center">Total Expenses: {numberFormat(totalExpenses)}</span>
            </div>
            {/* table */}
            <Table
              tableHeaders={[
                "PO NO.",
                "DATE",
                "SUPPLIER",
                "TYPE",
                "TOTAL",
                "PYMT REF NO",
                "STATUS",
                "ORDR STATUS",
                "PREP BY",
                "APV BY",
              ]}
              headerSelector={[
                "id",
                "supplies_expense_date",
                "supplier_trade_name",
                "expense_name",
                "grand_total",
                "payment_ref_no",
                "status",
                "order_status",
                "prepared_by_name",
                "approved_by_name",
              ]}
              tableData={suppliesExpenseManager}
              onRowClicked={(row) => window.open(`/suppliesexpenses/review/${row.id}`, "_blank")}
              InvoiceBtn={(row) => InvoiceBtn(row)}
              newTabBtn={(row) => ContractBtn(row, row.status)}
              showLoader={showLoader}
              className={{
                supplier_trade_name: "text-start text-wrap ps-2 align-top",
                expense_name: "text-start text-wrap ps-2 align-top",
                invoice: "text-center text-wrap align-top",
                payment_ref_no: "text-start text-wrap ps-2 align-top",
                grand_total: "text-end pe-2",
              }}
            />
            <div className="mb-2" />
          </Tab>
        </Tabs>
        {/* Add Approve and Disapprove buttons */}
        {filterConfig.tab === "for_approval" && (
          <div className="d-flex justify-content-end mt-3">
            <button className="button-primary me-2" onClick={() => handleBulkAction("approve")}>Approve</button>
            <button className="button-warning" onClick={() => handleBulkAction("disapprove")}>Disapprove</button>
          </div>
        )}
      </div>

      {/* modals */}
      <DeleteModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        text="supplies expense"
        onDelete={handleDeleteSuppliesExpense}
      />
      <SEModal
        show={showReturnModal}
        hide={handleCloseReturnModal}
        type="return"
        handler={handleReturnSE}
      />
      <POModal
        show={showClosePOModal}
        hide={handleCloseClosePOModal}
        type="close"
        handler={handleClosePO}
      />
      <PoPaymentModal
        id={supExpenseID.id}
        show={showAddPaymentModal}
        onHide={handleCloseAddPaymentModal}
        balance={bal}
        payee={supExpenseID.name}
      />
    </div>
  );
}
