import React, { useState} from "react";
import {Col,Form,Row,} from "react-bootstrap";
import ReactSelect from "react-select";
import { Modal, Form as AntForm, Button, Input, Select } from "antd";
//components
import Table from "../../Components/TableTemplate/Table";
import Navbar from "../../Components/Navbar/Navbar";
import DeleteModal from "../../Components/Modals/DeleteModal";
import AddModal from "../../Components/Modals/AddModal";
import EditModal from "../../Components/Modals/EditModal";
import ViewModal from "../../Components/Modals/ViewModal";

//css
import "./Manage.css";
import "../../Components/Navbar/Navbar.css";
import {
  createCustomer,
  deleteCustomer,
  getAllCustomer,
  getAllLeads,
  searchCustomer,
  updateCustomer,
} from "../../Helpers/apiCalls/Manage/CustomerAPI";
import { validateCustomer } from "../../Helpers/Validation/Manage/CustomerValidation";
import toast from "react-hot-toast";
import {
  toastStyle,
  isAdmin,
} from "../../Helpers/Utils/Common";
import InputError from "../../Components/InputError/InputError";

export default function Customer() {
  const [customerForm] = AntForm.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [inactive, setInactive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [lead, setLead] = useState([])


  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // VIEW
  const [showViewCustomerModal, setShowViewCustomerModal] = useState(false);
  const handleShowViewProjectModal = () => setShowViewCustomerModal(true);
  const handleCloseViewProjectModal = () => setShowViewCustomerModal(false);

  // EDIT
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const handleShowEditProjectModal = () => setShowEditCustomerModal(true);
  const handleCloseEditProjectModal = () => setShowEditCustomerModal(false);

  // ADD
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  //REQUIRED ERROR HANDLING
  const [isError, setIsError] = useState({
    name: false,
    company: false,
    // address: false,
  });

  //API
  const [customerData, setCustomerData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [addCustomerData, setaddCustomerData] = useState({
    name: "",
    company: "",
    address: "",
    contact_number: "",
    email: "",
    tin_no: "",
    contact_person: "",
    lead: "",
    terms: "",
    credit_limit: "",
  });

  const [editCustomerData, seteditCustomerData] = useState({
    name: "",
    company: "",
    address: "",
    contact_number: "",
    email: "",
    tin_no: "",
    contact_person: "",
    lead: "",
    terms: "",
    credit_limit: "",
  });

  function resetValues () {
    setaddCustomerData({});
  }

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setaddCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    seteditCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //DROPDOWN
  function handleSelectChange(e, row) {
    seteditCustomerData(row);
    setShowLoader(true);
    setSelectedRow(row);
    if (e.target.value === "delete-customer") {
      handleShowDeleteModal();
    } else if (e.target.value === "edit-customer") {
      handleShowEditProjectModal();
    } else if (e.target.value === "view-customer") {
      seteditCustomerData(row);
      handleShowViewProjectModal();
    } else {
      handleShowDeleteModal();
    }
    setShowLoader(false);
  }

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action form-select"
        id={row}
        onChange={(e) => handleSelectChange(e, row)}
        value={""}
      >
        <option defaulValue selected hidden>
          Select
        </option>
        <option value="view-customer" className="color-options">
          View
        </option>
        {isAdmin && (
          <option value="edit-customer" className="color-options">
            Edit
          </option>
        )}
        {isAdmin && (
          <option value="delete-customer" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

//API CALL
  async function fetchAllCustomer() {
    setShowLoader(true);
    const response = await getAllCustomer();
      if (response.data) {
        setCustomerData(response.data);
      } else {
        setCustomerData([]);
      }
      setShowLoader(false);
    }
  
  async function create() {
    if (validateCustomer(addCustomerData, setIsError)) {
      setIsClicked(true);
      const response = await createCustomer(addCustomerData);
      if (response) {
        if (response?.data?.status === "success") {
          toast.success("Successfully added customer!", {
            style: toastStyle(),
          });
          setShowAddCustomerModal(false);
          fetchCustomer();
        }
        if (response?.error?.data?.messages?.name) {
          toast.error(response.error.data.messages.name, {
            style: toastStyle(),
          });
        }
        if (response?.error?.data?.messages?.account_name) {
          toast.error(response.error.data.messages.account_name, {
            style: toastStyle(),
          });
        }
      }
      setIsClicked(false);
    }
  }
  

  async function fetchCustomer() {
    setCustomerData([]);
    // setFilteredData([]);
    setShowLoader(true);
    const response = await searchCustomer(searchText);
    if (response.data) {
      const result = response.data.data.map((a) => {
        return {
          ...a,
          formatted_credit_limit: a.credit_limit
          ? Number(a.credit_limit).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",           action_btn: ActionBtn(a),
        };
      });
      // setFilteredData(result);
      setCustomerData(result);
    }
    setShowLoader(false);
  }

  async function fetchLeads() {
    var response = await getAllLeads();
  
    if (response.data) {
      var data = response.data.data.map((lead) => ({
        ...lead,
        label: lead.lead
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase()),
        value: lead.lead,
      }));
  
      setLead(data);
    }
  }  

  async function del() {
    const response = await deleteCustomer(selectedRow.id);
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.data.response, {
          style: toastStyle(),
        });
        handleCloseDeleteModal();
        fetchCustomer();
        // setTimeout(() => refreshPage(), 1000);
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
    }
  }

  async function editCustomer() {
    if (validateCustomer(editCustomerData, setIsError)) {
      setIsClicked(true)
      const response = await updateCustomer(editCustomerData);
      if (response) {
        if (response?.data?.status === "success") {
          toast.success(response.data.response, {
            style: toastStyle(),
          });
          setShowEditCustomerModal(false);
          fetchCustomer();
        } else {
          toast.error(response.error.data.messages.error, {
            style: toastStyle(),
          });
        }
      }
      setIsClicked(false)
    }
  }

  React.useEffect(() => {
    fetchCustomer();
    fetchLeads()
  }, []);

  // React.useEffect(() => {
  //   const temp = customerData.filter(item => {
  //     if (item&&item.name&&searchText) {
  //       return item.name.toLowerCase().includes(searchText.toLowerCase())
  //     } else return null
  //   });
  //   setFilteredData(temp)
  // }, [searchText, customerData])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"MANAGE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> CUSTOMER </h1>
          </Col>
          <Col className="d-flex justify-content-end">
          <input
              type="search"
              name="name"
              placeholder="Search Name..."
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              className="search-bar"
              onKeyDown={(e) => {if(e.key === 'Enter') {fetchCustomer()}}}
            />
            <button className="add-btn" onClick={() => setShowAddCustomerModal(true)}>
              Add
            </button>
          </Col>
        </Row>
  
        <div className="tab-content">
          <Table
            tableHeaders={[
              "NAME",
              "COMPANY",
              "ADDRESS",
              "PHONE NUMBER",
               "LEAD",
              "CONTACT PERSON",
              "CREDIT LIMIT",
              "TERMS",
              "ACTIONS",
            ]}
            headerSelector={[
              "name",
              "company",
              "address",
              "contact_number",
              "lead",
              "contact_person",
              "formatted_credit_limit",
              "terms",
              "action_btn",
            ]}
            tableData={customerData}
            showLoader={showLoader}
            withActionData={true}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="customer"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={del}
      />
      <AddModal
        title="CUSTOMER"
        size="lg"
        type="branch"
        show={showAddCustomerModal}
        onHide={() => setShowAddCustomerModal(false)}
        onSave={()=>create()}
        isClicked={isClicked}
        data={addCustomerData}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={addCustomerData.name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.name}
                message={"customer name is required"}
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={addCustomerData.company}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.company}
                message={"company name is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="contact_number"
                value={addCustomerData.contact_number}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
            <Col>
              EMAIL
              <Form.Control
                type="text"
                name="email"
                value={addCustomerData.email}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
            <Col>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin_no"
                value={addCustomerData.tin_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={addCustomerData.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
            <Col>
              LEAD <span className="required-icon"></span>
              <ReactSelect
                className="react-select-container nc-modal-custom-input"
                classNamePrefix="react-select"
                placeholder="Select Lead..."
                name="customer_id"
                value={
                  lead.find(option => option.value === addCustomerData.lead) ||
                  (addCustomerData.lead ? { label: addCustomerData.lead, value: addCustomerData.lead } : null) 
                }
                options={lead}
                isSearchable={true}
                onInputChange={(inputValue, { action }) => {
                  if (action === "input-change") {
                    handleAddChange({ target: { name: "lead", value: inputValue } });
                  }
                }}
                onChange={(selectedOption) =>
                  handleAddChange({ target: { name: "lead", value: selectedOption ? selectedOption.value : "" } })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              CREDIT LIMIT
              <Form.Control
                type="text"
                name="credit_limit"
                value={addCustomerData.credit_limit}
                className="nc-modal-custom-input"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, ""); 
                  handleAddChange({ target: { name: "credit_limit", value } });
                }}
                required
              />
            </Col>
            <Col>
              TERMS
              <Form.Control
                type="text"
                name="terms"
                value={addCustomerData.terms}
                className="nc-modal-custom-input"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); 
                  handleAddChange({ target: { name: "terms", value } });
                }}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
              <Col>
              ADDRESS<span className="required-icon"> *</span>
              <Form.Control
                type="text"
                name="address"
                value={addCustomerData.address}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.company}
                message={"address is required"}
              />
              </Col>
            </Row>
        </div>
      </AddModal>

      {/* <Modal title="Add Customer" open={showAddCustomerModal} onCancel={() => setShowAddCustomerModal(false)} width={600}>
        <AntForm layout="vertical" form={customerForm}>
          <Row>
            <Col xs={6}>
              <AntForm.Item name="name" label="Name" rules={[{required: true}]}>
                <Input/>
              </AntForm.Item>
            </Col>
            <Col xs={6}>
              <AntForm.Item name="company" label="Company" rules={[{required: true}]}>
                <Input/>
              </AntForm.Item>
            </Col>
            <Col xs={6}>
              <AntForm.Item name="contact_number" label="Phone Number" rules={[{required: true}]}>
                <Input/>
              </AntForm.Item>
            </Col>
            <Col xs={6}>
              <AntForm.Item name="contact_number" label="Phone Number" rules={[{required: true}]}>
                <Input/>
              </AntForm.Item>
            </Col>
          </Row>
        </AntForm>
      </Modal> */}
      
      <EditModal
        title="CUSTOMER"
        size="lg"
        type="project"
        show={showEditCustomerModal}
        onHide={handleCloseEditProjectModal}
        onSave={() => editCustomer()}
        data={editCustomerData}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={editCustomerData.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isError.name}
                message={"customer name is required"}
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={editCustomerData.company}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isError.company}
                message={"company name is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="contact_number"
                value={editCustomerData.contact_number}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col>
              EMAIL
              <Form.Control
                type="text"
                name="email"
                value={editCustomerData.email}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin_no"
                value={editCustomerData.tin_no}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              CONTACT PERSON
              {/* <span className="required-icon">*</span> */}
              <Form.Control
                type="text"
                name="contact_person"
                value={editCustomerData.contact_person}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              LEAD <span className="required-icon"></span>
              <ReactSelect
                className="react-select-container nc-modal-custom-input"
                classNamePrefix="react-select"
                placeholder="Select Lead..."
                name="customer_id"
                value={
                  lead.find(option => option.value === editCustomerData.lead) ||
                  (editCustomerData.lead ? { label: editCustomerData.lead, value: editCustomerData.lead } : null) 
                }
                options={lead.map((data) => ({
                  value: data.id,
                  label: data.lead,
                })) }
                isSearchable={true}
                onChange={(selectedOption) => {
                  handleEditChange({ 
                    target: { 
                      name: "lead", 
                      value: selectedOption ? selectedOption.value : "" 
                    } 
                  });
                  handleEditChange({ 
                    target: { 
                      name: "lead", 
                      value: selectedOption ? selectedOption.label : ""
                    } 
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              CREDIT LIMIT
              {/* <span className="required-icon">*</span> */}
              <Form.Control
                type="text"
                name="credit_limit"
                value={editCustomerData.credit_limit}
                className="nc-modal-custom-input-edit"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, ""); 
                  handleEditChange({ target: { name: "credit_limit", value } });
                }}
                required
              />
            </Col>
            <Col>
              TERMS
              {/* <span className="required-icon">*</span> */}
              <Form.Control
                type="text"
                name="terms"
                value={editCustomerData.terms}
                className="nc-modal-custom-input-edit"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); 
                  handleEditChange({ target: { name: "terms", value } });
                }}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
          <Col>
              ADDRESS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="address"
                value={editCustomerData.address}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isError.address}
                message={"Address is required"}
              />
            </Col>
          </Row>
        </div>
      </EditModal>
      <ViewModal
        withButtons
        size="lg"
        show={showViewCustomerModal}
        onHide={handleCloseViewProjectModal}
        onEdit={() => {setShowEditCustomerModal(true); setShowViewCustomerModal(false)}}
      >
        <div className="mt-1">
        <span className="custom-modal-body-title-forwarder-details">
              CUSTOMER DETAILS
            </span>
          <Row className="nc-modal-custom-row mt-3">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={editCustomerData.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={editCustomerData.company}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="contact_number"
                value={editCustomerData.contact_number}
                disabled
              />
            </Col>
            <Col>
              EMAIL
              <Form.Control
                type="text"
                name="email"
                value={editCustomerData.email}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin_no"
                value={editCustomerData.tin_no}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">

            <Col>
              CONTACT PERSON
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="contact_person"
                value={editCustomerData.contact_person}
                className="nc-modal-custom-input-edit"
                disabled

              />
            </Col>
            <Col>
              LEAD
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="lead"
                value={editCustomerData.lead}
                className="nc-modal-custom-input-edit"
                disabled

              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
          <Col>
              CREDIT LIMIT
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="credit_limit"
                value={
                  editCustomerData.credit_limit
                    ? Number(editCustomerData.credit_limit).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              TERMS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="terms"
                value={editCustomerData.terms}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
          <Col>
              ADDRESS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="address"
                value={editCustomerData.address}
                className="nc-modal-custom-input-edit"
                disabled
              />
              <InputError
                isValid={isError.address}
                message={"Address is required"}
              />
            </Col>
          </Row>
        </div>
      </ViewModal>

      {contextHolder}
    </div>
  );
}