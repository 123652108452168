import moment from "moment";
import { Fragment } from "react";
import React, { useState } from "react";
import { Col, Container, Row, Table, Form, Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  getTodayDateISO,
  numberFormat,
  refreshPage,
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import { searchProject } from "../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "../../Helpers/apiCalls/Manage/CustomerAPI";
import { dateFormat, getName } from "../../Helpers/Utils/Common";
import { getDistributorBilling, getOneDistributorBilling } from "../../Helpers/apiCalls/Distributor/DistributorApi";
import { getSubscriptionBillings } from "../../Helpers/apiCalls/Subscription/SubscriptionBilling";

export default function ViewSubscriptionBilling () {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentDate = new Date().toISOString().split("T")[0];
  const [inactive, setInactive] = useState(true);
  const [customersData, setCustomersData] = useState([]);
  const [allProjects, setAllProjects] = useState([])
  const [distributorDetails, setDistributorDetails] = useState({});
  const [recurringCosts, setRecurringCosts] = useState([]);

  async function handlePrint() {
    toast.loading("Printing billing details...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    // refreshPage();
  }

  async function getBillingDetails () {
    const response = await getSubscriptionBillings({billing_id: id});
    if (response.data) {
      const billingData = response.data.data[0];
      setDistributorDetails(billingData);
      if (response.data.data[0].subscription_billing_entries) {
        var tempGrandTotal = 0
        const tempCosts = response.data.data[0].subscription_billing_entries.map((data) => {
          var info = data;
          tempGrandTotal += Number(data.amount);
          info.billing_date = moment(data.subscription_billing_entry_date).format("YYYY-MM-DD");
          info.due_date = data.due_date;
          info.price = data.project_recurring_cost_price;
          return info;
        });
        setRecurringCosts(tempCosts);
      }
    }
  }

  React.useEffect(() => {
    getBillingDetails();
  }, []);

  const CustomerProjectsTable = () => {
    return (
        <Table className="align-middle">
          <thead>
            <tr>
              <th>Description</th>
              <th>Period</th>
              <th>Price</th>
              <th>Balance</th>
              <th>Billing Date</th>
              <th>Deadline</th>
              {/* <th className="text-right">Paid Amount</th> */}
            </tr>
          </thead>
          <tbody>
            {recurringCosts.map((project, projectIndex) => (
              <React.Fragment key={projectIndex}>
                  <tr key={projectIndex}>
                    <td>{project.project_recurring_cost_description}</td>
                    <td>{project.project_recurring_cost_period}</td>
                    <td>{project.amount}</td>
                    <td>{project.balance}</td>
                    <td>{project.billing_date}</td>
                    <td>{project.due_date}</td>
                  </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
    );
  };

  return (
    <div>
        <div className="print-page page">
            <Navbar
                onCollapse={(inactive) => {
                    setInactive(inactive);
                }}
                active={"SALES"}
            />
        </div>
        <div
            className={`print-top container ${
                inactive ? "inactive" : "active"
            }`}
        >
            <div className="print-container px-5 py-2" id="printablediv">
                <div className="d-flex justify-content-center py-1">
                    <img src={cleanLogo} className="print-logo" />
                </div>
                <div className="d-flex justify-content-center py-1 mt-1">
                    <h5 className="print-shop-header">
                        TRIPLE K EXPRESSFOODS / 3K EXPRESSFOODS / CHK
                        BUSINESS VENTURES CORP
                    </h5>
                </div>

                {/* content */}
                <div className="print-body mt-5">
                    <Row>
                        <Col>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Project:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.project_name
                                        ? distributorDetails.project_name
                                        : ''}
                                </Col>
                            </div>
                            
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Contact Person:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.project_contact_person}
                                </Col>
                            </div>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Customer Name:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.customer_name}
                                </Col>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Address:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.project_address}
                                </Col>
                            </div>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Contact No.:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.project_contact_number}
                                </Col>
                            </div>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Billing Date:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {moment(distributorDetails.billing_date).format("MMMM DD, YYYY")}
                                </Col>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex mt-5 mb-2 justify-content-evenly">
                        <div className="print-table mt-3 mx-2">
                          <CustomerProjectsTable/>
                        </div>
                    </div>
                   
                    <Row className="print-grand-total my-3 d-flex justify-content-end me-5">
                        <Col xs={3} className="print-table-footer-label text-end">
                            GRAND TOTAL
                        </Col>
                        <Col
                            xs={3}
                            className="print-table-footer-data text-end"
                        >
                            PHP{" "}
                            {distributorDetails.grand_total
                                ? numberFormat(distributorDetails.grand_total)
                                : "0.00"}
                        </Col>
                    </Row>
                    <div className="print-signatures">
                        <span className="text-center text-uppercase print-label">
                            {getName()}
                        </span>
                        <span className="text-center text-uppercase print-label fw-bold">
                            {distributorDetails.prepared_by}
                        </span>
                    </div>
                    <div className="print-signatories pb-4 mb-4">
                        <span>Received by</span>
                        <span>Prepared by</span>
                    </div>
                </div>
            </div>

            {/* footer */}
            <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
                <button
                    className="button-secondary me-3"
                    onClick={() => navigate("/subscription_billings")}
                >
                    Close
                </button>
                <button
                    className="button-tertiary me-3"
                    onClick={() =>
                        navigate(
                           "/subscription_billings/billing_statement", {state: {formPurpose: "edit", formOrigin: "billing", billing_id: id, distributor_id: ''}}
                        )
                    }
                >
                    Edit
                </button>
                <button
                    className="button-primary"
                    onClick={handlePrint}
                >
                    Print
                </button>
            </div>
        </div>
       
    </div>
);
}