import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import {
    getTodayDateISO,
    refreshPage,
    toastStyle,
} from "../../Helpers/Utils/Common";
import PaymentTable from "../Sales/PaymentTable";

export default function ProjectChangeRequestItemModal({
    id,
    changeRequestItem,
    show,
    onHide,
}) {
    let navigate = useNavigate();
    return (
        <div>
            <Modal show={show} onHide={onHide} size="lg" centered>
                <Modal.Header closeButton>
                    <span className="page-title"> Change Request Items </span>
                </Modal.Header>
                <Modal.Body className="return-body">
                    <PaymentTable
                    tableHeaders={[
                        "REQUEST NAME",
                        "DESCRIPTION",
                        "COST"
                    ]}
                    headerSelector={[
                        "name",
                        "description",
                        "amount",
                    ]}
                    tableData={changeRequestItem}
                    />
                </Modal.Body>
                <Modal.Footer className="return-footer">
                    <button
                    className="button-tertiary me-3"
                    onClick={() => navigate("/projectchangerequest/edit/" + id)}
                    >
                    Edit
                    </button>
                    <button
                    type="button"
                    className="button-secondary"
                    onClick={onHide}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

