import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";  
// api
import { getAllProjects } from "../../Helpers/apiCalls/Purchases/purchaseOrderApi";
// assets & styles
import {
  getTodayDateISO,
  numberFormat,
  numberFormatNoComma,
  toastStyle,
} from "../../Helpers/Utils/Common";
import trash from "./../../Assets/Images/trash.png";
import Navbar from "../../Components/Navbar/Navbar";
import { Fragment } from "react";
import { getAllBanks } from "../../Helpers/apiCalls/banksAPi";
import InputError from "../../Components/InputError/InputError";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { validateChangeRequest } from "../../Helpers/Validation/ChangeRequest";
import { createChangeRequest, updateChangeRequest, getProjectChangeRequestItem } from "../../Helpers/apiCalls/ProjectChangeRequest/ProjectChangeRequestApi";

function FormChangeRequestModal({ add, edit, changerequest, view }) {
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [selectedWHT, setSelectedWHT] = useState(""); // For dropdown selection
  

  const [availableParticulars, setAvailableParticulars] = useState([]);
  const [addDetails, setAddDetails] = useState({
    project_id: location.state ? location.state.row.project_id : "",
    request_date: add ? getTodayDateISO() : location.state ? location.state.row.request_date : "",
    project_name: location.state ? location.state.row.project_name : "",
    name: location.state ? location.state.row.project_name : "",
    address: location.state ? location.state.row.address : "",
    company: location.state ? location.state.row.company : "",
    request_no: location.state ? location.state.row.request_no : "",
  });

  // DATA HANDLERS
  const [banks, setBanks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [particulars, setParticulars] = useState([
    {
      item_id: "",
      name: "",
      amount: "", // Initialize amount as blank
      request_no: "",
      remarks: "",
      billed_amount: 0,
      balance: 0,
      discount: 0,
    },
  ]);
  const [fileList, setFileList] = useState([]);

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    project_id: false,
    project_date: false,
    invoice_date: false,
    address: false,
    items: false,
    request_no: false,
  });

  function handleAddDetailsChange(e) {
    const { name, value } = e.target;
    let net, withhold, twelve, grandtotal;
  
    if (name === "discount") {
      const discount = parseFloat(value) || 0;
      const subtotal = parseFloat(addDetails.subtotal) || 0;
  
      if (discount > subtotal) {
        toast.error("Discount cannot be greater than subtotal", { style: toastStyle() });
        return;
      }
  
      if (addDetails.vat_type === "vat-in") {
        net = subtotal / 1.12;
        twelve = subtotal - net;
        withhold = net * (parseFloat(selectedWHT) / 100 || 0);
        grandtotal = subtotal - discount - withhold;
      } else {
        grandtotal = subtotal - discount;
      }
  
      setAddDetails((prevState) => ({
        ...prevState,
        discount: discount,
        grand_total: grandtotal
      }));
    } else if (e.target.value === "vat-in") {
      // Calculate VAT-in values
      net = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) / 1.12;
      twelve = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) - parseFloat(net ? net : 0);
      grandtotal = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0);
  
      setAddDetails((prevState) => ({
        ...prevState,
        [name]: value,
        vat_net: net,
        vat_twelve: twelve,
        wht: 0, 
        grand_total: grandtotal - (prevState.discount || 0),
      }));
    } else if (e.target.value === "vat-ex") {
      grandtotal = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) - (parseFloat(addDetails.discount) || 0);
      setAddDetails((prevState) => ({
        ...prevState,
        [name]: value,
        vat_net: 0,
        vat_twelve: 0,
        wht: 0,
        grand_total: grandtotal,
      }));
    } else {
        // Handle other form changes
        const newList = { ...addDetails };
        newList[name] = value;
  
      if (name === "service_fee" || name === "delivery_fee") {
        setIsChanged(!isChanged);
      }

      setAddDetails(newList);
    }
  }

  function handleParticularChange(e, index) {
    setIsChanged(true);
    const { name, value } = e.target;
  
    setParticulars((prevParticulars) => {
      const updatedParticulars = [...prevParticulars];
  
      if (name === "item_name") {
        const selectedParticular = availableParticulars.find(
          (option) => option.item_name === value
        );
  
        updatedParticulars[index] = {
          ...updatedParticulars[index],
          [name]: value,
          item_id: selectedParticular ? selectedParticular.item_id : null,
        };
      } else if (name === "amount") {
        updatedParticulars[index] = {
          ...updatedParticulars[index],
          [name]: parseFloat(value), 
        };

        // Calculate new subtotal from all particulars
        const newSubtotal = updatedParticulars.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);
        
        // Update addDetails with new subtotal and grand_total
        setAddDetails(prevDetails => ({
          ...prevDetails,
          subtotal: newSubtotal,
          grand_total: newSubtotal - (prevDetails.discount || 0)
        }));
      } else {
        updatedParticulars[index] = {
          ...updatedParticulars[index],
          [name]: value,
        };
      }
  
      return updatedParticulars;
    });
  
    setTimeout(() => setIsChanged(false), 1);
  }

  async function handleSubmitChangeRequest() {
    if (
      !isClicked &&
      validateChangeRequest(addDetails, particulars, setIsError)
    ) {
      setIsClicked(true);
      const response = await createChangeRequest(
        {...addDetails, wht_percent: selectedWHT},
        particulars,
        fileList
      );
      if (response.data) {
        if (response.data.status === "error") {
          toast.error(response.data.response, {
            style: toastStyle(),
          });
          // setTimeout(() => refreshPage(), 1000);
        } else if (response.data.status === "success") {
          toast.success("Successfully created change request", {
            style: toastStyle(),
          });
          setTimeout(
            () =>
              navigate(
                "/projectchangerequest"
              ),
            1000
          );
        }
      } else {
        var errMsg = response?.response?.data?.messages?.error;
        toast.error(errMsg, { style: toastStyle() });
        setIsClicked(false);
      }
      setIsClicked(false);
    } else {
      toast.error("Please fill in all fields", { style: toastStyle() });
    }
  }

  async function handleEditChangeRequest() {
    if (
      !isClicked &&
      validateChangeRequest(addDetails, particulars, setIsError)
    ) {
      setIsClicked(true);
      const response = await updateChangeRequest( 
        addDetails.change_request_id,
        {...addDetails, wht_percent: selectedWHT},
        particulars,
        fileList
      );
  
      if (response.data) {
        if (response.data.status === "error") {
          toast.error(response.data.response, {
            style: toastStyle(),
          });
        } else if (response.data.status === "success") {
          toast.success("Successfully updated change request", {
            style: toastStyle(),
          });
          setTimeout(() => navigate("/projectchangerequest"), 1000);
        }
      } else {
        toast.error(response?.error?.message || "Error updating change request", { 
          style: toastStyle() 
        });
      }
      setIsClicked(false);
    } else {
      toast.error("Please fill in all required fields", { style: toastStyle() });
    }
  }

  const handleSubmit = () => {
    if (add) handleSubmitChangeRequest();
    else if (edit) handleEditChangeRequest();
  };

  const handleWHTChange = (value) => {
    setSelectedWHT(value);
  
    if (value) {
      const percentage = parseFloat(value) / 100;
      const net = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) / 1.12; // VAT net calculation
      const wht = net * percentage; // Withholding tax calculation for the selected percentage
      const vatTwelve = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) - net; // VAT amount
      const grandTotal = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) - wht;
  
      // Update the details state with recalculated values
      setAddDetails((prevDetails) => ({
        ...prevDetails,
        wht: wht.toFixed(2),
        vat_net: net,
        vat_twelve: vatTwelve,
        grand_total: grandTotal.toFixed(2),
        is_wht: 1,
      }));
    } else {
      // If WHT is disabled or no percentage is selected, reset values
      setAddDetails((prevDetails) => ({
        ...prevDetails,
        wht: 0,
        vatTwelve: 0,
        vatNet: 0,
        grand_total: prevDetails.subtotal - (prevDetails.discount || 0), // Keep subtotal if WHT is disabled
      }));
    }
  };
  
  async function fetchAllProjects() {
    setProjects([]);
    const response = await getAllProjects();
    if (response && response.data && response.data.data) {
      let result = response.data.data.map((a) => {
        var info = a;
      // a.name =  "project_id";
        a.for = "project_id";
        a.select_value = "project_id_value";
        a.label = a.name;
        a.value = a.id;
        return info;
      });
      setProjects(result);
    }
  }

  async function fetchBanks() {
    const response = await getAllBanks();
    if (response.error) {
    } else {
      setBanks(response.data.data);
    }
  }
  
  /** FOR EDIT - Fetch Sales Invoice Details */
  async function fetchProjectInvoice() {
    if (edit || view || changerequest) {
      const response = await getProjectChangeRequestItem(id);
      if (response.data && response.data.data && response.data.data.length > 0) {
        const changeRequestData = response.data.data[0]; // Access the first item in the data array
        const changeRequest = changeRequestData.change_request[0]; // Access the first change request

        setAddDetails({
          project_id: changeRequestData.id,
          request_date: changeRequest.request_date,
          name: changeRequestData.project_name,
          project_name: changeRequestData.project_name, // Make sure project_name is set
          address: changeRequestData.address,
          company: changeRequestData.company,
          project_id_value: {
            label: changeRequestData.project_name,
            value: changeRequestData.project_id,
          },
          change_request_id: changeRequest.id,
          vat_type: changeRequest.vat_type || "vat-ex",
          subtotal: changeRequest.subtotal,
          discount: numberFormatNoComma(changeRequest.discount),
          grand_total: changeRequest.grand_total, // Access grand_total from change_request
          wht_percent: changeRequest.wht_percent,
          wht: changeRequest.wht,
          remarks: changeRequest.remarks,
          request_no: changeRequest.request_no, // Ensure request_no is mapped to request_no
          vat_twelve: changeRequest.vat_twelve,
          vat_net: changeRequest.vat_net,
        });

        // Set particulars if they exist in the response
        if (changeRequestData.change_request_items) {
          setParticulars(changeRequestData.change_request_items.map(item => ({
            name: item.name,
            description: item.description,
            amount: numberFormatNoComma(item.amount),
          })));
        }

        // Set WHT percentage if it exists
        if (changeRequest.wht_percent) {
          setSelectedWHT(changeRequest.wht_percent.toString());
        }
      } else {
        console.error("No data found for the given ID");
      }
    }
  }

  function handleAddNewRow() {
    const newItem = {
      item_id: { value: null },
      name: "",
      amount: "0",
      description: "",
    };
  
    setParticulars((oldItems) => {
      // Ensure oldItems is an array before spreading it
      return [...(Array.isArray(oldItems) ? oldItems : []), newItem];
    });
  }
  
  function handleDelRow(id) {
    setIsChanged(true);

    const newList = [...particulars];
    newList.splice(id, 1);
    setParticulars(newList);

    // Recalculate subtotal and grand total after deleting a row
    const newSubtotal = newList.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);
    setAddDetails(prevDetails => ({
      ...prevDetails,
      subtotal: newSubtotal,
      grand_total: newSubtotal - (prevDetails.discount || 0)
    }));

    setTimeout(() => setIsChanged(false), 1);
  }

  // DATA FETCHING
  useEffect(() => {
    if (changerequest || edit || view) {
      fetchProjectInvoice();
    }
    fetchAllProjects();
    fetchBanks();
  }, []);

  useEffect(() => {
    // If in view mode and we have state data, set it
    if (view && location.state?.row) {
      const viewData = location.state.row;
      setAddDetails({
        ...viewData,
        project_id_value: {
          label: viewData.project_name,
          value: viewData.project_id,
        },
        vat_type: viewData.vat_type || "vat-ex",
      });
      
      if (viewData.particulars) {
        setParticulars(viewData.particulars);
      }
      
      if (viewData.wht_percent) {
        setSelectedWHT(viewData.wht_percent);
      }
    }
  }, [view, location.state]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center pb-4">
          <h1 className="page-title mb-0">
            {add ? "ADD CHANGE REQUEST" : 
             edit ? "EDIT CHANGE REQUEST" : 
             view ? "VIEW CHANGE REQUEST" :
             "ADD CHANGE REQUEST"}
          </h1>
        </div>

        {/* content */}
        <div className="edit-form">
          <Fragment>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Project Name
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Request Date
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Project..."
                  isDisabled={view || changerequest || edit} // Ensure the Select component is disabled
                  value={
                    addDetails.project_id
                      ? projects.find((p) => p.id === addDetails.project_id) // Match selected project by ID
                      : null
                  }
                  options={projects}
                  getOptionLabel={(e) => e.name} // Display project name in the dropdown
                  getOptionValue={(e) => e.id} // Use project ID as the option value
                  onChange={(e) => {
                    setAddDetails((prev) => ({
                      ...prev,
                      project_id: e.id,
                      project_name: e.name, 
                      project_date: e.project_date,
                      company: e.company,
                      address: e.address,
                    }))
                  }}
                />
                <InputError
                  isValid={isError.project_id}
                  message={"Project name is required"}
                />
              </Col>
              <Col xs={3}>
                <DatePicker
                  className="nc-modal-custom-text-new-datepicker"
                  name="request_date"
                  value={addDetails.request_date && addDetails.request_date !== "0000-00-00" ? dayjs(addDetails.request_date) : null}
                  onChange={(date, dateString) => {
                    handleAddDetailsChange({
                      target: { name: "request_date", value: dateString },
                    });
                  }}
                  disabled={view || changerequest}
                />
                <InputError
                  isValid={isError.request_date}
                  message={"Request date is required"}
                />
              </Col>

            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Address
                  {/* <span className="color-red"> *</span> */}
                </span>
              </Col>
              <Col>
                <span className="edit-label">
                  Company
                  <span className="color-red"> *</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  disabled={view || changerequest || edit}
                  type="text"
                  name="address"
                  defaultValue={
                    location.state
                      ? location.state.row.address
                      : addDetails.address
                  }
                  className="nc-modal-custom-text"
                />
                {/* <InputError
                  isValid={isError.address}
                  message={"Address is required"}
                /> */}
              </Col>
              <Col>
                <Form.Control
                  disabled={view || changerequest|| edit}
                  type="text"
                  name="company"
                  defaultValue={
                    location.state
                      ? location.state.row.company
                      : addDetails.company
                  }
                  className="nc-modal-custom-text"
                />
                <InputError
                  isValid={isError.company}
                  message={"Company is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">Remarks</span>
              </Col>
              <Col>
                <span className="edit-label">
                  Change Request No. 
                  <span className="color-red"> *</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  disabled={view || changerequest}
                  type="text"
                  name="remarks"
                  defaultValue={
                    addDetails.remarks === "undefined" ? "" : addDetails.remarks
                  }
                  className="nc-modal-custom-text"
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col>
              <Col>
                <Form.Control
                  disabled={view || changerequest}
                  type="text"
                  name="request_no"
                  className="nc-modal-custom-text"
                  value={addDetails.request_no}
                  onChange={(e) => handleAddDetailsChange(e)}
                />
                <InputError
                  isValid={isError.request_no}
                  message={"Change Request No. is required"}
                />
              </Col>
            </Row>
          </Fragment>
          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">
              Particulars<span className="color-red"> *</span>
            </span>
            <div className="edit-purchased-items">
              {particulars?.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                      <th className="color-gray">Change Request Name</th>
                        <th className="color-gray">Description</th>
                        <th className="color-gray">Cost</th>
                        <th className="color-gray">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {particulars?.map((item, index) => {
                        return (
                          <tr key={index}>
                          <td>
                              <Form.Control
                                disabled={view || changerequest}
                                type="text"
                                name="name" 
                                value={item.name} 
                                onChange={(e) => handleParticularChange(e, index)}
                              />
                            </td>
                            <td>
                              <Form.Control
                                disabled={view || changerequest}
                                type="text"
                                name="description"
                                value={item.description}
                                onChange={(e) => handleParticularChange(e, index)}
                              />
                            </td>
                            <td>
                              <Form.Control
                                disabled={view || changerequest}
                                type="number"
                                name="amount" 
                                value={item.amount} 
                                min="0"  
                                onChange={(e) => handleParticularChange(e, index)}
                              />
                            </td>
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => !view && handleDelRow(index)} 
                                className={`cursor-pointer ${view ? "disabled" : ""}`} 
                                alt="delete"
                                style={{ cursor: view ? "not-allowed" : "pointer" }} 
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <InputError
                    isValid={isError.item_info}
                    message={"Please make sure all fields are filled"}
                  />
                </>
              ) : (
                <div className="entries-not-found">
                  There is no particulars recorded yet.
                </div>
              )}
              <InputError
                isValid={isError.items}
                message={"Please add at least 1 particular"}
              />
            </div>
          </Row>


          {/* ADD ITEM BUTTON */}
          <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRow()}>
              Add Item
            </Button>
          </Row>

          {/* SUBTOTAL, FREIGHT COST, DISCOUNT, & GRAND TOTAL */}
          <Fragment>
            <Row className="align-right pt-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">Subtotal</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle">PHP</span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle">
                  {numberFormat(
                    addDetails?.subtotal === "NaN" ? 0 : addDetails?.subtotal
                  )}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-3">
              <Col className="text-end">
                <Form.Check
                  inline
                  label="VAT Ex"
                  name="vat_type"
                  type="radio"
                  value="vat-ex"
                  disabled={view || changerequest}
                  checked={addDetails.vat_type === "vat-ex" || !addDetails.vat_type}
                  onClick={(e) => {
                    handleAddDetailsChange(e);
                  }}
                />
                <Form.Check
                  inline
                  label="VAT In"
                  name="vat_type"
                  type="radio"
                  value="vat-in"
                  disabled={view || changerequest}
                  checked={addDetails.vat_type === "vat-in"}
                  onClick={(e) => {
                    handleAddDetailsChange(e);
                  }}
                />
              </Col>
            </Row>
            {addDetails.vat_type === "vat-in" && (
              <>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">12% VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.vat_twelve
                        ? numberFormat(
                            parseFloat(addDetails.vat_twelve).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">Net of VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.vat_net
                        ? numberFormat(
                            parseFloat(addDetails.vat_net).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                 <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">WHT</span>
                  </Col>
                  <Col xs={4} className="text-end d-flex justify-content-end align-items-center">
                    <Form.Select
                      value={selectedWHT}
                      onChange={(e) => handleWHTChange(e.target.value)}
                      className="edit-label color-gray w-50"
                      style={{ width: "100px" }}
                      disabled={view}
                    >
                      <option value="">Select</option>
                      <option value="1">1%</option>
                      <option value="2">2%</option>
                      <option value="3">3%</option>
                      <option value="5">5%</option>
                      <option value="12">12%</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">PHP</span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.wht
                        ? numberFormat(parseFloat(addDetails.wht).toFixed(2))
                        : "0.00"}
                    </span>
                  </Col>
                  
                </Row>
              </>
            )}
              <Row className="align-right py-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">Discount</span>
                </Col>
                <Col xs={3} className="text-end">
                  <Form.Control 
                    disabled={view || changerequest}
                    type="number"
                    name="discount"
                    value={addDetails.discount || ""}
                    min="0"  
                    className="edit-label mb-2"
                    onChange={(e) => handleAddDetailsChange(e)}
                  />
                </Col>
              </Row>
            <Row className="align-right py-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray grand-total-text">
                  Grand Total
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  PHP
                </span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle grand-total-text text-end">
                {numberFormat(
                    isNaN(addDetails.grand_total) ? 0.00 : parseFloat(addDetails.grand_total).toFixed(2)
                  )}
                </span>
              </Col>
            </Row>
          </Fragment>
          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              {view || changerequest ? "Close" : "Cancel"}
            </button>
            {(add || edit || changerequest) && (
            <button
              type="button"
              className="button-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

FormChangeRequestModal.defaultProps = {
  add: false,
  edit: false,
  view: false,
  changerequest: false,
};

export default FormChangeRequestModal;
