import { handleValidationChange } from "../CommonValidation";

export const validateDistributor = (data, setIsError) => {

  var isValid = true;

  if (data.name === "") {
    handleValidationChange("name", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("name", false, setIsError);
  }

  if (data.address === "") {
    handleValidationChange("address", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("address", false, setIsError);
  }

  if (data.contact_person === "") {
    handleValidationChange("contact_person", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("contact_person", false, setIsError);
  }

  if (data.contact_number === "") {
    handleValidationChange("contact_number", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("contact_number", false, setIsError);
  }

  return isValid;

}