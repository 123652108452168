import React, { useState, useEffect } from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import toast from "react-hot-toast"
import Select from "react-select"
// api
import { getAllProjects } from "../../Helpers/apiCalls/Purchases/purchaseOrderApi"
// assets & styles
import {
  getTodayDateISO,
  getTodayDateISOFormat,
  numberFormat,
  numberFormatNoComma,
  sanitizeNumbers,
  toastStyle,
} from "../../Helpers/Utils/Common"
import trash from "./../../Assets/Images/trash.png"
import Navbar from "../../Components/Navbar/Navbar"
import { Fragment } from "react"
import { updateSalesInvoice } from "../../Helpers/apiCalls/franchiseeApi"
import { getAllBanks } from "../../Helpers/apiCalls/banksAPi"
import InputError from "../../Components/InputError/InputError"
import ReactLoading from "react-loading"
import {
  createProjectInvoice,
  updateProjectInvoice,
  getProjectInvoice,
  getProjectInvoiceParticular,
  recordStatusProjectInvoice,
} from "./../../Helpers/apiCalls/ProjectInvoice/ProjectInvoiceApi"
import { validateProjectInvoice } from "./../../Helpers/Validation/Project/ProjectValidation"
import moment from "moment"
import dayjs from "dayjs"
import { DatePicker } from "antd"

function FormProjectInvoice({ add, edit, process, createinvoice }) {
  let navigate = useNavigate()
  const currentYear = moment().format("YYYY")
  const [inactive, setInactive] = useState(true)
  const [isChanged, setIsChanged] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const { id } = useParams()
  const location = useLocation()
  const [selectedWHT, setSelectedWHT] = useState("") // For dropdown selection
  const [isSubmittable, setIsSubmittable] = useState(false)

  const [availableParticulars, setAvailableParticulars] = useState([])
  const [addDetails, setAddDetails] = useState({
    project_id: location.state ? location.state.row.id : "",
    invoice_date: null,
    project_name: location.state ? location.state.row.name : "",
    project_date: location.state
      ? getTodayDateISOFormat(location.state.row.project_date)
      : "",
    due_date: "",
    address: location.state ? location.state.row.address : "",
    company: location.state ? location.state.row.company : "",
    project_id_value: {
      value: location.state ? location.state.row.id : "",
      label: location.state ? location.state.row.name : "",
    },
    vat_type: "vat-ex",
  })

  // DATA HANDLERS
  const [banks, setBanks] = useState([])
  const [projects, setProjects] = useState([])
  const [particulars, setParticulars] = useState([
    {
      item_id: "",
      item_name: "",
      qty: 0,
      unit: "",
      price: 0,
      item_info: "",
      invoice_no: "",
      remarks: "",
      billed_amount: 0,
      balance: 0,
      discount: 0,
    },
  ])
  const [fileList, setFileList] = useState([])

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    project_id: false,
    project_date: false,
    invoice_date: false,
    address: false,
    items: false,
    item_info: false,
    invoice_no: false,
  })

  const handleVATChange = (value) => {
    const subtotal = parseFloat(addDetails.subtotal || 0)
    const discount = parseFloat(addDetails.discount || 0)

    if (value === "vat-in") {
      // Calculate VAT-in values
      const net = subtotal / 1.12
      const vatTwelve = subtotal - net
      let withholdingTax = 0

      // If WHT percentage selected, calculate WHT
      if (selectedWHT) {
        const percentage = parseFloat(selectedWHT) / 100
        withholdingTax = net * percentage
      }

      const grandTotal = subtotal - withholdingTax - discount

      setAddDetails((prevState) => ({
        ...prevState,
        vat_type: value,
        vat_net: net.toFixed(2),
        vat_twelve: vatTwelve.toFixed(2),
        withholding_tax: withholdingTax.toFixed(2),
        grand_total: grandTotal.toFixed(2),
        is_wht: selectedWHT ? 1 : 0,
      }))
    } else if (value === "vat-ex") {
      // Reset VAT-related values for VAT-ex
      const grandTotal = subtotal - discount

      setAddDetails((prevState) => ({
        ...prevState,
        vat_type: value,
        vat_net: 0,
        vat_twelve: 0,
        withholding_tax: 0,
        grand_total: grandTotal.toFixed(2),
        is_wht: 0,
      }))
    }
  }
  function handleDateChange(date, dateString) {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null
    setIsChanged(true)
    setAddDetails((prevState) => ({
      ...prevState,
      invoice_date: formattedDate,
    }))
    setTimeout(() => setIsChanged(false), 10)
  }

  function handleAddDetailsChange(e, search) {
    const { name, value } = e.target

    if (search) {
      // Handle search changes
      setAddDetails((prevState) => ({
        ...prevState,
        [e.for]: e.value,
        [e.select_value]: {
          for: e.for,
          label: e.label,
          value: e.value,
        },
        project_date: e.project_date,
        address: e.address,
        company: e.company,
        invoice_no: e.invoice_no,
      }))
      setIsChanged(true)
      var info = {
        qty: 1,
        price: parseFloat(e.balance),
        total: e.balance,
        subtotal: e.balance,
      }
      setParticulars([info])
      setTimeout(() => setIsChanged(false), 10)
    } else if (name === "discount") {
      // Update discount
      setAddDetails((prevState) => ({
        ...prevState,
        discount: value,
      }))
    } else if (name === "vat_type") {
      handleVATChange(value)
    } else {
      // Handle other form changes
      const newList = { ...addDetails }
      newList[name] = value

      if (name === "service_fee" || name === "delivery_fee") {
        setIsChanged(!isChanged)
      }

      setAddDetails(newList)
    }

    // If WHT checkbox is enabled and a percentage is selected, recalculate withholding tax and grand total
    if (selectedWHT && e.target.value === "vat-in") {
      handleWHTChange(selectedWHT) // Recalculate when the WHT is enabled
    } else if (!selectedWHT) {
      // If WHT is disabled, reset withholding tax and grand_total based on subtotal
      setAddDetails((prevState) => ({
        ...prevState,
        withholding_tax: 0,
        net: 0,
        twelve: 0,
        // grand_total: prevState.subtotal, // Set grand total to subtotal if WHT is disabled
      }))
    }
  }

  /**
   *  ORDER ITEMS
   */
  const [orderItems, setOrderItems] = useState([])

  async function handleSubmitInvoice() {
    if (
      !isClicked &&
      validateProjectInvoice(addDetails, particulars, setIsError)
    ) {
      setIsClicked(true)
      const response = await createProjectInvoice(
        { ...addDetails, wht_percent: selectedWHT },
        particulars,
        fileList
      )
      if (response.data) {
        if (response.data.status === "error") {
          toast.error(response.data.response, {
            style: toastStyle(),
          })
          // setTimeout(() => refreshPage(), 1000);
        } else if (response.data.status === "success") {
          toast.success("Successfully created project invoice", {
            style: toastStyle(),
          })
          setTimeout(
            () =>
              navigate(
                "/projectinvoice/print/" + response.data.project_invoice_id,
                "_blank"
              ),
            1000
          )
        }
      } else {
        var errMsg = response.error.response
        toast.error(errMsg, { style: toastStyle() })
        setIsClicked(false)
      }
      setIsClicked(false)
    } else {
      toast.error("Please fill in all fields", { style: toastStyle() })
    }
  }

  async function handleUpdatePI() {
    setIsClicked(true)
    const response = await updateProjectInvoice(
      { ...addDetails, wht_percent: selectedWHT },
      particulars
    )
    if (response.data?.status === "success") {
      toast.success("Successfully updated sales invoice", {
        style: toastStyle(),
      })
      setTimeout(() => navigate("/projectinvoice/print/" + id), 1000)
    } else if (response.error) {
      toast.error("Error updating sales invoice", {
        style: toastStyle(),
      })
      setIsClicked(false)
    }
    setIsClicked(false)
  }

  async function handleProcess() {
    const response = await updateSalesInvoice(
      id,
      orderItems,
      particulars,
      addDetails
    )
    if (response.data) {
      toast.success("Successfully processed quotation", {
        style: toastStyle(),
      })
      setTimeout(() => navigate("/projectinvoice"), 1000)
    } else if (response.error) {
      toast.error("Error updating sales invoice", {
        style: toastStyle(),
      })
    }
    setIsClicked(false)
  }

  async function handleCreateInvoiceBtn() {
    setIsClicked(true)
    const response = await updateProjectInvoice(addDetails, particulars)

    if (response.data) {
      const record = await recordStatusProjectInvoice(id, "invoiced")
      if (record.data) {
        toast.success("Successfully created invoice", {
          style: toastStyle(),
        })
        setTimeout(() => navigate("/projectinvoice/print/" + id), 1000)
      }
    } else if (response.error) {
      toast.error("Error updating project invoice", {
        style: toastStyle(),
      })
      setIsClicked(false)
    }
    setIsClicked(false)
  }

  const handleSubmit = () => {
    if (add) handleSubmitInvoice()
    else if (edit) handleUpdatePI()
    else if (process) handleProcess()
    else if (createinvoice) handleCreateInvoiceBtn()
  }

  const handleWHTChange = (value) => {
    setSelectedWHT(value)

    if (addDetails.vat_type === "vat-in") {
      const subtotal = parseFloat(addDetails.subtotal || 0)
      const net = subtotal / 1.12
      const vatTwelve = subtotal - net  
      const discount = parseFloat(addDetails.discount || 0)
      let withholdingTax = 0
      let grandTotal = subtotal - discount

      if (value) {
        const percentage = parseFloat(value) / 100
        withholdingTax = net * percentage
        grandTotal = subtotal - withholdingTax - discount
      }

      setAddDetails((prevDetails) => ({
        ...prevDetails,
        withholding_tax: withholdingTax.toFixed(2),
        vat_net: net.toFixed(2),
        vat_twelve: vatTwelve.toFixed(2),
        grand_total: grandTotal.toFixed(2),
        is_wht: value ? 1 : 0,
      }))
    }
  }

  /** FOR EDIT - Fetch Sales Invoice Details */
  async function fetchProjectInvoice() {
    const response = await getProjectInvoice(id)
    if (response.data) {
      var SI = response.data.data[0]
      SI.project_id_value = {
        label: SI.project_name,
        value: SI.project_id,
      }

      setSelectedWHT(SI.wht_percent)
      if (process || createinvoice) {
        SI.payment_type = "cash"
        SI.paid_amount = "0"
        SI.payment_date = getTodayDateISO()
        SI.deposit_date = getTodayDateISO()
        SI.due_date = getTodayDateISOFormat(SI.due_date)
      }
      setAddDetails(SI)

      var items = SI.project_invoice_items.map((item) => ({
        ...item,
        balance: item.item_balance
          ? parseFloat(item.item_balance).toFixed(2)
          : 0.0,
      }))
      setParticulars(items)
    }
  }

  /** Fetch Particulars available */
  async function fetchProjectInvoiceParticular(id) {
    try {
      const response = await getProjectInvoiceParticular(id)

      if (response?.data?.data) {
        const particularsData = response.data.data
          .map((item) => ({
            item_id: item.id,
            item_name: item.project_change_request_id
              ? item.name
              : item.description,
            // qty: item.period,
            // unit: item.type,
            price: item.amount,
            balance: item.balance,
          }))
          .filter((item) => parseFloat(item.balance) > 0)

        setAvailableParticulars(particularsData)
      } else {
        setAvailableParticulars([])
      }
    } catch (error) {
      console.error("Error fetching particulars:", error)
      setAvailableParticulars([])
    }
  }

  function handleCreatePayment(e) {
    const paymentInfo = { ...addDetails }
    const { name, value, id } = e.target
    if (name === "service_fee" || name === "delivery_fee") {
      const paymentEdit = { ...addDetails }
      paymentEdit[name] = value
      setAddDetails(paymentEdit)
      setIsChanged(!isChanged)
    }

    if (name === "to_bank_id" || name === "from_bank_id") {
      paymentInfo[name] = value
    } else {
      paymentInfo[name] = value
    }

    setAddDetails(paymentInfo)
  }

  function handleAddNewRow() {
    const newItem = {
      item_id: { value: null },
      name: "",
      qty: "",
      unit_discount: "0",
      unit: "",
      price: "",
      type: "",
      se_id: "0",
      billed_amount: "0",
      balance: "0",
      discount: "0",
    }
    setParticulars((oldItems) => [...oldItems, newItem])
  }

  function handleDelRow(id) {
    setIsChanged(true)

    const newList = [...particulars]
    newList.splice(id, 1)
    setParticulars(newList)

    setTimeout(() => setIsChanged(false), 1)
  }

  function handleParticularChange(e, index) {
    setIsChanged(true)
    const { name, value } = e.target

    setParticulars((prevParticulars) => {
      const updatedParticulars = [...prevParticulars]

      if (name === "item_name") {
        // Find the selected particular from the available options
        const selectedParticular = availableParticulars.find(
          (option) => option.item_name === value
        )

        updatedParticulars[index] = {
          ...updatedParticulars[index],
          [name]: value,
          item_id: selectedParticular ? selectedParticular.item_id : null,
          qty: selectedParticular ? selectedParticular.qty : 0,
          price: selectedParticular ? numberFormatNoComma(selectedParticular.price) : 0,
          subtotal: selectedParticular
            ? selectedParticular.qty * selectedParticular.price
            : 0,
          total: selectedParticular
            ? selectedParticular.qty * selectedParticular.price
            : 0,
          balance: selectedParticular ? numberFormatNoComma(selectedParticular.balance) : 0,
          billed_amount: selectedParticular
            ? numberFormatNoComma(selectedParticular.balance)
            : 0,
        }
      } else {
        updatedParticulars[index] = {
          ...updatedParticulars[index],
          [name]: parseFloat(value),
        }

        // if (name === "qty" || name === "price") {
        //   const qty = name === "qty" ? parseFloat(value) : updatedParticulars[index].qty;
        //   const price = name === "price" ? parseFloat(value) : updatedParticulars[index].price;

        //   updatedParticulars[index] = {
        //     ...updatedParticulars[index],
        //     subtotal: qty * price,
        //     total: qty * price,
        //   };
        // }
      }

      return updatedParticulars
    })

    setTimeout(() => setIsChanged(false), 1) // Keep the behavior from the original function
  }

  useEffect(() => {
    var _subtotal = particulars
      .map((item) => parseFloat(item.billed_amount) || 0)
      .reduce((a, b) => a + b, 0)

    var discount = parseFloat(addDetails.discount || 0)
    var _grandTotal = _subtotal - discount

    // Only calculate VAT and WHT if vat_type is "vat-in"
    if (addDetails.vat_type === "vat-in") {
      const net = _subtotal / 1.12 // VAT net calculation
      const vatTwelve = _subtotal - net
      let withholdingTax = 0

      // Calculate WHT if percentage selected
      if (selectedWHT) {
        const percentage = parseFloat(selectedWHT) / 100
        withholdingTax = net * percentage
        _grandTotal = _subtotal - withholdingTax - discount
      } else {
        _grandTotal = _subtotal - discount
      }

      setAddDetails((prev) => ({
        ...prev,
        subtotal: _subtotal,
        withholding_tax: withholdingTax.toFixed(2),
        vat_net: net.toFixed(2),
        vat_twelve: vatTwelve.toFixed(2),
        grand_total: _grandTotal.toFixed(2),
        is_wht: selectedWHT ? 1 : 0,
      }))
    } else {
      // Reset VAT-related values for VAT-ex
      setAddDetails((prev) => ({
        ...prev,
        subtotal: _subtotal,
        withholding_tax: 0,
        vat_twelve: 0,
        vat_net: 0,
        grand_total: _grandTotal.toFixed(2),
        is_wht: 0,
      }))
    }
  }, [particulars, addDetails.discount, selectedWHT, addDetails.vat_type])

  // FETCH API
  async function fetchAllProjects() {
    setProjects([]);
    const response = await getAllProjects();
    if (response && response.data && response.data.data) {
        let result = response.data.data
            .map((a) => {
                var info = a;
                a.for = "project_id";
                a.select_value = "project_id_value";
                a.label = a.name;
                a.value = a.id;
                return info;
            })

        setProjects(result);
    }
}

  async function fetchBanks() {
    const response = await getAllBanks()
    if (response.error) {
    } else {
      setBanks(response.data.data)
    }
  }

  // FOR DYNAMIC CALCULATION
  // useEffect(() => {
  //   var tempServiceFee = addDetails.service_fee
  //     ? parseFloat(addDetails.service_fee)
  //     : 0;
  //   var tempDeliveryFee = addDetails.delivery_fee
  //     ? parseFloat(addDetails.delivery_fee)
  //     : 0;

  //   var _subtotal = particulars
  //     .map((item) => parseFloat(item.billed_amount))
  //     .reduce((a, b) => a + b, 0);

  //   // var _grandTotal = _subtotal + tempServiceFee + tempDeliveryFee - addDetails.discount;
  //   var _itemsTotal = _subtotal;
  //   setAddDetails((prev) => {
  //     return {
  //       ...prev,
  //       subtotal: _itemsTotal.toFixed(2),
  //       // grand_total: _grandTotal,
  //     };
  //   });
  // }, [isChanged]);

  useEffect(() => {
    const requiredParticularFields = ["item_id", "billed_amount"] // Fields needed in each item of particulars
    const requiredAddDetailsFields = ["invoice_date", "company", "project_id"] // Fields needed in addDetails

    // Validate if all particulars have the necessary fields filled
    const areParticularsValid = particulars.every((item) =>
      requiredParticularFields.every((field) => {
        const value = item[field]
        return (
          value &&
          (typeof value === "string" ? value.trim() !== "" : value !== "")
        )
      })
    )

    // Validate if addDetails has the necessary fields
    const isAddDetailsValid = requiredAddDetailsFields.every((field) => {
      const value = addDetails[field]
      return (
        value &&
        (typeof value === "string" ? value.trim() !== "" : value !== "")
      )
    })

    // Set isSubmittable based on whether all validations pass
    setIsSubmittable(areParticularsValid && isAddDetailsValid)
  }, [particulars, addDetails])

  const [desData, setDesData] = useState([])
  const [qtyData, setQtyData] = useState([])
  const [unitData, setUnitData] = useState([])
  const [priceData, setPriceData] = useState([])

  // DATA FETCHING
  useEffect(() => {
    if (edit || process || createinvoice) {
      fetchProjectInvoice()
    }

    if (location.state !== null) {
      fetchProjectInvoiceParticular(location.state.row.id)
    }

    fetchAllProjects()
    fetchBanks()
  }, [])

  useEffect(() => {
    particulars.map((data) => {
      setDesData(data.item_name)
      setQtyData(data.qty)
      setUnitData(data.unit)
      setPriceData(data.price)
    })
  }, [addDetails])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center pb-4">
          <h1 className="page-title mb-0">
            {add ? "ADD PROJECT INVOICE" : "EDIT PROJECT INVOICE"}
          </h1>
        </div>

        {/* content */}
        <div className="edit-form">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Project Name
                  <span className="color-red"> *</span>
                </span>
              </Col>
              {/* <Col xs={3}>
                <span className="edit-label">
                  Project Date
                  <span className="color-red"> *</span>
                </span>
              </Col> */}
              <Col xs={3}>
                <span className="edit-label">
                  Invoice Date
                  <span className="color-red"> *</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Project..."
                  value={
                    addDetails.project_id
                      ? projects.find((p) => p.id === addDetails.project_id) // Match selected project by ID
                      : null
                  }
                  options={projects}
                  getOptionLabel={(e) => e.name} // Display project name in the dropdown
                  getOptionValue={(e) => e.id} // Use project ID as the option value
                  onChange={(e) => {
                    fetchProjectInvoiceParticular(e.id) // Fetch invoice details for the selected project
                    setAddDetails((prev) => ({
                      ...prev,
                      project_id: e.id,
                      project_name: e.name, // Update project name
                      project_date: e.project_date,
                      company: e.company,
                      address: e.address,
                    }))
                  }}
                />
                <InputError
                  isValid={isError.project_id}
                  message={"Project name is required"}
                />
              </Col>
              {/* <Col xs={3}>
                <DatePicker
                  className="nc-modal-custom-text-new-datepicker"
                  name="project_date"
                  value={
                    location.state
                      ? dayjs(getTodayDateISOFormat(location.state.row.project_date)) && getTodayDateISOFormat(location.state.row.project_date) !== "0000-00-00" 
                        ? dayjs(getTodayDateISOFormat(location.state.row.project_date)) 
                        : null
                      : addDetails.project_date && addDetails.project_date !== "0000-00-00"
                      ? dayjs(addDetails.project_date)
                      : null
                  }
                  disabled
                  onChange={(date, dateString) => {
                    // If project_date can be updated in the future, use this handler to process the value
                    // Example: handleAddDetailsChange({ target: { name: "project_date", value: dateString } });
                  }}
                />
                <InputError
                  isValid={isError.project_date}
                  message={"Project date is required"}
                />
              </Col>  */}
              <Col xs={3}>
                <DatePicker
                  className="nc-modal-custom-text-new-datepicker"
                  name="invoice_date"
                  value={
                    addDetails.invoice_date
                      ? dayjs(addDetails.invoice_date)
                      : null
                  }
                  onChange={(date, dateString) => {
                    // handleAddDetailsChange({
                    // target: { name: "invoice_date", value: date },
                    // });
                    handleDateChange(date, dateString)
                  }}
                />
                <InputError
                  isValid={isError.invoice_date}
                  message={"Invoice date is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Address
                  {/* <span className="color-red"> *</span> */}
                </span>
              </Col>
              <Col>
                <span className="edit-label">
                  Company
                  <span className="color-red"> *</span>
                </span>
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Due Date
                  {/* <span className="color-red"> *</span> */}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  disabled
                  type="text"
                  name="address"
                  defaultValue={
                    location.state
                      ? location.state.row.address
                      : addDetails.address
                  }
                  className="nc-modal-custom-text"
                />
                {/* <InputError
                  isValid={isError.address}
                  message={"Address is required"}
                /> */}
              </Col>
              <Col>
                <Form.Control
                  disabled
                  type="text"
                  name="company"
                  defaultValue={
                    location.state
                      ? location.state.row.company
                      : addDetails.company
                  }
                  className="nc-modal-custom-text"
                />
                <InputError
                  isValid={isError.company}
                  message={"Company is required"}
                />
              </Col>
              <Col xs={3}>
                <DatePicker
                  className="nc-modal-custom-text-new-datepicker"
                  name="due_date"
                  value={
                    addDetails.due_date && addDetails.due_date !== "0000-00-00"
                      ? dayjs(addDetails.due_date)
                      : null
                  }
                  onChange={(date, dateString) => {
                    handleAddDetailsChange({
                      target: { name: "due_date", value: dateString },
                    })
                  }}
                />
                {/* Uncomment this if InputError is needed */}
                {/* 
                <InputError
                  isValid={isError.due_date}
                  message={"Due date is required"}
                /> 
                */}
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">Remarks</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="remarks"
                  defaultValue={
                    addDetails.remarks === "undefined" ? "" : addDetails.remarks
                  }
                  className="nc-modal-custom-text"
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col>
            </Row>
          </Fragment>

          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">
              Particulars<span className="color-red"> *</span>
            </span>
            <div className="edit-purchased-items">
              {particulars.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">Description</th>
                        {/* <th className="color-gray">Quantity</th>
                        <th className="color-gray">Unit</th> */}
                        <th className="color-gray">Total Amount</th>
                        <th className="color-gray">Balance</th>
                        <th className="color-gray">Billed Amount</th>
                        <th className="color-gray">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {particulars.map((item, index) => {
                        // Filter available options to exclude already selected items
                        const availableOptions = availableParticulars.filter(
                          (option) =>
                            !particulars.some(
                              (selected, selectedIndex) =>
                                selected.item_name === option.item_name &&
                                selectedIndex !== index // Allow current row's value
                            )
                        )

                        return (
                          <tr key={index}>
                            <td>
                              <Form.Control
                                as="select" // Make the item_name a dropdown
                                name="item_name"
                                value={item.item_name}
                                onChange={(e) =>
                                  handleParticularChange(e, index)
                                }
                              >
                                <option value="">
                                  {item.item_name
                                    ? item.item_name
                                    : "Select a particular"}
                                </option>
                                {availableOptions.map((option, idx) => (
                                  <option key={idx} value={option.item_name}>
                                    {option.item_name}
                                  </option>
                                ))}
                              </Form.Control>
                            </td>
                            {/* <td>
                              <Form.Control
                                type="number"
                                name="qty"
                                value={item.qty}
                                onChange={(e) => handleParticularChange(e, index)}
                                disabled={!item.item_name} // Disable if no item is selected
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="unit"
                                value={item.unit}
                                onChange={(e) => handleParticularChange(e, index)}
                                disabled
                              />
                            </td> */}
                            <td>
                              <Form.Control
                                type="text"
                                name="price"
                                value={item.price}
                                onChange={(e) =>
                                  handleParticularChange(e, index)
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <span>{item.balance}</span>
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="billed_amount"
                                value={item.billed_amount}
                                min="0"
                                onChange={(e) =>
                                  handleParticularChange(e, index)
                                }
                              />
                            </td>
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => handleDelRow(index)}
                                className="cursor-pointer"
                                alt="delete"
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  <InputError
                    isValid={isError.item_info}
                    message={"Please make sure all fields are filled"}
                  />
                </>
              ) : (
                <div className="entries-not-found">
                  There is no particulars recorded yet.
                </div>
              )}
              <InputError
                isValid={isError.items}
                message={"Please add at least 1 particular"}
              />
            </div>
          </Row>

          {/* ADD ITEM BUTTON */}
          <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRow()}>
              Add Item
            </Button>
          </Row>

          {/* SUBTOTAL, FREIGHT COST, DISCOUNT, & GRAND TOTAL */}
          <Fragment>
            <Row className="align-right pt-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">Subtotal</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle">PHP</span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle">
                  {numberFormat(
                    addDetails?.subtotal === "NaN" ? 0 : addDetails?.subtotal
                  )}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-3">
              <Col className="text-end">
                <Form.Check
                  inline
                  label="VAT Ex"
                  name="vat_type"
                  type="radio"
                  value="vat-ex"
                  checked={addDetails.vat_type === "vat-ex"}
                  onClick={(e) => {
                    handleAddDetailsChange(e)
                  }}
                />
                <Form.Check
                  inline
                  label="VAT In"
                  name="vat_type"
                  type="radio"
                  value="vat-in"
                  checked={addDetails.vat_type === "vat-in"}
                  onClick={(e) => {
                    handleAddDetailsChange(e)
                    // setAddDetails((prev) => ({...prev, vat_type: "vat-in"}))
                  }}
                />
              </Col>
            </Row>
            {addDetails.vat_type === "vat-in" && (
              <>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">12% VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.vat_twelve
                        ? numberFormat(
                            parseFloat(addDetails.vat_twelve).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">Net of VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.vat_net
                        ? numberFormat(
                            parseFloat(addDetails.vat_net).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">WHT</span>
                  </Col>
                  <Col
                    xs={4}
                    className="text-end d-flex justify-content-end align-items-center"
                  >
                    <Form.Select
                      value={selectedWHT}
                      onChange={(e) => {
                        const value = e.target.value
                        setSelectedWHT(value)

                        if (addDetails.vat_type === "vat-in") {
                          const subtotal = parseFloat(addDetails.subtotal || 0)
                          const net = subtotal / 1.12
                          const vatTwelve = subtotal - net
                          const discount = parseFloat(addDetails.discount || 0)
                          let withholdingTax = 0
                          let grandTotal = subtotal - discount

                          if (value) {
                            const percentage = parseFloat(value) / 100
                            withholdingTax = net * percentage
                            grandTotal = subtotal - withholdingTax - discount
                          }

                          setAddDetails((prev) => ({
                            ...prev,
                            withholding_tax: withholdingTax.toFixed(2),
                            vat_net: net.toFixed(2),
                            vat_twelve: vatTwelve.toFixed(2),
                            grand_total: grandTotal.toFixed(2),
                            is_wht: value ? 1 : 0,
                          }))
                        }
                      }}
                      className="edit-label color-gray w-50"
                      style={{ width: "100px" }}
                      disabled={addDetails.vat_type !== "vat-in"}
                    >
                      <option value="">Select</option>
                      <option value="1">1%</option>
                      <option value="2">2%</option>
                      <option value="3">3%</option>
                      <option value="5">5%</option>
                      <option value="12">12%</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span
                      className="edit-label align-middle vat-total-text text-end"
                      style={{ minWidth: "100px" }}
                    >
                      {addDetails.withholding_tax
                        ? numberFormat(
                            parseFloat(addDetails.withholding_tax).toFixed(2)
                          )
                        : "0.00"}
                    </span>
                  </Col>
                </Row>
              </>
            )}
            <Row className="align-right py-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">Discount</span>
              </Col>
              <Col
                xs={4}
                className="text-end d-flex justify-content-end align-items-center"
              >
                <Form.Control
                  type="text"
                  name="discount"
                  value={addDetails.discount || ""}
                  min="0"
                  className="edit-label mb-2 w-50 text-end"
                  onChange={(e) => {
                    // Only allow numbers and decimal point
                    const value = e.target.value.replace(/[^\d.]/g, "")
                    // Prevent multiple decimal points
                    const parts = value.split(".")
                    if (parts.length > 2) return
                    // Ensure only 2 decimal places
                    if (parts[1] && parts[1].length > 2) return

                    handleAddDetailsChange({
                      target: {
                        name: "discount",
                        value: value,
                      },
                    })
                  }}
                  onKeyPress={(e) => {
                    // Allow only numbers and decimal point
                    if (!/[\d.]/.test(e.key)) {
                      e.preventDefault()
                    }
                    // Prevent multiple decimal points
                    if (e.key === "." && e.target.value.includes(".")) {
                      e.preventDefault()
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className="align-right py-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray grand-total-text">
                  Grand Total
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  PHP
                </span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle grand-total-text text-end">
                  {numberFormat(
                    addDetails?.grand_total === "NaN"
                      ? 0
                      : parseFloat(addDetails.grand_total).toFixed(2)
                  )}
                </span>
              </Col>
            </Row>
            {(process || createinvoice) &&
              addDetails.payment_status !== "closed_bill" && (
                <>
                  <Row className="align-right pt-3">
                    <Col xs={2} className="text-end">
                      <span className="edit-label color-gray">
                        Payment Type
                      </span>
                    </Col>
                    <Col xs={1} className="text-end"></Col>
                    <Col xs={3}>
                      <Form.Check
                        inline
                        label="Cash"
                        name="payment_type"
                        value="cash"
                        type="radio"
                        defaultChecked={addDetails.payment_type === "cash"}
                        onClick={(e) => {
                          handleCreatePayment(e)
                        }}
                      />
                      <Form.Check
                        inline
                        label="Check"
                        name="payment_type"
                        type="radio"
                        value="check"
                        defaultChecked={addDetails.payment_type === "check"}
                        onClick={(e) => {
                          handleCreatePayment(e)
                        }}
                      />
                      <Form.Check
                        inline
                        label="Others"
                        name="payment_type"
                        value="others"
                        defaultChecked={addDetails.payment_type === "others"}
                        type="radio"
                        onClick={(e) => {
                          handleCreatePayment(e)
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="align-right pt-3">
                    <Col xs={2} className="text-end">
                      <span className="edit-label color-gray">
                        Paid Amount
                        <span className="color-red"> *</span>
                      </span>
                    </Col>
                    <Col xs={1} className="text-end">
                      <span className="edit-label align-middle">PHP</span>
                    </Col>
                    <Col xs={3}>
                      <Form.Control
                        type="number"
                        name="paid_amount"
                        defaultValue={addDetails.paid_amount}
                        className="align-middle nc-modal-custom-text"
                        onChange={(e) => handleCreatePayment(e)}
                      />
                    </Col>
                  </Row>
                </>
              )}
          </Fragment>

          {(process || createinvoice) &&
            addDetails.payment_status !== "closed_bill" && (
              <>
                {addDetails.payment_type === "cash" && (
                  <>
                    <div className="mt-5"></div>
                    <hr />
                    <div className="payment-header-wrapper mb-5">
                      <h5 className="payment-header">Payment Details</h5>
                    </div>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="date"
                          name="payment_date"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.payment_date}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.payment_date}
                          message={"Payment date is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Invoice Number
                          <label className="badge-required">{` *`}</label>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="invoice_no"
                          className="nc-modal-custom-text"
                          value={addDetails.invoice_no}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.invoice_no}
                          message={"Invoice Number is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposit Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DatePicker
                          className="nc-modal-custom-text-new-datepicker"
                          name="deposit_date"
                          value={
                            addDetails.deposit_date
                              ? dayjs(addDetails.deposit_date)
                              : null
                          }
                          onChange={(date, dateString) => {
                            handleCreatePayment({
                              target: {
                                name: "deposit_date",
                                value: dateString,
                              },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposited To
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Select
                          type="text"
                          name="to_bank_id"
                          className="nc-modal-custom-text"
                          value={addDetails.to_bank_id}
                          onChange={(e) => handleCreatePayment(e)}
                        >
                          <option value="">Select a bank...</option>
                          {banks.map((data) => {
                            return (
                              <option value={data.id}>{data.bank_name}</option>
                            )
                          })}
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Term (days)
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="term_day"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.term_day}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Remarks
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="payment_remarks"
                          defaultValue={addDetails.payment_remarks}
                          className="nc-modal-custom-text"
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {/* CHECK PAYMENT DETAILS */}
                {addDetails.payment_type === "check" && (
                  <>
                    <div className="mt-5"></div>
                    <hr />
                    <div className="payment-header-wrapper mb-5">
                      <h5 className="payment-header">Payment Details</h5>
                    </div>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Invoice Number
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Check Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DatePicker
                          className="nc-modal-custom-text-new-datepicker"
                          name="payment_date"
                          value={
                            addDetails.payment_date
                              ? dayjs(addDetails.payment_date)
                              : null
                          }
                          onChange={(date, dateString) => {
                            handleCreatePayment({
                              target: {
                                name: "payment_date",
                                value: dateString,
                              },
                            })
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="invoice_no"
                          className="nc-modal-custom-text"
                          value={addDetails.invoice_no}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.invoice_no}
                          message={"Invoice Number is required"}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="date"
                          name="cheque_date"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.cheque_date}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.cheque_date}
                          message={"Check date is required"}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Bank Name
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Check Number
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="bank_name"
                          className="nc-modal-custom-text"
                          value={addDetails.bank_name}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.bank_name}
                          message={"Bank is required"}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="cheque_number"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.cheque_number}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.cheque_number}
                          message={"Check number is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposited To
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Select
                          type="text"
                          name="to_bank_id"
                          className="nc-modal-custom-text"
                          value={addDetails.to_bank_id}
                          onChange={(e) => handleCreatePayment(e)}
                        >
                          <option value="">Select a bank...</option>
                          {banks.map((data) => {
                            return (
                              <option value={data.id}>{data.bank_name}</option>
                            )
                          })}
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Term (days)
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="term_day"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.term_day}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <Row className="edit-label">
                          Payment Remarks
                          <Row className="edit-optional px-2">(Optional)</Row>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="payment_remarks"
                          defaultValue={addDetails.payment_remarks}
                          className="nc-modal-custom-text"
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {/* OTHERS PAYMENT DETAILS */}
                {addDetails.payment_type === "others" && (
                  <>
                    <div className="mt-5"></div>
                    <hr />
                    <div className="payment-header-wrapper mb-5">
                      <h5 className="payment-header">Payment Details</h5>
                    </div>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DatePicker
                          className="nc-modal-custom-text-new-datepicker"
                          name="payment_date"
                          value={
                            addDetails.payment_date
                              ? dayjs(addDetails.payment_date)
                              : null
                          }
                          onChange={(date, dateString) => {
                            handleCreatePayment({
                              target: {
                                name: "payment_date",
                                value: dateString,
                              },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Invoice Number
                          <label className="badge-required">{` *`}</label>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="invoice_no"
                          className="nc-modal-custom-text"
                          value={addDetails.invoice_no}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Reference Number
                          <label className="badge-required">{` *`}</label>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="reference_number"
                          className="nc-modal-custom-text"
                          value={addDetails.reference_number}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.reference_number}
                          message={"Reference number is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">Payment Description</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="payment_remarks"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.payment_remarks}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposited To
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Term (days)
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Select
                          type="text"
                          name="to_bank_id"
                          className="nc-modal-custom-text"
                          value={addDetails.to_bank_id}
                          onChange={(e) => handleCreatePayment(e)}
                        >
                          <option value="">Select a bank...</option>
                          {banks.map((data) => {
                            return (
                              <option value={data.id}>{data.bank_name}</option>
                            )
                          })}
                        </Form.Select>
                        <InputError
                          isValid={isError.to_bank_id}
                          message={"Deposited to is required"}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="term_day"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.term_day}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Remarks
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="payment_remarks"
                          defaultValue={addDetails.payment_remarks}
                          className="nc-modal-custom-text"
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            {isClicked ? (
              <div className="button-primary d-flex justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              // )   :
              //   addDetails.project_id_value === "" ||
              //   addDetails.project_date === "" ||
              //   addDetails.invoice_date === "" ||
              //   addDetails.company === "" ||
              //   desData?.length === 0 ||
              //   qtyData?.length === 0 ||
              //   unitData?.length === 0 ||
              //   priceData?.length === 0 ? (
              //   <button type="button" className="button-primary" disabled>
              //     Submit
              //   </button>
              <button
                type="button"
                disabled={!isSubmittable}
                className="button-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FormProjectInvoice.defaultProps = {
  add: false,
  edit: false,
}

export default FormProjectInvoice
