import { Modal, Button, Form, Container, Row, Col} from "react-bootstrap";
import React, { useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import NoDataImg from "../../Assets/Images/no-data-dog.png"
import Table from "../../Components/TableTemplate/DataTable";
// import { render } from "react-dom";
import { 
    numberFormat,
} from "../../Helpers/Utils/Common";
import { useNavigate } from "react-router-dom";
import warning from "../../Assets/Images/Modal/warning.png";
import {searchProject} from './../../Helpers/apiCalls/Manage/Projects';
import { searchProjectExpense, deleteProjectExpense } from './../../Helpers/apiCalls/ProjectInvoice/ProjectExpenseApi';

//css
import "./Modal.css";

function AttachmentsModal(props) {
    let navigate = useNavigate();
    const [filterConfig, setFilterConfig] = useState({
        name: "",
      });
    
    function handleViewBtn(id, status) {
        navigate("/projectexpense/view/" + id + "/" + status);
    }
    function handleSelectChange(e, id) {
   
    if (e.target.value === "view-invoice") {
      window.open("projectexpense/view/" + id +"/approved","_blank");
    }
  }
    function ViewBtn(row) {
      return (
        <button
          name="action"
          className="btn btn-sm view-btn-table"
          id={row.id}
          onClick={(e) =>
            handleSelectChange(e, row.id)
          }
          value="view-invoice"
        >
          View
        </button>
      );
    }
    
    // async function fetchData() {
    //     setShowLoader(true);
    //     setAllData([]);
    
    //     const response = await searchProjectExpense(filterConfig);
    //     if (response.data) {
    
    //       var allData = response.data.data.map((data) => {
    //         var info = data;
    //         info.added_on = data.added_on !== "0000-00-00" ? formatDateNoTime(data.added_on) : "";
    //         info.amount = numberFormat(data.amount);
    //         info.balance = numberFormat(data.balance);
    //         info.paid_amount = data.paid_amount ? numberFormat(data.paid_amount) : "0.00";
    //         return info;
    //       });
    //       setAllData(allData);
    
    //     } else if (response.error) {
    //       if(response.error.data.status !== 404) {
    //         TokenExpiry(response.error);
    //       }
    //     }
    //     setShowLoader(false);
    //   }
    return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size={props.size}
        centered
      >
      <Modal.Header/>
        <Modal.Body>
          <div className="col-sm-12">
            <Row>
              <Col sm={7}>
                <Row>
                  <span className="expense-modal-body-title">Attachments</span>
                </Row>
              </Col>
            </Row>
            <Container fluid className="modal-cont justify-content-center">
                <Row className="py-3">
                    <Col className="print-table">
                        {props.transaction?.attachment?.length > 0 ? (
                            <>
                            <Carousel showArrows={true} >
                                {props.transaction?.attachment.map((data) => {
                                    if(data.base_64 !== "") {
                                        return(
                                            <>    
                                                <div className='mt-5 mb-2' style={{textAlignLast:"center"}}>
                                                    <img src={`data:image/png;base64,${data.base_64}`} width={100} height={100}/>
                                                </div>
                                            </>
                                        )
                                    } else {
                                        return(
                                            <>
                                                <div className="no-data-cont">
                                                    <div className='mt-5 mb-2' style={{textAlignLast:"center"}} alt="no data found"><img src={NoDataImg} width={100} height={100}/></div>
                                                    <span>Uh Oh! No data found.</span>
                                                </div>
                                            </>
                                        )
                                    }
                                })}
                            </Carousel>
                            </>
                        ) : (
                            <div className="no-data-cont">
                                <div className='mt-5 mb-2' style={{textAlignLast:"center"}} alt="no data found"><img src={NoDataImg} width={100} height={100}/></div>
                                <span>Uh Oh! No data found.</span>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
         
            <div className="col-sm-12 mt-3 d-flex justify-content-end">
                <button className="button-tertiary mr-3" onClick={props.onHide}>
                    Close
                </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

AttachmentsModal.defaultProps = {
  title:"",
  type:"",
  size:"xl",
  withButtons: false,
  withHeader: false,
  show:()=>{},
  onHide:()=>{},
  onEdit:()=>{}
}

export default AttachmentsModal;