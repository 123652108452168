import React, { useState } from "react";
import {Button, Col, Form, Row, Table } from "react-bootstrap";
import {useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import "react-autocomplete-input/dist/bundle.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactLoading from "react-loading";
import {
  toastStyle,
} from "../../Helpers/Utils/Common";
import Navbar from "../../Components/Navbar/Navbar";
import { Fragment } from "react";
import { useEffect } from "react";
import { getAllCustomer } from "../../Helpers/apiCalls/Manage/CustomerAPI";
import { searchProject } from "../../Helpers/apiCalls/Manage/Projects";
import { validateDistributor } from "../../Helpers/Validation/Distributor/DistributorValidation";
import InputError from "../../Components/InputError/InputError";
import { createDistributor, updateDistributor } from "../../Helpers/apiCalls/Distributor/DistributorApi";
import trash from "../../Assets/Images/trash.png";

function DistributorForm ({ add, edit }) {
  const navigate = useNavigate();
  const location = useLocation();
  const formPurpose = location.state.formPurpose;
  const passedId = location.state.passedId;
  const distributorDetails = location.state.distributorDetails;
  const [inactive, setInactive] = useState(true);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [allProjects, setAllProjects] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [addDetails, setAddDetails] = useState({
    name: "",
    address: "",
    contact_person: "",
    contact_no: "",
  });

  const [clientDetails, setClientDetails] = useState([
    {
      customer_id: '',
      projects: [{ project_id: '', project_date: '' }],
    },
  ]);

  const [isError, setIsError] = useState({
    name: false,
    contact_no: false,
    contact_person: false,
    address: false,
  });

  const handleChange = (e) => {
    var newData = {...addDetails}
    newData[e.target.name] = e.target.value;
    setAddDetails(newData)
  }

  const handleKeyPress = e => {
    const allowedChars = /[0-9.]/;
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  };
  
  async function handleSubmit () {
    setIsSubmitClicked(true)
    const errors = {};
    const finalProjectDetails = {
      customer_ids: [],
      project_ids: [],
      distributor_client_dates: [],
    }

    clientDetails.forEach((client, customerIndex) => {
      if (!client.customer_id) {
        errors[customerIndex] = {
          ...errors[customerIndex],
          customer_id: 'Customer is required',
        };
      }

      client.projects.forEach((project, projectIndex) => {
        if (!project.project_id) {
          if (!errors[customerIndex]) {
            errors[customerIndex] = {};
          }
          errors[customerIndex][projectIndex] = {
            ...errors[customerIndex][projectIndex],
            project_id: 'Project is required',
          };
        } else {
          finalProjectDetails.customer_ids.push(client.customer_id);
          finalProjectDetails.project_ids.push(project.project_id);
        }

        if (!project.project_date) {
          if (!errors[customerIndex]) {
            errors[customerIndex] = {};
          }
          errors[customerIndex][projectIndex] = {
            ...errors[customerIndex][projectIndex],
            project_date: 'Project date is required',
          };
        } else {
          finalProjectDetails.distributor_client_dates.push(project.project_date);
        }
      });
    });

    // Update the formErrors state to trigger rendering of error messages
    setFormErrors(errors);

    // if (Object.keys(errors).length === 0 && validateDistributor(addDetails, setIsError)) { // use this id client details are still needed
      if (validateDistributor(addDetails, setIsError)) {
      if (formPurpose==="ADD") {
        const response = await createDistributor({...addDetails, ...finalProjectDetails});
        if (response.data) {
          toast.success(response.data.response, {style: toastStyle()})
          setTimeout(() => navigate("/distributors"), 1000);
        } else {
          toast.error(response.error.data.messages.response, {style: toastStyle()})
        }
      } else {
        const response = await updateDistributor({distributor_id: addDetails.id, ...addDetails, ...finalProjectDetails});
        if (response.data) {
          toast.success(response.data.response, {style: toastStyle()})
          setTimeout(() => navigate("/distributors"), 1000);
        } else {
          toast.error(response.error.data.messages.response, {style: toastStyle()})
        }
      }
    }
    setIsSubmitClicked(false);
  }

  async function fetchCustomer() {
    setCustomersData([]);
    const response = await getAllCustomer();
    let isCustomer = [];

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.data
    ) {

      let result = response.data.data.data.map((a) => {
        return {
          label: a.name,
          value: a.id,
          contact_no: a.contact_no,
          contact_person: a.contact_person,
          address: a.address,
          company: a.company,
          email: a.email,
        };
      });
      setCustomersData(result);
    }
  }

  async function fetchProjects () {
    const response = await searchProject({name:'',project_id:'',customer_id:''})
    if (response.data) {
      setAllProjects(response.data.data);
    }
  }

  async function getDistributorDetails () {
    setAddDetails(distributorDetails);

    // Transform data from backend to the frontend structure
    const inputArray = distributorDetails.distributor_clients.length>0?distributorDetails.distributor_clients:[];
    const mergedMap = new Map();
    
    // Iterate through the input array
    inputArray.forEach(item => {
      const customerID = item.customer_id;
      const project = {
        project_id: item.project_id,
        project_date: item.distributor_client_date,
      };
    
      if (mergedMap.has(customerID)) {
        mergedMap.get(customerID).projects.push(project);
      } else {
        mergedMap.set(customerID, {
          customer_id: customerID,
          projects: [project],
        });
      }
    });
    const mergedArray = Array.from(mergedMap.values());
    setClientDetails(mergedArray);
  }

  const [clientData, setClientData] = useState([])
  const [clientData2, setClientData2] = useState([])
  const [clientData3, setClientData3] = useState([])

  //USE EFFECT
  useEffect(() => {
    fetchCustomer();
    fetchProjects();
    
    if (formPurpose==="EDIT") {
      getDistributorDetails()
    }
  }, []);

    useEffect(() => {
      clientDetails.map((data)=> {
        setClientData(data.customer_id)
        data.projects.map((info)=>{
          setClientData2(info.project_date)
          setClientData3(info.project_id)
        })
      })
  }, [clientDetails]);

  const CustomerProjectsTable = () => {
    
    const handleAddProject = (index) => {
      const updatedClientDetails = [...clientDetails];
      updatedClientDetails[index].projects.push({ project_id: '', project_date: '' });
      setClientDetails(updatedClientDetails);
    };

    const handleDeleteProject = (customerIndex, projectIndex) => {
      const updatedClientDetails = [...clientDetails];
      updatedClientDetails[customerIndex].projects.splice(projectIndex, 1);
      setClientDetails(updatedClientDetails);
    };
  
    const handleInputChange = (e, customerIndex, projectIndex, field) => {
      const updatedClientDetails = [...clientDetails];
     
      if (e.target.name==="customer_id") {
        updatedClientDetails[customerIndex].customer_id = e.target.value;
      } else if (field==="project_id") {
        const projectWithId = allProjects.find((project) => project.id === e.target.value);
        updatedClientDetails[customerIndex].projects[projectIndex][field] = e.target.value;
        updatedClientDetails[customerIndex].projects[projectIndex]["project_date"] = projectWithId.start_date;
      } else {
        updatedClientDetails[customerIndex].projects[projectIndex][field] = e.target.value;
      }
      
      setClientDetails(updatedClientDetails);
    };

    const handleAddRow = () => {
        setClientDetails([...clientDetails, { customer_id: '', projects: [{ project_id: '', project_date: '' }] }]);
      };

      const handleDeleteRow = (customerIndex) => {
        const updatedClientDetails = [...clientDetails];
        updatedClientDetails.splice(customerIndex, 1);
        setClientDetails(updatedClientDetails);
      };

      return (
      <div className="edit-purchased-items">
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Customer</th>
            <th>Project Details</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clientDetails.map((client, customerIndex) => (
            
            <>
            <tr key={customerIndex} className="">
                <td className="w-30">
                    <Form.Select
                        type="select"
                        name="customer_id"
                        value={client.customer_id}
                        onChange={(e) => handleInputChange(e, customerIndex, 0, 'customer_id')}
                        placeholder="Customer ID"
                    >
                        <option value="">Select Customer</option>
                        {customersData.map((data) => {
                        return (
                            <option key={data.value} value={data.value}>
                            {data.label}
                            </option>
                        );
                        })}
                    </Form.Select>
                    {formErrors[customerIndex] && formErrors[customerIndex].customer_id && (
                      <div className="validity-error callout bottom">{formErrors[customerIndex].customer_id}</div>
                    )}
                </td>
                <td>
                    {client.projects.map((project, projectIndex) => (
                        <>
                        <div key={projectIndex}>
                            <Row className="mt-1">
                                <Col xs={5}>
                                  <Form.Select
                                      type="select"
                                      value={project.project_id}
                                      onChange={(e) =>
                                          handleInputChange(e, customerIndex, projectIndex, 'project_id')
                                      }
                                      placeholder="Select Project"
                                    >
                                        <option value="">Select Project</option>
                                        {allProjects.map((data) => {
                                          return (
                                              <option key={data.value} value={data.id}>
                                                {data.name}
                                              </option>
                                          );
                                        })}
                                    </Form.Select>
                                    {formErrors[customerIndex] && formErrors[customerIndex][projectIndex].project_id && (
                                      <div className="validity-error callout bottom">{formErrors[customerIndex][projectIndex].project_id}</div>
                                    )}
                                </Col>
                                <Col xs={5}>
                                    <Form.Control
                                        value={project.project_date}
                                        onChange={(e) =>
                                            handleInputChange(e, customerIndex, projectIndex, 'project_date')
                                        }
                                        type="date"
                                    />
                                    {formErrors[customerIndex] && formErrors[customerIndex][projectIndex].project_date && (
                                      <div className="validity-error callout bottom">{formErrors[customerIndex][projectIndex].project_date}</div>
                                    )}
                                </Col>
                                <Col xs={1} className="text-center">
                                    {
                                      client.projects.length > 1 && <img
                                      src={trash}
                                      onClick={() => handleDeleteProject(customerIndex, projectIndex)}
                                      className="cursor-pointer" alt=""
                                    />
                                    }
                                </Col>
                            </Row>
                        </div>
                        {
                            (projectIndex===client.projects.length-1) && (
                                <Row className="pt-2 PO-add-item py-0">
                                  <Button className="add-project-btn" type="button" onClick={()=>handleAddProject(customerIndex)}>
                                      Add Project
                                  </Button>
                                </Row>
                            )
                        }
                        </>
                    ))}
                </td>
                <td className="text-center">
                    {
                      clientDetails.length > 1 && <img
                      src={trash}
                      onClick={() => handleDeleteRow(customerIndex)}
                      className="cursor-pointer" alt=""
                  />
                    }
                </td>
            </tr>
            </>

          ))}
        </tbody>
      </Table>
      <Row className="pt-3 PO-add-item">
        <Button type="button" onClick={handleAddRow}>
            Add Client
        </Button>
        </Row>
      </div>
    );
  };
  
  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center my-3 pb-4">
          <h1 className="page-title mb-0">{formPurpose} DISTRIBUTOR</h1>
        </div>
        <div className="edit-form">
          <Fragment>
            <Row className="mt-4 mb-2">
                <Col>
                    <span htmlFor="customer_id" className="edit-label">Distributor Name<label className='badge-required'>*</label></span>
                    {/* <Form.Select
                        type="select"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select Distributor..."
                        name="customer_id"
                        id="customer_id"
                        value={addDetails.customer_id}
                        onChange={(e) => handleChange(e)}
                    >
                        <option value="">Select Distributor</option>
                        {customersData.map((data) => {
                          return (
                              <option key={data.value} value={data.value}>
                              {data.label}
                              </option>
                          );
                        })}
                    </Form.Select> */}
                    <Form.Control
                      value={addDetails.name}
                      name="name"
                      onChange={handleChange}
                    />
                    <InputError
                        isValid={isError.name}
                        message={"Distributor name is required"}
                    />
                </Col>

              <Col>
              <span htmlFor="customer_id" className="edit-label">Address<label className='badge-required'>*</label></span>
                <Form.Control
                  type="text"
                  name="address"
                  value={addDetails.address}
                  onChange={(e) => handleChange(e)}
                />
                <InputError
                  isValid={isError.address}
                  message={"Address is required"}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
              <span htmlFor="customer_id" className="edit-label">CONTACT PERSON <label className='badge-required'>*</label></span>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={addDetails.contact_person}
                  onChange={(e) => handleChange(e)}
                />
                <InputError
                  isValid={isError.contact_person}
                  message={"Contact person is required"}
                />
              </Col>
              <Col>
              <span htmlFor="customer_id" className="edit-label">CONTACT NUMBER<label className='badge-required'>*</label></span>
                <Form.Control
                  type="text"
                  name="contact_no"
                  value={addDetails.contact_no}
                  onChange={(e) => handleChange(e)}
                />
                <InputError
                  isValid={isError.contact_no}
                  message={"Contact number is required"}
                />
              </Col>
              
            </Row>
          </Fragment>

          {/* <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">CLIENTS</span>
            <CustomerProjectsTable/>
          </Row> */}

          <Row className="pt-5 pb-3">
            <Col xs={6}></Col>
            <Col xs={3}>
            <button
              type="button"
              className="button-secondary w-100"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            </Col>
            <Col xs={3}>
            {isSubmitClicked ? (
              <div className="button-primary d-flex justify-content-center w-100">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              addDetails.name === "" || addDetails.contact_no === "" ||
              addDetails.address === "" || addDetails.contact_person === "" ||
              clientDetails.customer_id === "" || clientDetails?.projects?.project_date === ""
              || clientDetails?.projects?.project_id === "" ?
              // || clientData.length === 0 || clientData2.length === 0 || clientData3.length === 0 ?
              <button
                disabled
                type="button"
                className="button-primary w-100"
              >
                Submit
              </button>
              :
              <button
                type="button"
                className="button-primary w-100"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}</Col>
        </Row>
        </div>
        </div>
    </div>
  );
}

DistributorForm.defaultProps = {
  add: false,
  edit: false,
};

export default DistributorForm;
