import { getToken, getUser } from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import qs from 'qs';

export const getSubscriptionBillings = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "subscription_billings/get_all_subscription_billing",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}


export const generateSubscriptionBilling = async (filter) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "subscription_billings/generate_subscription_billing",
            {
                requester: getUser(),
                token: getToken(),
                ...filter
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const createSubscriptionBilling = async (data) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "subscription_billings/create",
            {
                requester: getUser(),
                token: getToken(),
                ...data
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const updateSubscriptionBilling = async (data) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "subscription_billings/update",
            {
                requester: getUser(),
                token: getToken(),
                ...data
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}

export const deleteSubscriptionBilling = async (id) => {
    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "subscription_billings/delete",
            {
                requester: getUser(),
                token: getToken(),
                subscription_billing_id: id,
            },
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

export const addSubscriptionBillingPayment = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "subscription_billing_payments/create",
            {
                requester: getUser(),
                token: getToken(),
                ...data
            }
        );
        return { data: response.data }
    } catch (error) {
        return {error: error.response};
    }
}