import React, { useState } from "react";
import { Col, Container, Row, Table, Modal } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Moment from "moment";

// components
import PIModal from "./PIModal";

// utils, assets, & styles
import { getSuppliesInvoice } from "../../../../Helpers/apiCalls/Expenses/suppliesInvoiceApi";
import {
  dateFormat,
  formatDate,
  formatDateNoTime,
  formatDateSlash,
  getName,
  getTodayDateISO,
  numberFormat,
  refreshPage,
  toastStyle,
  TokenExpiry,
} from "../../../../Helpers/Utils/Common";
import cleanLogo from "../../../../Assets/Images/Login/logo.png";
import Navbar from "../../../../Components/Navbar/Navbar";
import TablePagination from "../../../../Components/TableTemplate/Table";
import { getSEPaymentHistory } from "../../../../Helpers/apiCalls/Expenses/sePurchaseApi";
import PaymentTable from "./PaymentTable";

export default function PrintPurchaseInvoice() {
  const { id } = useParams();
  const location = useLocation();
  let navigate = useNavigate();

  const [inactive, setInactive] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [printPI, setPrintPI] = useState([]);
  const [items, setItems] = useState([]);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  async function fetchPI() {
    setPrintPI({});
    setItems([]);

    const response = await getSuppliesInvoice(id);

    if (response.error) {
      TokenExpiry(response);
    } else {
      setPrintPI(response.data.data[0]);
      setItems(response.data.data[0].supplies_receive_items);
    }
  }

  async function fetchSEPaymentHistory() {
    const response = await getSEPaymentHistory(id);

    if (response.data) {
      var history = response.data.data;

      var allPayments = history.map((payment) => ({
        payment_date: payment.payment_date ? Moment(payment.start_date).format("DD-MM-YYYY") : "",
        remarks: payment.remarks || "",
        from_bank_name: payment.from_bank_name || "",
        paid_amount: numberFormat(payment.paid_amount) || "0.00",
        attachments: payment.attachment || []
      }));
      setPaymentInfo(allPayments);
    }
  }

  /* print modal handler */
  const [showPrintModal, setShowPrintModal] = useState(false);
  const handleShowPrintModal = () => setShowPrintModal(true);
  const handleClosePrintModal = () => setShowPrintModal(false);

  async function handlePrintPI() {
    toast.loading("Printing Invoice", { style: toastStyle() });
    handleClosePrintModal();
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  function renderTable() {
    return (
      <Table className="align-middle">
        <thead>
          <tr>
            <th>Item</th>
            <th className="text-center">Quantity</th>
            <th>Unit</th>
            <th className="text-end">Unit Price</th>
            <th className="text-end">Amount</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item) => {
            return (
              <tr>
                <td>{item.name}</td>
                <td className="text-center">{Math.round(item.qty)}</td>
                <td className="text-lowercase">{item.unit}</td>
                <td className="text-end">{numberFormat(item.price)}</td>
                <td className="text-end">{numberFormat(item.total)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }

  // Initial data fetch
  React.useEffect(() => {
    fetchPI();
    fetchSEPaymentHistory();
  }, [id]); // Add id as dependency

  // Handle refresh after new payment
  React.useEffect(() => {
    if (location.state?.refresh) {
      // Fetch both PI and payment history
      fetchPI();
      fetchSEPaymentHistory();
      // Clear the refresh state
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state?.refresh]); // Only run when refresh state changes

  // Add handler for attachment preview
  const handlePaymentAttachmentPreview = (attachment) => {
    setSelectedAttachment(attachment);
    setShowAttachmentModal(true);
  };

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"EXPENSE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
          <div className="text-end print-header d-flex flex-column">
            <span>SE NO. {printPI.se_id}</span>
            <span className="text-uppercase">
              {Moment(printPI.supplies_receive_date).format("MMMM DD, yyyy")}
            </span>
          </div>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" />
          </div>
          <div className="d-flex justify-content-center py-1 mt-1">
            <h5 className="print-shop-header">MYT SOFTDEV SOLUTIONS.</h5>
          </div>

          {/* content */}
          <div className="print-body mt-5">
            <Row>
              <Col>
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Supplier:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.supplier_name
                      ? printPI.supplier_name
                      : printPI.vendor_name}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Contact Person:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.contact_person}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Waybill No.:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.waybill_no}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Forwarder:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.forwarder_name}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Invoice No.:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.invoice_no}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Remarks:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.remarks}
                  </Col>
                </div>
              </Col>
              <Col>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Branch:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.branch_name}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Type:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.expense_type_name}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    DR No.:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.dr_no}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Contact No.:
                  </Col>
                  <Col xs={7} className="print-data">
                    {printPI.phone_no}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Date Purchased:
                  </Col>
                  <Col xs={7} className="print-data">
                    {dateFormat(printPI.purchase_date)}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Date Received:
                  </Col>
                  <Col xs={7} className="print-data">
                    {dateFormat(printPI.supplies_receive_date)}
                  </Col>
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-5 mb-2 justify-content-evenly">
              {/* table */}
              <div className="print-table mt-3 mx-2">{renderTable()}</div>
            </div>
            <Row className="print-grand-total my-3 text-end mr-0">
              <Col xs={3} className="print-table-footer-label">
                SUBTOTAL
              </Col>
              <Col xs={2} className="print-table-footer-data text-end">
                PHP {printPI.subtotal ? numberFormat(printPI.subtotal) : "0.00"}
              </Col>
            </Row>
            <Row className="print-grand-total my-3 text-end mr-0">
              <Col xs={3} className="print-table-footer-label">
                FREIGHT COST
              </Col>
              <Col xs={2} className="print-table-footer-data text-end">
                PHP {printPI?.freight_cost || "0.00"}
              </Col>
            </Row>
            <Row className="print-grand-total my-3 text-end mr-0">
              <Col xs={3} className="print-table-footer-label">
                DISCOUNT
              </Col>
              <Col xs={2} className="print-table-footer-data text-end">
                PHP {printPI?.discount || "0.00"}
              </Col>
            </Row>
            <Row className="print-grand-total my-3 text-end mr-0">
              <Col xs={3} className="print-table-footer-label">
                GRAND TOTAL
              </Col>
              <Col xs={2} className="print-table-footer-data text-end">
                PHP {printPI.grand_total ? numberFormat(printPI.grand_total) : "0.00"}
              </Col>
            </Row>
            <div className="print-signatures">
              <span className="text-center text-uppercase print-label fw-bold">
                {getName()}
              </span>
              <span className="text-center text-uppercase print-label fw-bold">
                {printPI.prepared_by}
              </span>
            </div>
            <div className="print-signatories pb-4 mb-4">
              <Row>
                
              </Row>
              <span>Received by</span>
              <span>Prepared by</span>
            </div>
          </div>
        </div>

        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
          <h5 className="PI-payment-info">PAYMENT INFO</h5>
          <div className="justify-content-center">
            <PaymentTable 
              tableHeaders={["PAYMENT DATE", "REMARKS", "CREDIT FROM", "PAID AMOUNT", "ATTACHMENT"]}
              headerSelector={["payment_date", "remarks", "from_bank_name", "paid_amount", "attachment"]}
              tableData={paymentInfo}
              onAttachmentClick={handlePaymentAttachmentPreview}
            />
          </div>
        </Container>

        {/* footer buttons - increased bottom spacing */}
        <div className="d-flex justify-content-end my-4 mb-8 pb-5 d-flex-responsive" style={{marginBottom: "120px"}}>
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/se/purchaseinvoices")}
          >
            Close
          </button>
          <button
            className="button-tertiary me-3"
            onClick={() => navigate("/se/purchaseinvoices/edit/" + id)}
          >
            Edit
          </button>
          <button className="button-primary" onClick={handleShowPrintModal}>
            Print
          </button>
        </div>
      </div>
      {/* modals */}
      <PIModal
        show={showPrintModal}
        hide={handleClosePrintModal}
        type="print"
        handler={handlePrintPI}
      />

      {/* Add the Attachment Modal */}
      <Modal
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedAttachment?.name || "View Attachment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAttachment && (
            <img
              src={selectedAttachment.base_64}
              alt="Payment Attachment"
              style={{ 
                width: '100%', 
                height: 'auto',
                objectFit: 'contain' 
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
