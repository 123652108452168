import moment from "moment";
import { Fragment } from "react";
import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  formatDateNoTime,
  getTodayDateISO,
  numberFormat,
  refreshPage,
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import { getProject } from "../../Helpers/apiCalls/Manage/Projects";
import PaymentTable from "../Sales/PaymentTable";
import Moment from "moment";
import Compressor from "compressorjs";
import FileExceedModal from "../../Components/Modals/FileExceedModal";
import ProjectChangeRequestItemModal from "../ProjectChangeRequest/ProjectChangeRequestItemModal";

export default function PrintFranchiseInvoice(edit) {
  const { id } = useParams();
  let navigate = useNavigate();
  const today = getTodayDateISO();

  const [inactive, setInactive] = useState(true);
  const [project, setProject] = useState([]);
  const [items, setItems] = useState([]);
  const [oneTimeFeeTotal, setOneTimeFeeTotal] = useState(0);
  const [oneTimeFeeData, setOneTimeFeeData] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [changeRequestData, setChangeRequestData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showFileExceedModal, setShowFileExceedModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedRequestId, setSelectedRequestId] = useState(null);

  /* modal handler */
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleRowClick = (row) => {
    setSelectedRequestId(row.request_id);
    const filteredItems = row.change_request_item || [];
    setSelectedItems(filteredItems);
    setShowModal(true);
  };

  async function fetchData() {
    setProject({});
    setItems([]);
    const response = await getProject(id);

    if (response.error) {
      
      TokenExpiry(response);
    } else {
      var details = response.data.data[0];
      setProject(details);
      const formattedRecurringCost = details.recurring_cost.map(cost => {
        return {
          ...cost,
          total: numberFormat(cost.total)
        }
      });
      const formattedChangeRequests = details.change_request.map(req => ({
        request_id: req.id,
        request_no: req.request_no,
        request_date: formatDateNoTime(req.request_date),
        subtotal: req.subtotal,
        vat_twelve: req.vat_twelve,
        vat_net: req.vat_net,
        grand_total: numberFormat(req.grand_total),
        change_request_item: req.project_change_request_item 
          ? req.project_change_request_item .map((item) => ({
              ...item,
              amount: numberFormat(item.amount),
            }))
          : [],
      }));    
      
      setChangeRequestData(formattedChangeRequests);
      setItems(formattedRecurringCost);
      setOneTimeFeeData(details.one_time_fee);

      let tempOneTimeTotal = 0
      details?.one_time_fee?.forEach((row) => {
        tempOneTimeTotal += parseFloat(row.amount);
      })
      setOneTimeFeeTotal(tempOneTimeTotal);

      if (details.invoice) {
        var invoice = details.invoice.map((data) => {
          var info = data;
          info.invoice_amount = numberFormat(data.invoice_amount);
          info.balance = numberFormat(data.balance);
          info.grand_total = numberFormat(data.grand_total);
          info.paid_amount = data.paid_amount
            ? numberFormat(data.paid_amount)
            : "0.00";
          if (data.payment_method === "check") {
            info.payment_method =
              data.payment_method + "-" + data.cheque_number;
          }
          info.payment_date = formatDateNoTime(data.payment_date);
          info.invoice_date = formatDateNoTime(data.invoice_date);
          return info;
        });
        setPaymentInfo(invoice);
      } else {
        setPaymentInfo([]);
      }
    }
  }

  async function handlePrint() {
    toast.loading("Printing sales invoice...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleRemove = (selectedFile) => {
    var newlist = fileList.filter((file) => {
      return selectedFile.uid !== file.uid;
    });
    setFileList(newlist);
  };

  const handleBeforeUpload = (file) => {
    if (file.size > 2000000) {
      setShowFileExceedModal(true);
    } else {
      new Compressor(file, {
        quality: 0.6,
        success: (compressedResult) => {
          setFileList([...fileList, compressedResult]);
        },
      });
    }
    return false;
  };

  const handlePreview = (file) => {
    try {
      let src = file.url || file.base64;

      if (!src) {
        console.error("No valid source for preview");
        toast.error("No preview available", {
          style: toastStyle(),
        });
        return;
      }

      const newTab = window.open();
      newTab.document.write(`<img src="${src}" style="width:100%; height:auto;" />`);

    } catch (error) {
      console.error("Error previewing file:", error);
      toast.error("Error previewing file", {
        style: toastStyle(),
      });
    }
  };

  const handleFileChange = ({ file, fileList }) => {
    setFileList(fileList);
    // Update project data with new files
    setProject((prev) => ({
      ...prev,
      file: fileList.map((file) => file.originFileObj || file),
    }));
  };

  const calculateChangeRequestTotal = (data) => {
    const total = data.reduce((sum, item) => {
      return sum + parseFloat(item.amount.replace(/,/g, '') || 0);
    }, 0);
    return total;
  };

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
          <div className="print-grand-total my-3 text-end print-header d-flex flex-column">
            <span>PROJECT NO. {project.id}</span>
            <span className="text-uppercase">
              {moment(project.added_on).format("MM-DD-YYYY")}
            </span>
          </div>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" alt="logo"/>
          </div>

          {/* content */}
          <div className="print-body mt-5">
            <div className="">
              {/* FRANCHISEE SALES INVOICE DETAILS */}
              <Fragment>
                <Row className="pt-3 mb-2">
                  <Col xs={5}>
                    <span className="edit-label">
                      Project Name
                      <label className="badge-required">{` *`}</label>
                    </span>
                  </Col>
                  <Col xs={3}>
                    <span className="edit-label">
                      Contract Date
                      <label className="badge-required">{` *`}</label>
                    </span>
                  </Col>
                  <Col>
                    <span className="edit-label">
                      Start Date (deployment)
                      <label className="badge-required">{` *`}</label>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={project.name}
                      className="nc-modal-custom-text"
                      disabled
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="date"
                      name="project_date"
                      className="nc-modal-custom-text"
                      value={project.project_date}
                      defaultValue={today}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="date"
                      name="start_date"
                      className="nc-modal-custom-text"
                      value={project.start_date}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col>
                    <span className="edit-label">
                      Customer Name
                      <label className="badge-required">{` *`}</label>
                    </span>
                  </Col>
                  <Col>
                    <span className="edit-label">Address</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      name="customer_name"
                      className="nc-modal-custom-text"
                      value={project.customer_name}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      className="nc-modal-custom-text"
                      name="address"
                      trigger=""
                      value={project.address}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col>
                    <span className="edit-label">Company</span>
                  </Col>
                  <Col>
                    <span className="edit-label">Contact Person</span>
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col>
                    <Form.Control
                      type="text"
                      name="company"
                      className="nc-modal-custom-text"
                      value={project.company}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="contact_person"
                      className="nc-modal-custom-text"
                      value={project.contact_person}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col>
                    <span className="edit-label">Distributor</span>
                  </Col>
                  <Col>
                    <span className="edit-label">Payment Structure</span>
                  </Col>
                  <Col>
                    <span className="edit-label">Project Type</span>
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col>
                    <Form.Control
                      type="text"
                      name="company"
                      className="nc-modal-custom-text"
                      value={project.distributor_name}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="contact_person"
                      className="nc-modal-custom-text"
                      value={project.payment_structure}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="contact_person"
                      className="nc-modal-custom-text"
                      value={project.project_type}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col>
                    {/* <span className="edit-label">File Attachment</span> */}
                    {/* <Dragger
                      fileList={fileList}
                      onRemove={handleRemove}
                      beforeUpload={handleBeforeUpload}
                      multiple={true}
                      listType="picture"
                      onPreview={handlePreview}
                      onChange={handleFileChange}
                    >
                      <img src={upload} className="cursor-pointer" alt="" />
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger> */}
                  </Col>
                </Row>
              </Fragment>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">12% VAT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label align-middle">PHP</span>
                </Col>
                <Col xs={3} className="text-end">
                  <Form.Control
                    type="text"
                    name="taxes"
                    value={numberFormat(project.vat_twelve || 0)}
                    className="align-middle nc-modal-custom-text text-end"
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">Net of VAT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label align-middle">PHP</span>
                </Col>
                <Col xs={3} className="text-end">
                  <Form.Control
                    type="text"
                    name="other_fees"
                    value={numberFormat(project.vat_net || 0)}
                    className="align-middle nc-modal-custom-text text-end"
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">WHT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label align-middle">PHP</span>
                </Col>
                <Col xs={3} className="text-end">
                  <Form.Control
                    type="text"
                    name="other_fees"
                    value={numberFormat(project.withholding_tax || 0)}
                    className="align-middle nc-modal-custom-text text-end"
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row className="align-right py-5">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray grand-total-text">
                    Grand Total
                  </span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label align-middle grand-total-text">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle grand-total-text">
                    {numberFormat(project.grand_total)}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
          <h5 className="PI-payment-info">RECURRING FEE</h5>
          <div className="sales-tbl justify-content-center">
            <PaymentTable
              tableHeaders={["DESCRIPTION", "TYPE", "PERIOD", "AMOUNT", "TOTAL"]}
              headerSelector={["descriptions", "types", "periods", "prices", "total"]}
              tableData={items}
            />
          </div>
          <Row className="align-right pt-3">
            <Col xs={4} className="text-end">
              <span className="edit-label color-gray">
                Recurring Fee Total
                {/* <label className="badge-required">{` *`}</label> */}
              </span>
            </Col>
            <Col xs={1} className="text-end"></Col>
            <Col xs={3}>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="recurring_cost"
                    disabled
                    value={project.recurring_cost_total ? numberFormat(project.recurring_cost_total) : "0"}
                    className="align-middle nc-modal-custom-text right-align"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
          <h5 className="PI-payment-info">ONE TIME FEE</h5>
          <div className="sales-tbl justify-content-center">
            <PaymentTable
              tableHeaders={["DESCRIPTION", "AMOUNT"]}
              headerSelector={["description", "amount"]}
              tableData={oneTimeFeeData}
            />
          </div>
          <Row className="align-right pt-3">
            <Col xs={4} className="text-end">
              <span className="edit-label color-gray">
                One Time Fee Total
              </span>
            </Col>
            <Col xs={1} className="text-end"></Col>
            <Col xs={3}>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="recurring_cost"
                    disabled
                    value={oneTimeFeeTotal ? numberFormat(oneTimeFeeTotal) : "0"}
                    className="align-middle nc-modal-custom-text right-align"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
        <h5 className="PI-payment-info">CHANGE REQUEST</h5>
        <div className="sales-tbl justify-content-center">
          <PaymentTable
            tableHeaders={["REQUEST NO.", "REQUEST DATE", "TOTAL"]}
            headerSelector={["request_no", "request_date", "grand_total"]}
            tableData={changeRequestData}
            handleRowClick={(e) => handleRowClick(e)}
          />
        </div>
        </Container>
        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
          <h5 className="PI-payment-info">INVOICE HISTORY</h5>
          <div className="sales-tbl justify-content-center">
            <PaymentTable
              tableHeaders={[
                "INV DATE",
                "COMPANY",
                "STATUS",
                // "PARTICULARS",
                "PAID AMT",
                "INV AMT",
                "BALANCE",
                // "DEPOSITED TO",
                "ADDED BY",
              ]}
              headerSelector={[
                "invoice_date",
                "company",
                "payment_status",
                // "project_invoice_item_name",
                "paid_amount",
                "grand_total",
                "balance",
                // "to_bank_name",
                "added_by_name",
              ]}
              tableData={paymentInfo}
            />
          </div>
        </Container>
        
        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/project")}
          >
            Close
          </button>
          <button
            className="button-tertiary me-3"
            onClick={() => navigate("/project/edit/" + id)}
          >
            Edit
          </button>
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
      <FileExceedModal
        show={showFileExceedModal}
        onHide={() => setShowFileExceedModal(false)}
        size="2MB"
      />
      <ProjectChangeRequestItemModal
        id={selectedRequestId}
        changeRequestItem={selectedItems}
        show={showModal}
        onHide={handleCloseModal}
      />
    </div>
  );
}
