import dayjs from "dayjs";
import { getToken, getUser } from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";

export const createChangeRequest = async (data, particulars) => {
    try {
        var payload = {
            requester: getUser(),
            token: getToken(),
            project_id: data.project_id,
            request_date: dayjs(data.request_date).format("YYYY-MM-DD"),
            request_no: data.request_no,
            remarks: data.remarks,
            subtotal: data.subtotal,
            vat_type: data.vat_type,
            vat_twelve: data.vat_twelve,
            vat_net: data.vat_net,
            wht: data.wht,
            wht_percent: data.wht_percent,
            company: data.company,
            address: data.address,
            name: data.name,
            grand_total : data.grand_total,
            discount : data.discount,
            "names": particulars.map((item) => item.name),
            "amounts": particulars.map((item) => item.amount),
            "descriptions": particulars.map((item) => item.description)
        }
     
        const response = await postAPICall( process.env.REACT_APP_LINK + "project_change_requests/create", payload);
        return response;
    } catch (error) {
        return error
    }
};

export const updateChangeRequest = async (change_request_id, data, particulars) => {
    try {
        var payload = {
            requester: getUser(),
            token: getToken(),
            change_request_id: change_request_id,
            project_id: data.project_id,
            request_date: dayjs(data.request_date).format("YYYY-MM-DD"),
            request_no: data.request_no,
            remarks: data.remarks,
            subtotal: data.subtotal,
            vat_type: data.vat_type,
            vat_twelve: data.vat_twelve,
            vat_net: data.vat_net,
            wht: data.wht,
            wht_percent: data.wht_percent,
            company: data.company,
            address: data.address,
            name: data.name,
            grand_total: data.grand_total,
            discount: data.discount,
            "names": particulars.map((item) => item.name),
            "amounts": particulars.map((item) => item.amount),
            "descriptions": particulars.map((item) => item.description)
        }

        const response = await postAPICall(
            process.env.REACT_APP_LINK + "project_change_requests/update", 
            payload
        );
        
        return response;
    } catch (error) {
        return error;
    }
};

// Add a function to get single change request details
export const getChangeRequest = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "project_change_requests/get_change_request",
            {
                requester: getUser(),
                token: getToken(),
                id: id
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

// GET SINGLE
export const getProjectChangeRequestItem = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_change_requests/get_project_change_request_item",
      {
        requester: getUser(),
        token: getToken(),
        change_request_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const searchProjectChangeRequest = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_change_requests/search",
      {
        requester: getUser(),
        token: getToken(),
        ...data,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const deleteProjectChangeRequest = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_change_requests/delete",
      {
        requester: getUser(),
        token: getToken(),
        project_change_request_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}