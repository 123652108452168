import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import {
    getTodayDate,
    getType,
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
import moment from "moment";
import NoDataImg from "./../../../Assets/Images/no-data-dog.png"
import { getExpenseByDateReport, searchExpenseByDate } from './../../../Helpers/apiCalls/ExpensesReports/ExpenseReportApi';
import { getAllExpenseType } from "../../../Helpers/apiCalls/expensetypesApi";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

export default function ExpenseByDate() {
    const navigate = useNavigate();
    const dateToday = getTodayDate();
    const accountType = getType();

    const [inactive, setInactive] = useState(true);
    const thirtyDaysBefore = (givenDate) => {
        const newDate = new Date(givenDate);
        newDate.setDate(newDate.getDate() - 30);
        newDate.setTime(newDate.getTime()-1);
        return newDate
    }

    const [filterConfig, setFilterConfig] = useState({
        expense_type_per_day_date_from: thirtyDaysBefore(dateToday),
        expense_type_per_day_date_to: dateToday,
        expense_type_per_day_type: '',
    });

    const [showLoader, setShowLoader] = useState(false);
    const [noData, setNoData] = useState(false);
    const [expenseDate, setExpenseDate] = useState([]);
    const [amount, setAmount] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState([]);
    const [allExpenseTypes, setAllExpenseTypes] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);

    const data = {
        datasets: filteredTypes.map((type, index) => {
          return {
            label: type.name,
            data: lineChartData[type.name] || [],
            borderColor: getColor(index),
            backgroundColor: getBackground(index),
            borderWidth: 1,
            lineTension: 0.1,
          };
        }),
      };
    
      function getColor(index) {
        const borderColor = [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ]
        return borderColor[index % borderColor.length];
      }
    
      function getBackground (index) {
        const backgroundColor = [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ];
            return backgroundColor[index % backgroundColor.length];
      }

    async function fetchData() {
        setShowLoader(true);
        setTotalQuantity(0);

        const response = await getExpenseByDateReport(filterConfig);

        if (response.error) {
            setExpenseDate([])
            setAmount([])
            setNoData(true)
        } else {
            const tempLineData = {}
            response.data.expenses.map((row, index) => {
                filteredTypes.map((type) => {
                if (!tempLineData[type.name]) {
                    tempLineData[type.name] = []
                }

                var tempDate = moment(row).format('MMM DD, YYYY');

                if (row.pc_expense_type && row.pc_expense_type.includes(type.name)) {
                    var info = {}
                    info.x = tempDate;
                    info.y = row.total_expense_per_day;
                    tempLineData[type.name].push(info);
                } else {
                    var info = {}
                    info.x = tempDate;
                    info.y = 0;
                    tempLineData[type.name].push(info);
                }
                });
            });
            setLineChartData(tempLineData)
            setExpenseDate(response.data.expense_date)
            setAmount(response.data.expense_total)
            setNoData(false)
        }
        setShowLoader(false);
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        ArcElement,
        Title,
        Tooltip,
        Legend
    );

    const handleTypeFilter = (e) => {
        if (e.value==="") {
          setFilteredTypes(allExpenseTypes);
        } else {
          setFilteredTypes([e])
        }
    
        setFilterConfig((prev) => {
          return { ...prev, expense_type_per_day_type: e.value };
        });
      }

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);


    React.useEffect(() => {
        async function fetchAllExpenseTypes () {
            const response = await getAllExpenseType();
            if (response.data) {
                const res = response.data.map((row) => {
                    var info = row;
                    info.value = row.id;
                    info.label = row.name;
                    return info;
                })
                setAllExpenseTypes(res);
                setFilteredTypes(res);
            }
        }

        fetchAllExpenseTypes()
    }, []);

    const generatePdf = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const text = "EXPENSE BY TYPE.";
            const pdf = new jsPDF();
            pdf.text(text, 5, 5)
            pdf.addImage(imgData, 'PNG',15,15,180,60);
            pdf.save("Expensebydate.pdf");
        });
    }

    const options = {
        plugins: {
            datalabels: {
            formatter: (value, context) => {
                const dataset = context.chart.data.datasets[0];
                const total = dataset.data.reduce((acc, data) => acc + data, 0);
                const percentage = ((value / total) * 100).toFixed(2) + "%";
                return percentage;
            },
            color: '#fff', // Set the color of the labels
            anchor: 'end', // Set the position of the labels
            align: 'end', // Set the alignment of the labels
            display: 'auto', // Set to 'auto' for automatic display
            },
        },
    };

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EXPENSES REPORT"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-4">
                    <Col xs={6}>
                        <h1 className="page-title"> EXPENSE BY DATE </h1>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                        <button className="add-btn text-center" onClick={generatePdf}>Export to PDF</button>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end mb-3">
                        <div className="justify-content-center align-items-center ">
                            <button
                                type="button"
                                className="button-primary "
                                onClick={() => navigate("/expensereport/" + Moment(filterConfig.date_from).format("MM-DD-YYYY") + "/" + Moment(filterConfig.date_to).format("MM-DD-YYYY"))}
                            >
                                View
                            </button>
                        </div>
                    </Col>
                </Row>

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                        <span className="me-4 align-middle mt-2 ps-label">
                            Filter By Date:
                        </span>

                        <span className="me-4 align-middle mt-2 ps-label">
                            From:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_from}
                            name="date_from"
                            placeholderText={Moment().format("MM/DD/YYYY")}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_from: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />

                        <span className="me-4 align-middle mt-2 ps-label">
                            To:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_to}
                            name="date_to"
                            placeholderText={Moment().format("MM/DD/YYYY")}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_to: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />
                        <span className="me-4 align-middle mt-2 ps-label">
                            Expense Type:
                        </span>
                    <Select
                        name="type"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select Expense Type..."
                        value={allExpenseTypes.map((item) => item.id === filterConfig.expense_type)}
                        options={[{label: "All", value:''}, ...allExpenseTypes]}
                        onChange={handleTypeFilter}
                    />
                    </div>
                    

                    {/* <div className=" PO-filters d-flex justify-content-center">
                        <span className="me-4 ml-4 mt-2 ps-label">
                            Total Usage Quantity: {numberFormat(totalQuantity)}
                        </span>
                    </div> */}

                    <div className="below d-flex justify-content-center mb-5 mt-3" id="divToPrint">
                        {
                            !noData ? (
                                <Line className="chart-size ms-5" data={data} options={options}/>
                            ) : (
                                <div className="no-data-cont">
                                    <img src={NoDataImg} alt="no data found" className="no-data-small"/>
                                    <span>Uh Oh! No data found.</span>
                                </div>
                            )
                        }
                    </div>
                    <div className="mb-2" />
                </div>
            </div>

        </div>
    );
}
