import React, { useState, useEffect } from "react";
import { Col, Form, Row} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/DataTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Select from "react-select";

// api calls and utils
import {
  refreshPage,
  toastStyle,
  TokenExpiry,
  formatDateNoTime,
  getType,
  numberFormat,
  selectDropdownStyle,
} from "../../Helpers/Utils/Common";

// css
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import { searchProject, deleteProject } from './../../Helpers/apiCalls/Manage/Projects';
import { getAllCustomer } from "./../../Helpers/apiCalls/Manage/CustomerAPI";
import { deleteProjectChangeRequest, searchProjectChangeRequest } from "../../Helpers/apiCalls/ProjectChangeRequest/ProjectChangeRequestApi";

/**
 *  Franchise Register component
 */

export default function ProjectChangeRequest() {
  let navigate = useNavigate();
  const userType = getType();
  const [inactive, setInactive] = useState(true);
  const [allData, setAllData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);


  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [projectChangeRequestId, setProjectChangeRequestId] = useState("");

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    // franchise: "",
    // tabKey: "",
    // project_id: "",
    // franchised_on_from: "",
    // franchised_on_to: "",
  });
  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilterConfig((prev) => {
      return { ...prev, [name]: value };
    });
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([])

    const response = await searchProject();
    if (response.error) {
      if(response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{label: "All Projects", value: ""}, ...projects]);      
    }

    setShowLoader(false);
  }

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([]);

    const response = await getAllCustomer(filterConfig);
    if (response.error) {
    TokenExpiry(response.error);
    } else {
    var projects = response?.data?.data?.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.name;
        return info;
    }); 

    setCustomers([{ label: "All Customers", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchData() {
    setShowLoader(true);
    setAllData([]);

    try {
        const response = await searchProjectChangeRequest(filterConfig);

        const tableData = response.data.data.map((data) => ({
            ...data,
            grand_total: numberFormat(data.grand_total),
            project_date: formatDateNoTime(data.request_date),
        }));

        setAllData(tableData);
    } catch (error) {
        setAllData([]);
    }

    setShowLoader(false);
  }

  async function handleDeleteProjectChangeRequest() {
    const response = await deleteProjectChangeRequest(projectChangeRequestId)

    if (response.data) {
      toast.success("Project Change Request Deleted Successfully!", {
        style: toastStyle(),
      });
      setTimeout(() => refreshPage(), 1000);
    } else {
      toast.error("Error Deleting Project", {
        style: toastStyle(),
      });
    }
  }

  function handleSelectChange(e, row, id) {
    const commonData = {
      ...row,
      project_name: row.name,    
      name: row.name,            
      address: row.address,
      company: row.company,
      id: row.id,
      request_no: row.request_no,
    };

    if (e.target.value === "edit-pi") {
      navigate("/projectchangerequest/edit/" + row.id, {
        state: { row: commonData }
      });
    } else if (e.target.value === "print-pi") {
      navigate("/projectchangerequest/view/" + row.id, { 
        state: { row: commonData }
      });
    } else if (e.target.value === "delete-pi") {
      setProjectChangeRequestId(row.id);
      handleShowDeleteModal();
    }
  }

  function ActionBtn(row, type) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        onChange={(e) =>
          handleSelectChange(e, row, row.project_id, row.id)
        }
      >
        <option value="" hidden selected>
          Select
        </option>
        <option value="print-pi" className="color-options">
          View
        </option>
        {userType === "admin" ? (
          <option value="edit-pi" className="color-options">
            Edit
          </option>
        ) : null}
        {userType === "admin" && (
          <option value="delete-pi" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  useEffect(() => {
    fetchProjects();
    fetchCustomers();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">PROJECT CHANGE REQUEST</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="request_no"
              placeholder="Search request no..."
              value={filterConfig.request_no}
              onChange={(e) => handleFilterChange(e)}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => navigate("/projectchangerequest/add")}
            >
              Add 
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 ms-2 PO-filters PI-filters d-flex">
            <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Customer"
                styles={selectDropdownStyle}
                options={customers}
                onChange={(e) => {
                    setFilterConfig((prev) => {
                    return { ...prev, company: e.value };
                    });
                }}
            />
          </div>

          <Table
            tableHeaders={[
              "REQUEST NO",
              "REQUEST DATE",
              "CUSTOMER",
              "TOTAL AMOUNT",
              "ACTIONS",
            ]}
            headerSelector={[
              "request_no",
              "project_date",
              "customer_name",
              "grand_total",
            ]}
            tableData={allData}
            ActionBtn={(row) => ActionBtn(row, "open_bill")}
            showLoader={showLoader}
            withActionData={false}
          />
        </div>
        <div className="mb-2" />
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="project change request"
        onDelete={() => handleDeleteProjectChangeRequest()}
      />
    </div>
  );
}
