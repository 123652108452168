import { Modal } from "react-bootstrap";
import React from "react";
// import { render } from "react-dom";
import warning from "../../Assets/Images/Modal/warning.png";
import ReactLoading from "react-loading";

//css
import "./Modal.css";

function FileExceedModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} size="lg" centered backdrop="static">
      <Modal.Header closeButton/>
        <Modal.Body>
          <div className="text">
            <h1> WARNING! </h1>
            <div className="text-wrapper">
              <div className="text-group">
                <h2>File size exceeds {props.size}!</h2>
                <h3>Please upload a file that is less than {props.size}.</h3>
              </div>
              <img src={warning} alt="warning" className="small-warning"></img>
            </div>
            <div className="col-sm-12 mt-3 d-flex justify-content-end">
              <button className="button-warning me-3" onClick={props.onHide}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

FileExceedModal.defaultProps = {
  size: "",
  show: () => {},
  onHide: () => {},
  onDelete: () => {},
};

export default FileExceedModal;