import { useEffect, useState } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAllBanks } from "../../../../Helpers/apiCalls/banksAPi";
import {
  getTodayDateISO,
  toastStyle,
  formatAmount
} from "../../../../Helpers/Utils/Common";
import { validatePayment } from "../../../../Helpers/Validation/Franchise/PaymentModalValidation";
import { payPurchaseInvoiceModal } from "../../../../Helpers/apiCalls/Purchases/purchaseInvoiceApi";
import InputError from "../../../../Components/InputError/InputError";
import ReactLoading from "react-loading";
import dayjs from "dayjs";
import { DatePicker, Upload } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import Compressor from "compressorjs";

export default function AddPaymentModal({
  id,
  se_id,
  invoice_no,
  show,
  onHide,
  balance,
}) {
  const navigate = useNavigate();
  const [bankOptions, setBankOptions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  const [selectedBank, setSelectedBank] = useState({
    name: "bank_id",
    label: "",
    value: "",
  });

  const [isError, setIsError] = useState({
    payment_date: false,
    payment_type: false,
    paid_amount: false,
    bank_id: false,
    bank_name: false,
    check_date: false,
    check_number: false,
    reference_number: false,
    attachment: false,
  });

  const [paymentDetails, setPaymentDetails] = useState({
    payment_date: getTodayDateISO(),
    payment_type: "cash",
    paid_amount: balance || "",
    bank_id: "",
    remarks: "",
    check_date: "",
    check_number: "",
    bank_name: "",
    payment_description: "",
    invoice_no: se_id,
    deposit_date: getTodayDateISO(),
    from_bank_id: "",
  });

  useEffect(() => {
    setPaymentDetails(prev => ({
      ...prev,
      paid_amount: balance || ""
    }));
  }, [balance]);

  function handleDetailsChange(e) {
    const { name, value } = e.target;
    if (name === "bank_id") {
      setSelectedBank(() => {
        return { name: name, value: value, label: e.label };
      });
      setPaymentDetails(prev => ({
        ...prev,
        bank_id: value,
        from_bank_id: value
      }));
    } else {
      setPaymentDetails(prev => ({
        ...prev,
        [name]: value
      }));
    }
  }

  async function handleCreatePayment() {
    const updatedPaymentDetails = {
      ...paymentDetails,
      bank_id: paymentDetails.bank_id || selectedBank.value,
      from_bank_id: paymentDetails.from_bank_id || selectedBank.value,
    };

    if (validatePayment(updatedPaymentDetails, setIsError)) {
      setIsClicked(true);
      const response = await payPurchaseInvoiceModal(
        id,
        se_id,
        updatedPaymentDetails,
        balance,
        fileList
      );

      if (response.data) {
        if (response.data.status === "success") {
          toast.success("Payment saved!", {
            style: toastStyle(),
          });
          navigate("/se/purchaseinvoices/print/" + id, { 
            state: { refresh: true } 
          });
          onHide ();
        }
      } else if (response.error) {
        toast.error(
          response.error?.response?.data?.message || "Error saving payment", 
          { style: toastStyle() }
        );
      }
      setIsClicked(false);
    }
  }

  useEffect(() => {
    async function fetchBanks() {
      const response = await getAllBanks();
      const banks = response.data.data;
  
      const bankOptions = banks.map((bank) => ({
        name: "bank_id",
        label: bank.bank_name,
        value: bank.id
      }));
      setBankOptions(bankOptions);
    }
    fetchBanks();
  }, []);

  const handleBeforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      toast.error('You can only upload image files!', { style: toastStyle() });
      return false;
    }
    
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error('Image must be smaller than 2MB!', { style: toastStyle() });
      return false;
    }

    new Compressor(file, {
      quality: 0.6,
      success: (compressedResult) => {
        setFileList([compressedResult]);
      },
    });
    return false;
  };
  
  const handleRemove = () => {
    setFileList([]);
  };

  useEffect(() => {
    if (fileList.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setPaymentDetails(prev => ({
          ...prev,
          attachment: reader.result.split(',')[1],
          attachment_name: fileList[0].name
        }));
      };
      reader.readAsDataURL(fileList[0]);
    }
  }, [fileList]);

  return (
    <Modal show={show} onHide={() => {onHide(); setPaymentDetails({})}} size="lg" centered>
      <Modal.Header closeButton>
        <span className="page-title">Add Payment</span>
      </Modal.Header>
      <Modal.Body className="return-body">
        <div>
          <Row>
            <Col xs={6}>
              <span className="edit-label">
                Payment Date<span className="color-red"> *</span>
              </span>
              <DatePicker
                className="nc-modal-custom-text-new-datepicker"
                name="payment_date"
                value={paymentDetails.payment_date ? dayjs(paymentDetails.payment_date) : null}
                onChange={(date, dateString) => {
                  handleDetailsChange({
                    target: { name: "payment_date", value: dateString },
                  });
                }}
                format="YYYY-MM-DD"
                getPopupContainer={(trigger) => trigger.parentNode}
              />
              <InputError
                isValid={isError.payment_date}
                message={"Payment date is required"}
              />
            </Col>
            <Col xs={6}>
              <span className="edit-label">
                Invoice Number
                <label className="badge-required">{` *`}</label>
              </span>
              <Form.Control
                type="text"
                name="invoice_no"
                className="nc-modal-custom-text"
                value={invoice_no}
                disabled
              />
            </Col>
          </Row>
        </div>
        

        <Row className="mt-2">
          <Col>
            <span className="edit-label">Payment Method</span>
            <div>
              <Form.Check
                inline
                label="Cash"
                name="payment_type"
                value="cash"
                type="radio"
                defaultChecked
                onClick={(e) => handleDetailsChange(e)}
              />
              <Form.Check
                inline
                label="Check"
                name="payment_type"
                type="radio"
                value="check"
                onClick={(e) => handleDetailsChange(e)}
              />
              <Form.Check
                inline
                label="Others"
                name="payment_type"
                value="others"
                type="radio"
                onClick={(e) => handleDetailsChange(e)}
              />
            </div>
            <InputError
              isValid={isError.payment_type}
              message={"Payment type is required"}
            />
          </Col>
          <Col>
            <span className="">Balance: {balance ? formatAmount(balance) : ''}</span>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={6}>
            <span className="edit-label">
              Paid Amount<span className="color-red"> *</span>
            </span>
            <Form.Control
              type="text"
              name="paid_amount"
              className="nc-modal-custom-text"
              defaultValue={balance}
              onChange={(e) => handleDetailsChange(e)}
            />
            <InputError
              isValid={isError.paid_amount}
              message={"Paid amount is required"}
            />
          </Col>
          <Col xs={6}>
            <span className="edit-label">
              Credit From<span className="color-red"> *</span>
            </span>
            <Select
              name="bank_id"
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select bank..."
              defaultValue={selectedBank}
              options={bankOptions}
              onChange={(e) =>
                handleDetailsChange({
                  target: {
                    name: e.name,
                    value: e.value,
                    label: e.label,
                  },
                })
              }
            />
            <InputError
              isValid={isError.bank_id}
              message={"Credit From is required"}
            />
          </Col>
        </Row>

        {/* CHECK PAYMENT DETAILS */}
        {paymentDetails.payment_type === "check" && (
          <Row className="mt-3">
            <Col xs={4}>
              <span className="edit-label">Bank Name<span className="color-red"> *</span></span>
              <Form.Control
                type="text"
                name="bank_name"
                className="nc-modal-custom-text"
                value={paymentDetails.bank_name}
                onChange={(e) => handleDetailsChange(e)}
              />
              <InputError
                isValid={isError.bank_name}
                message={"Bank name is required"}
              />
            </Col>
            <Col xs={4}>
              <span className="edit-label">Check Date<span className="color-red"> *</span></span>
              <Form.Control
                type="date"
                name="check_date"
                className="nc-modal-custom-text"
                value={paymentDetails.check_date}
                onChange={(e) => handleDetailsChange(e)}
              />
              <InputError
                isValid={isError.check_date}
                message={"Check date is required"}
              />
            </Col>
            <Col xs={4}>
              <span className="edit-label">Check Number<span className="color-red"> *</span></span>
              <Form.Control
                type="text"
                name="check_number"
                className="nc-modal-custom-text"
                value={paymentDetails.check_number}
                onChange={(e) => handleDetailsChange(e)}
              />
              <InputError
                isValid={isError.check_number}
                message={"Check number is required"}
              />
            </Col>
          </Row>
        )}

        {/* OTHERS PAYMENT DETAILS */}
        {paymentDetails.payment_type === "others" && (
          <Row className="mt-3">
            <Col xs={6}>
              <span className="edit-label">
                Reference Number<span className="color-red"> *</span>
              </span>
              <Form.Control
                type="text"
                name="reference_number"
                className="nc-modal-custom-text"
                value={paymentDetails.reference_number}
                onChange={(e) => handleDetailsChange(e)}
              />
              <InputError
                isValid={isError.reference_number}
                message={"Reference number is required"}
              />
            </Col>
          </Row>
        )}

        <Row className="mt-3">
          <Col>
            <span className="edit-label">
              Remarks
              <span className="edit-optional px-2">(Optional)</span>
            </span>
            <Form.Control
              as="textarea"
              name="remarks"
              value={paymentDetails.remarks}
              className="nc-modal-custom-text"
              onChange={(e) => handleDetailsChange(e)}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <span className="edit-label">
              Attachment
              <span className="color-red"> *</span>
            </span>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onRemove={handleRemove}
              beforeUpload={handleBeforeUpload}
              maxCount={1}
              a
            >
              {fileList.length === 0 && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
            <InputError
              isValid={isError.attachment}
              message={"Attachment is required"}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="return-footer">
        <button 
          type="button" 
          className="button-secondary" 
          onClick={() => {onHide(); setPaymentDetails({})}}
        >
          Cancel
        </button>
        {isClicked ? (
          <div className="button-primary d-flex justify-content-center">
            <ReactLoading
              type="bubbles"
              color="#FFFFFF"
              height={50}
              width={50}
            />
          </div>
        ) : (
          <button
            type="button"
            className="button-primary"
            onClick={() => handleCreatePayment()}
          >
            Pay
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
