import {handleValidationChange} from '../CommonValidation';

export const validateBanks = (data, setIsError) => {
    //Required 
    // bank_name: "",

    var isValid = true;

    if(data.bank_name === "") {
        handleValidationChange("bank_name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("bank_name", false, setIsError);
    }

    if(data.account_name === "") {
        handleValidationChange("account_name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("account_name", false, setIsError);
    }
    
    if(data.check_template_id === "") {
        handleValidationChange("check_template_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("check_template_id", false, setIsError);
    }
    
    if (data.account_no.trim() === "") {
        handleValidationChange("account_no", "Account number is required", setIsError);
        isValid = false;
    } else if (!/^\d+$/.test(data.account_no)) { 
        handleValidationChange("account_no", "Account number must contain numbers only", setIsError);
        isValid = false;
    } else if (data.account_no.length > 20) {
        handleValidationChange("account_no", "Account number must be at most 20 digits", setIsError);
        isValid = false;
    } else {
        handleValidationChange("account_no", "", setIsError);
    }
    
    if (data.beginning_bal.trim() === "") {
        handleValidationChange("beginning_bal", "Beginning balance is required", setIsError);
        isValid = false;
    } else if (!/^\d+$/.test(data.beginning_bal)) { 
        handleValidationChange("beginning_bal", "Beginning must contain numbers only", setIsError);
        isValid = false;
    } else {
        handleValidationChange("beginning_bal", "", setIsError);
    }
    

    return isValid
  
}