import {
  formatDateNoTime,
  formatYDM,
  getToken,
  getUser,
} from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";

//GET
export const getAllInvoices = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "receives/get_all_receive",
      {
        requester: getUser(),
        token: getToken(),
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const getInvoice = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "receives/get_receive",
      {
        requester: getUser(),
        token: getToken(),
        receive_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const getPaymentHistory = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "receives/get_all_invoice_payments",
      {
        requester: getUser(),
        token: getToken(),
        receive_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

//POST
export const createInvoice = async (invoice, items, po_id) => {
  try {
    var payload = {
      requester: getUser(),
      token: getToken(),
      po_id: po_id,
      project_id: invoice.project_id,
      supplier_id: invoice.supplier_id,
      vendor_id: invoice.vendor_id,
      purchase_date: invoice.purchase_date,
      receive_date: invoice.received_date,
      forwarder_id: invoice.forwarder_id,
      waybill_no: invoice.waybill_no,
      invoice_no: invoice.invoice_no,
      dr_no: invoice.dr_no,
      freight_cost: invoice.freight_cost ? invoice.freight_cost : "0",
      discount: invoice.discount ? invoice.discount : "0",
      remarks: invoice.remarks,
      service_fee: invoice.service_fee,

      item_ids: items.map((item) => {
        return item.item_id;
      }),
      quantities: items.map((item) => {
        return item.qtyInput;
      }),
      units: items.map((item) => {
        return item.unit;
      }),
      prices: items.map((item) => {
        return item.price;
      }),
      po_item_ids: items.map((item) => {
        return item.po_item_id;
      }),
      // types: items.map((item) => {
      //     return item.type ? item.type : "";
      // }),
    };

    const response = await postAPICall(
      process.env.REACT_APP_LINK + "receives/create",
      payload
    );


    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const updateInvoice = async (invoice, items, id) => {
  try {
    var payload = {
      requester: getUser(),
      token: getToken(),
      receive_id: id,
      po_id: invoice.po_id,
      project_id: invoice.project_id,
      supplier_id: invoice.supplier_id,
      vendor_id: invoice.vendor_id,
      purchase_date: invoice.purchase_date,
      receive_date: invoice.received_date,
      forwarder_id: invoice.forwarder_id,
      waybill_no: invoice.waybill_no,
      invoice_no: invoice.invoice_no,
      dr_no: invoice.dr_no,
      freight_cost: invoice.freight_cost,
      discount: invoice.discount,
      // paid_amount: invoice.grand_total,
      remarks: invoice.remarks,
      service_fee: invoice.service_fee,

      item_ids: items.map((item) => {
        return item.item_id;
        // return item.id;
        // return item.po_item_id;
      }),
      quantities: items.map((item) => {
        return item.qtyInput;
      }),
      units: items.map((item) => {
        return item.unit;
      }),
      prices: items.map((item) => {
        return item.price;
      }),
      po_item_ids: items.map((item) => {
        return item.po_item_id;
      }),
    };
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "receives/update",
      payload
    );

    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const deleteInvoice = async (id) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "receives/delete",
      {
        requester: getUser(),
        token: getToken(),
        receive_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const filterInvoice = async (filterConfig) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "receives/search",
      {
        requester: getUser(),
        token: getToken(),
        supplier_id: filterConfig.supplier || "",
        vendor_id: filterConfig.vendor_id || "",
        payment_status: filterConfig.payment_status,
        receive_date_from: filterConfig.date_from
          ? formatYDM(filterConfig.date_from)
          : "",
        receive_date_to: filterConfig.date_to
          ? formatYDM(filterConfig.date_to)
          : "",
        invoice_no: filterConfig.invoice_no || "",
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const closeOverpaidBill = async (id, remarks) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "receives/close_overpaid_receive",
      {
        requester: getUser(),
        token: getToken(),
        receive_id: id,
        remarks: remarks,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const payPurchaseInvoiceModal = async (
  id,
  se_id,
  payment,
  balance,
  fileList
) => {
  try {
    var payload = {
      requester: getUser(),
      token: getToken(),
      supplies_expense_id: se_id,
      supplies_receive_id: id,
      payment_type: payment.payment_type,
      payment_date: payment.payment_date,
      from_bank_id: payment.from_bank_id,
      to_bank_id: payment.to_bank_id,
      remarks: payment.remarks,
      from_bank_name: payment.bank_name,
      deposited_to: payment.deposited_to,
      cheque_number: payment.cheque_number,
      cheque_date: payment.cheque_date,
      reference_number: payment.reference_number,
      transaction_number: payment.transaction_number,
      payment_description: payment.payment_description,
      delivery_address: payment.delivery_address,
      grand_total: payment.grand_total,
      paid_amount: payment.paid_amount !== "" ? payment.paid_amount : balance,
      subtotal: payment.subtotal,
      service_fee: payment.service_fee,
      delivery_fee: payment.delivery_fee,
      withholding_tax: payment.withholding_tax,
      invoice_no: payment.invoice_no,
      file: fileList.map((file) => file)
    };
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "supplies_invoice_payments/create",
      payload,
      true
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};
