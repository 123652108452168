import DataTable from "react-data-table-component";
import { SyncLoader } from "react-spinners";
import NoDataPrompt from "../NoDataPrompt/NoDataPrompt";
import "./Table.css";
import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";

const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

function CheckboxCell({ checked, onChange }) {
  return <Checkbox checked={checked} onChange={onChange} />;
}

export default function Table({
  tableHeaders,
  headerSelector,
  tableData,
  ActionBtn,
  ViewBtn,
  PendingBtn,
  showLoader,
  withActionData,
  onRowClicked,
  SaleBtn,
  ContractBtn,
  SaleBillingBtn,
  checkBtn,
  CheckBox,
  newTabBtn,
  tableType,
  OngoingPOBadges,
  DoneBtn,
  PaymentBtn,
  InvoiceBtn,
  currentAging,
  aboveNinetyAging,
  oneToThirty,
  thirtyOneToSixty,
  sixtyOneToNinety,
  totalAging,
  branchStatus,
  selectAll,
  handleSelectAllChange,
  handleRowCheckboxChange,
  selectedRows,
  expandable, // Add expandable prop
}) {
  const [localSelectAll, setLocalSelectAll] = useState(false);
  const [localSelectedRows, setLocalSelectedRows] = useState([]);

  useEffect(() => {
    if (tableData.length > 0 && localSelectedRows.length === 0) {
      handleLocalSelectAllChange({ target: { checked: false } });
    }
  }, [tableData]);

  const handleLocalSelectAllChange = (e) => {
    const checked = e.target.checked;
    setLocalSelectAll(checked);
    setLocalSelectedRows(
      tableData.map((row) => ({ ...row, selected: checked }))
    );
  };

  const handleLocalRowCheckboxChange = (e, row) => {
    const checked = e.target.checked;
    setLocalSelectedRows((prev) =>
      prev.map((r) => (r.id === row.id ? { ...r, selected: checked } : r))
    );
  };

  const columns = tableHeaders.map((header, index) => {
    if (tableType === "income_statement") {
      if (header === "Type") {
        return {
          name: header,
          selector: (row) => row[headerSelector[index]],
          left: true,
          sortable: true,
          width: "8vw",
          reorder: true,
          wrap: true,
        };
      } else {
        return {
          name: header,
          selector: (row) => row[headerSelector[index]],
          right: true,
          sortable: true,
          width: header === "Total" ? "10vw" : "7vw",
          reorder: true,
          wrap: true,
          cell: (row) => {
            const isIncomeLoss = row.name.toLowerCase() === "income/loss";
            const amount = parseFloat(row[headerSelector[index]].replace(/,/g, ""));
            const style = isIncomeLoss
              ? amount > 0
                ? { color: "green", fontWeight: "bold" }
                : amount < 0
                ? { color: "red", fontWeight: "bold" }
                : { color: "#5E5E5E" }
              : { color: "#5E5E5E" };

            return (
              <div style={{ width: "100%", textAlign: "right", ...style }}>
                {row[headerSelector[index]]}
              </div>
            );
          },
        };
      }
    }
    if (tableType === "journal_entry") {
      if (
        header === "DATE" ||
        header === "DESCRIPTION" ||
        header === "ACCOUNT TYPE" ||
        header === "BANK NAME"
      ) {
        return {
          name: header,
          selector: (row) => row[headerSelector[index]],
          left: true,
          sortable: true,
          width: "8vw",
          reorder: true,
          wrap: true,
        };
      } else {
        return {
          name: header,
          selector: (row) => row[headerSelector[index]],
          right: true,
          sortable: true,
          width: header === "Total" ? "10vw" : "7vw",
          reorder: true,
          wrap: true,
          cell: (row) => (
            <div style={{ width: "100%", textAlign: "right" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        };
      }
    }
    if (header === "-") {
      return {
        name: "",
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: ViewBtn,
        width: "5vw",
        reorder: false,
        wordWrap: "break-word",
      };
    }
    if (header === "/") {
      return {
        name: "",
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: DoneBtn,
        width: "4vw",
        reorder: false,
        wordWrap: "break-word",
      };
    } else if (header === "+") {
      return {
        name: "",
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: PaymentBtn,
        width: "7vw",
        reorder: false,
        wordWrap: "break-word",
      };
    } else if (header === "*") {
      return {
        name: "",
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: CheckBox,
        width: "4vw",
        reorder: false,
        wordWrap: "break-word",
      };
    } else if (header === "PO") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: OngoingPOBadges,
        reorder: false,
        wordWrap: "break-word",
      };
    } else if (header === ".") {
      return {
        name: "",
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: checkBtn,
        width: "3vw",
        reorder: false,
        wordWrap: "break-word",
      };
    } else if (header === "PYMT REF NO" || header === "PYMT MODE") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: newTabBtn,
        width: "8%",
        reorder: false,
        wrap: true,
      };
    } else if (header === "ACTIONS" && !withActionData) {
      return {
        name: header,
        button: true,
        cell: ActionBtn,
        width: "7vw",
        reorder: false,
        center: true,
      };
    } else if (header === "   ") {
      return {
        name: header,
        button: true,
        cell: PendingBtn,
        width: "17vw",
        reorder: true,
        wordWrap: "break-word",
      };
    } else if (header === " ") {
      return {
        name: header,
        button: true,
        cell: PendingBtn,
        reorder: true,
        wordWrap: "break-word",
      };
    } else if (header === "PRODUCT NAME") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        left: true,
        width: "40vw",
        reorder: true,
        wordWrap: "break-word",
      };
    } else if (
      header === "SUPPLIER" ||
      header === "PAYEE" ||
      header === "CUSTOMER" ||
      header === "PROJECT"
    ) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        left: true,
        width: "10vw",
        reorder: true,
        wordWrap: "break-word",
      };
    } else if (header === "CUSTOMER NAME") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "15vw", 
        reorder: true,
        wrap: true,
      };
    } else if (header === "PHONE NUMBER") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        width: "10vw",
        reorder: true,
        wordWrap: "break-word",
      };
    } else if (header === "INV NO." || header === "CONT. NO.") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        width: "6vw",
        cell: InvoiceBtn,
        reorder: true,
      };
    } else if (header === "CREDIT LIMIT") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        width: "6vw",
        cell: InvoiceBtn,
        reorder: true,
      };
    } else if (header === "TERMS") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        center: true,
        width: "6vw",
        cell: InvoiceBtn,
        reorder: true,
      };
    } else if (
      header.includes("SALES") ||
      header.includes("BAL") ||
      header.includes("AMT") ||
      header.includes("DISCOUNT") ||
      header.includes("CONTRACT") ||
      header.includes("MONTHLY BILLABLES")
    ) {
      return {
        name: header,
        selector: (row) =>
          row[headerSelector[index]] === null
            ? "N/A"
            : row[headerSelector[index]]?.toLowerCase(),
        right: true,
        width: "7vw",
        cell: SaleBtn,
        reorder: true,
      };
    } else if (header === "CONTRACT") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        button: true,
        width: "11vw",
        cell: ContractBtn,
        reorder: true,
      };
    } else if (header === "MONTHLY BILLABLES") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        button: true,
        width: "11vw",
        cell: SaleBillingBtn,
        reorder: true,
      };
    } else if (header === "TYPE") {
      return {
        name: header,
        selector: (row) => {
          const value = row[headerSelector[index]];
          return value
            ? value
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")
            : "";
        },
        button: true,
        width: "10vw",
        cell: SaleBillingBtn,
        reorder: true,
        style: { textAlign: "center" },
      };
    } else if (header === "TOTAL") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        width: "9vw",
        reorder: true,
        wrap: true,
        cell: (row) => (
          <div style={{ textAlign: "right" }}>{row[headerSelector[index]]}</div>
        ),
        style: { textAlign: "right" },
        headerStyle: { textAlign: "right" },
      };
    } else if (header === "PYMT STATS") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        button: true,
        width: "6vw",
        cell: SaleBillingBtn,
        reorder: true,
      };
    } else if (header === "AMT PAID") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        button: true,
        width: "3vw",
        cell: SaleBillingBtn,
        reorder: true,
      };
    } else if (header === "PAID AMT") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        reorder: true,
        right: true,
      };
    } else if (header === "PREP BY") {
      return {
        name: header,
        selector: (row) => {
          const value = row[headerSelector[index]];
          return value.replace(/\b\w/g, (char) => char.toUpperCase());
        },
        button: true,
        width: "7vw",
        cell: SaleBillingBtn,
        reorder: true,
      };
    } else if (header === "PO NO.") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        button: true,
        width: "6vw",
        cell: SaleBillingBtn,
        reorder: true,
      };
    } else if (header === "BRANCH") {
      return {
        name: header,
        button: true,
        width: "7.5vw",
        cell: SaleBillingBtn,
        reorder: true,
      };
    } else if (header === "INV NO.") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        button: true,
        width: "3vw",
        cell: SaleBillingBtn,
        reorder: true,
      };
    } else if (
      header === "CURRENT" ||
      header === "1-30 DAYS" ||
      header === "31-60 DAYS" ||
      header === "61-90 DAYS" ||
      header === "ABOVE 90 DAYS"
    ) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        cell: (row) => {
          switch (header) {
            case "CURRENT":
              return currentAging(row);
            case "1-30 DAYS":
              return oneToThirty(row);
            case "31-60 DAYS":
              return thirtyOneToSixty(row);
            case "61-90 DAYS":
              return sixtyOneToNinety(row);
            case "ABOVE 90 DAYS":
              return aboveNinetyAging(row);
            default:
              return null;
          }
        },
        right: true,
        width: "11vw",
        style: {
          justifyContent: "flex-end",
          padding: "0 10px",
        },
      };
    } else if (header === "BRANCH STATUS") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]].toLowerCase(),
        button: true,
        width: "10vw",
        cell: branchStatus,
        reorder: true,
      };
    } else if (header.includes("FRANCHISEE NAME")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "15vw",
        reorder: true,
      };
    } else if (header.includes("BRANCH NAME")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "16vw",
        reorder: true,
        wrap: true,
      };
    } else if (header === "COMPANY EMAIL") {
      return {
        name: header,
        selector: (row) =>
          row[headerSelector[index]] === null
            ? "N/A"
            : row[headerSelector[index]]?.toLowerCase(),
        left: true,
        width: "15vw",
        reorder: true,
        Cell: (row) => {
          const email = row.email;
          if (!email) return "";
          return email.toLowerCase();
        },
        wrap: true,
      };
    } else if (header.includes("SUPPLIER")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "10vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("DOC TYPE")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "8vw",
        reorder: true,
        wrap: true,
      };
    } else if (
      header.includes("UNIT") ||
      header.includes("QUANTITY") ||
      header.includes("PRICE")
    ) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "8vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("COMPANY NAME")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "15vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("SUPPLIER/VENDOR")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "10vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("PO NO.")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        sortable: true,
        width: "6.8vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("DOC NO.")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: false,
        width: "11vw",
        reorder: true,
        wrap: true,
      };
    } else if (
      (header.includes("NO.") ||
        header.includes("no.") ||
        header === "CUR QTY" ||
        header === "BRKDN VALUE" ||
        (header.includes("DR NO"))) &&
      header !== "INV NO."
    ) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        sortable: true,
        width: "5vw",
        reorder: true,
      };
    } else if (header.includes("ROLE") || header.includes("TYPE")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "12vw",
        reorder: true,
      };
    } else if (header.includes("USERNAME")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        left: true,
        sortable: true,
        width: "30vw",
        reorder: true,
      };
    } else if (header.includes("INITIAL CASH IN DRAWER")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        sortable: true,
        width: "16vw",
        reorder: true,
      };
    } else if (header.includes("FULL NAME")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "25vw",
        reorder: true,
      };
    } else if (header === "DETAILS") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "30vw",
        reorder: true,
        wrap: true,
      };
    } else if (
      header === "MIN" ||
      header === "MAX" ||
      header.includes("QTY") ||
      header.includes("Qty") ||
      header.includes("COUNT") ||
      header.includes("DIFFERENCE") ||
      header.includes("YIELD")
    ) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        right: true,
        sortable: true,
        width: "8vw",
        reorder: true,
        wrap: true,
      };
    } else if (header === "ADDRESS") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "30vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("DATE")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        left: true,
        sortable: true,
        width: "6vw",
        reorder: true,
        wrap: true,
      };
    } else if (
      header.includes("AMOUNT PAID") ||
      (header.includes("AMOUNT") && header !== "INVOICE AMOUNT")
    ) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        button: true,
        width: "10vw",
        cell: (row) => (
          <div style={{ textAlign: "right" }}>{row[headerSelector[index]]}</div>
        ),
        reorder: true,
        right: true,
        style: { textAlign: "right" },
        headerStyle: { textAlign: "right" },
      };
    } else if (header.includes("STATUS")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "10vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("BY")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "9vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("BALANCE")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "7vw",
        reorder: true,
        wrap: true,
      };
    } else if (header.includes("INVOICE NO")) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        left: true,
        width: "5vw",
      };
    } else if (header === "INVOICE AMOUNT") {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        width: "6vw",
        right: true,
      };
    } else if (
      header === "PAID" ||
      header === "RECEIVABLE" ||
      header === "TOTAL PROFIT" ||
      header === "PROJECT EXPENSE"
    ) {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        width: "10vw",
        right: true,
        sortable: true,
      };
    } else if (header === "SELECT") {
      return {
        name: (
          <Checkbox checked={localSelectAll} onChange={handleLocalSelectAllChange} />
        ),
        selector: (row) => row[headerSelector[index]],
        button: true,
        cell: (row) => (
          <CheckboxCell
            checked={
              localSelectedRows.find((r) => r.id === row.id)?.selected || false
            }
            onChange={(e) => handleLocalRowCheckboxChange(e, row)}
          />
        ),
        width: "5vw",
        reorder: false,
        wordWrap: "break-word",
      };
    } else {
      return {
        name: header,
        selector: (row) => row[headerSelector[index]],
        sortable: true,
        width: "10vw",
        reorder: true,
        wrap: true,
      };
    }
  });

  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true,
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: tableType === "income_statement" ? "10vh" : "6.2vh",
        cursor: onRowClicked ? "pointer" : "default", // Add pointer cursor for clickable rows
      },
    },
    cells: {
      style: {
        padding: tableType === "income_statement" ? "12px" : "8px",
      },
    },
  };

  const footer = addFooterRow(tableData, columns);

  function addFooterRow(data) {
    const keysArray = Object.keys(Object.assign({}, ...tableData));
    let obj = {};
    for (let key of keysArray) {
      let countLength = 0;
      tableData.map((item) => {
        if (item === "expense_total") {
          countLength = countLength + 1;
        }
        if (typeof item === "number") {
          countLength = countLength + item;
        }
      });
      obj[key] = `Total ${countLength}`;
    }
    return obj;
  }

  return showLoader ? (
    <div className="d-flex justify-content-center my-5">
      <SyncLoader color="#5ac8e1" size={15} />
    </div>
  ) : (
    <div>
      <DataTable
        pagination={tableType === "item_history" || "income_statement" ? false : true}
        striped
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        paginationComponentOptions={paginationComponentOptions}
        noDataComponent={<NoDataPrompt />}
        defaultSortFieldId={tableType === "itemList" && 2}
        onRowClicked={(row) => onRowClicked && onRowClicked(row)} // Make entire row clickable
        expandableRows={!!expandable} // Enable expandable rows if the prop is provided
        expandableRowExpanded={(row) => row.children && row.children.length > 0} // Expand rows with children
        expandableRowsComponent={
          expandable?.expandedRowRender || null // Render the expandable row content
        }
      />
    </div>
  );
}